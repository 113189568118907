<template>
    <div class="main">
        <BannerCustom/>
        <div class="section-login"> 
            <div class="section-login__wrapper">
                <div class="section-login__container">
                    <h1 class="title-custom">
                        {{ getTranslatedText("log in") }}
                    </h1>
                    <div class="method-login">
                        {{ getTranslatedText("mobile number to login") }}
                    </div>
                    <div class="form-container" :class="classAddInValid()">
                        <FormCommon
                            :formItem="
                                methodLogin === 1 ? formItemPhone : formItemEmail
                            "
                            :dataForm="dataForm"
                            @update-form-data="updateFormData"
                            @onSubmitForm="onSubmitForm"
                            @onToggleShowPassword="onToggleShowPassword"
                        >
                            <div class="message-error" slot="messPhone" v-if="dataMessage.phone">{{ getTranslatedText(dataMessage.message) }}</div>
                            <b-input-group-prepend
                                slot="propend-phone-number"
                                class="phone-code"
                            >
                                <button
                                    class="show-dropdown"
                                    type="button"
                                    @click="showDropDow"
                                >
                                    <div class="flag-group">
                                        <img loading="lazy"
                                            class="user-avatar"
                                            :src="
                                                require(`@/assets/images/flags/${dataForm.flag}`)
                                            "
                                            :alt="dataForm.flag"
                                        />
                                    </div>
                                    <span class="name-country">{{
                                        dataForm.nameCountry
                                    }}</span>
                                    {{ dataForm.calling_code }}
                                </button>

                                <b-dropdown ref="select_phone_code">
                                    <b-form-input
                                        v-model="textSearchPhoneNumber"
                                        :placeholder="getTranslatedText('Search')"
                                        @input="onChangeFilterPhoneNumbers"
                                    ></b-form-input>
                                    <b-dropdown-item
                                        @click="selectPhoneCode(item)"
                                        v-for="(item, index) in phoneNumbersFilter"
                                        :key="index"
                                    >
                                        <div>
                                            <div class="flag-group">
                                                <img loading="lazy"
                                                    class="user-avatar"
                                                    :src="
                                                        require(`@/assets/images/flags/${item.flag}`)
                                                    "
                                                    :alt="item.flag"
                                                />
                                            </div>
                                            <span class="name-country">{{
                                                item.name
                                            }}</span>
                                            ({{ item.number }})
                                        </div>
                                    </b-dropdown-item>
                                </b-dropdown>
                                <img loading="lazy"
                                    class="dropdown-phone-icon"
                                    src="@/assets/images/personal-detail/dropdown-black.png"
                                />
                            </b-input-group-prepend>
                            <div class="message-error" slot="messPass" v-if="dataMessage.password">{{ getTranslatedText(dataMessage.message) }}</div>
                            <div class="message-error" slot="messPass" v-if="dataMessage.message && !dataMessage.phone && !dataMessage.password">{{ getTranslatedText(dataMessage.message) }}</div>
                            <div class="box-btn" slot="button">
                                <ButtonCustom
                                    classBtn="btn-forgot-password"
                                    typeBtn="button"
                                    type="primary"
                                    text="Forgot Password?"
                                    @onClickButton="onClickForgotPassword()"
                                />
                                <ButtonCustom
                                    classBtn="login-btn-continue"
                                    typeBtn="submit"
                                    type="primary"
                                    text="log in"
                                    :isLoading="isLoadingButton"
                                />
                            </div>
                        </FormCommon>
                    </div>
                    <div class="section-login__login-bottom__desktop">
                        <div
                            class="section-login__create-account__des t-transform-cap"
                        >
                            {{ getTranslatedText("New account?") }}
                        </div>
                        <div
                            @click="$router.push('/register')"
                            class="section-login__create-account t-transform-cap"
                        >
                            {{ getTranslatedText("Register Here.") }}
                        </div>
                    </div>
                    <div class="box-social-divider" v-if="!$store.state.is_app">
                        <div class="social-divider">
                            <span>{{ getTranslatedText("or") }}</span>
                        </div>
                    </div>
                    <SocialSignUp
                        :isShowFbSocial="false"
                        @onLogin="onSubmitForm"
                        :isLoadingButton="isLoadingButton"
                        textButton="Log In with Google"
                    />
                </div>
                <img class="frame-img mb" loading="lazy" src="@/assets/images/login/frame-login-mb.webp" alt="" />
                <img class="frame-img dt" loading="lazy" src="@/assets/images/login/frame-login.webp" alt="" />
            </div>
            <Toast :title="toastData.message" :type="toastData.type" />
        </div>
        <ConfirmRecaptcha
            id="modalRecaptchaLogin"
            idRecaptcha="recaptcha-login"
            :sitekey="sitekey"
            :isLoadingButton="isLoadingButton"
            @onSubmitRecaptcha="onSubmitForm"
        />
    </div>
</template>
<script>
import Toast from "@/components/common/toast/Toast.vue";

import FormCommon from "@/components/common/form";
import ButtonCustom from "@/components/common/button";
import ConfirmRecaptcha from "@/components/common/confirm-recaptcha";

import AuthService from "@/services/auth.service";
import GameService from "@/services/game.service";

import languageMixin from "@/mixins/language/language-mixin";
import defaultCountryCodeMixin from "@/mixins/default-country-code/default-country-code-mixin";

import { phoneNumbers } from "@/resources/phoneNumber";
import {
    filterFullTextSearch,
    isArrayEmpty,
    isStrEmpty,
    validateEmail,
} from "@/components/common/utils";

import SocialSignUp from "@/components/common/socialLogin";
import BannerCustom from "@/components/common/banner";
import EventBus from '@/components/common/EventBus';
import moment from "moment";

const SITEKEY = process.env.VUE_APP_SITE_KEY_RECAPTCHA;
const RECAPTCHA_TYPE = process.env.VUE_APP_RECAPTCHA_TYPE;

let scriptTag, scriptTag1, scriptTag2, scriptTag3
export default {
    mixins: [languageMixin, defaultCountryCodeMixin],
    components: {
        FormCommon,
        ButtonCustom,
        Toast,
        SocialSignUp,
        BannerCustom,
        ConfirmRecaptcha
    },
    data() {
        return {
            toastData: {
                message: "",
                type: "success",
            },
            sitekey: SITEKEY,
            recaptchaType: RECAPTCHA_TYPE,
            dataMessage: "",
            codeOTP: "",
            methodLogin: 1,
            dataForm: {
                phone: "",
                password: "",
                email: "",
                calling_code: "+968",
                code: "+968",
                flag: "Flag_of_Oman.svg",
                nameCountry: "Oman",
            },
            phoneNumbersFilter: phoneNumbers,
            textSearchPhoneNumber: "",
            phoneNumbers,
            isLoadingButton: false,
            formItemPhone: [
                {
                    type: "input-group",
                    typeInput: "number",
                    placeholder: "",
                    name: "phone",
                    label: "Mobile Number",
                    for: "input-phone",
                    id: "input-phone-login",
                    required: true,
                    showIconRequired: true,
                    prepend: "code",
                    nameSlotInputPrepend: "propend-phone-number",
                    state: null,
                    textInvalid: "Please enter phone",
                },
                {
                    type: "slot",
                    nameSlot: "messPhone",
                },
                {
                    type: "input",
                    typeInput: "password",
                    typeShow: "password",
                    label: "password",
                    placeholder: "password",
                    name: "password",
                    required: true,
                    showIconRequired: true,
                    showIconEye: true,
                    for: "input-password",
                    id: "input-password",
                },
                {
                    type: "slot",
                    nameSlot: "messPass",
                },
            ],
            formItemEmail: [
                {
                    type: "input",
                    typeInput: "email",
                    label: "email",
                    placeholder: "email",
                    name: "email",
                    required: true,
                    hideIconRq: true,
                    showIconRequired: true,
                    for: "input-email",
                    id: "input-email",
                    state: null,
                    textInvalid: "Please enter email",
                },
                {
                    type: "input",
                    typeInput: "password",
                    typeShow: "password",
                    label: "password",
                    placeholder: "password",
                    name: "password",
                    required: true,
                    hideIconRq: true,
                    for: "input-password",
                    id: "input-password",
                },
            ],
        };
    },
    created() {
        // this.fetchRecaptcha()
    },
    mounted() {
        this.handleDefaultCountry(this.dataForm)
    },

    // metaInfo() {
    //     return {
    //         title: 'Login using your O! Millionaire credentials: Participate Now, Win BIG & Save the Planet',
    //         meta: [
    //             {
    //                 vmid: "description",
    //                 name: "description",
    //                 content: 'Each entry with O! Millionaire is your contribution to our tree plantation green initiative Oasis Park plus you get a chance to Win Your Best Life while Saving the Planet at the same time.'
    //             }
    //         ]
    //     };
    // },
    methods: {
        showToast(message, type) {
            this.toastData.message = message;
            this.toastData.type = type;
            this.$bvToast.show("my-toast");
        },
        updateFormData(item) {
            let { value, name } = item;
            this.dataForm[name] = value;
        },

        classAddInValid() {
            if (this.dataMessage.message) {
                if (this.dataMessage.phone) {
                    return 'check-valid-phone'
                } else if (this.dataMessage.password) {
                    return 'check-valid-pass'
                } else {
                    return 'check-valid'
                }
            }
        },

        onChangeMethodLogin() {
            this.methodLogin = this.methodLogin === 1 ? 2 : 1;
        },

        fetchRecaptcha() {
            if(this.recaptchaType !== 'v2') {
                const scriptTag2 = document.createElement("script");
                scriptTag2.src =`https://www.google.com/recaptcha/enterprise.js?render=${this.sitekey}`
                document.head.appendChild(scriptTag2);
            }
        },

        async onFinishLogin(data, type, isNewUser) {
            this.$store.dispatch("setBalance");
            let totalCart = !isStrEmpty(localStorage.getItem("total_items"))
                ? parseInt(localStorage.getItem("total_items"))
                : 0;

            try {
                const respLotoGame = await GameService.getLottoGames("4");
                if(respLotoGame.status === 200 && !isArrayEmpty(respLotoGame.data)){
                    localStorage.setItem(
                        "list_games",
                        JSON.stringify(respLotoGame.data)
                    );
                }else{
                    window.location.href = "/";
                    return false;
                }
                const resp = await AuthService.getListCart();
                if (resp.status === 200) {
                    localStorage.setItem("isLogin", true);

                    let list_block_tmp =
                        totalCart > 0
                            ? JSON.parse(localStorage.getItem("list_block_tmp"))
                            : [];
                    let list_block_api =
                        parseInt(resp.data?.amount) > 0
                            ? JSON.parse(resp.data.cart_data)
                            : [];

                    if (!isArrayEmpty(list_block_tmp)) {
                        list_block_tmp = list_block_api.concat(list_block_tmp);
                        list_block_tmp = [...new Set(list_block_tmp)];

                        let amount = 0;

                        list_block_tmp.map((x) => {
                            amount += x.date.length;
                        });

                        let param = {
                            cart_data: list_block_tmp,
                            items: list_block_tmp.length,
                            amount,
                        };

                        const respSaveCart = await AuthService.saveListCart(
                            param
                        );

                        if (respSaveCart.status === 200) {
                            this.$store.dispatch(
                                "onChangeAnountCart",
                                parseInt(param.amount)
                            );
                            localStorage.setItem(
                                "list_block_tmp",
                                JSON.stringify(list_block_tmp)
                            );
                            localStorage.setItem(
                                "list_block",
                                JSON.stringify(list_block_tmp)
                            );
                            localStorage.setItem("total_items", param.amount);
                            if (this.$store.state.is_app) {
                                window.location.href = "/";
                            } else {
                                window.location.href = "/checkout";
                            }
                        }
                    } else {
                        let amount = resp?.data?.amount
                            ? parseInt(resp.data.amount)
                            : 0;

                        localStorage.setItem(
                            "list_block_tmp",
                            JSON.stringify(list_block_api)
                        );
                        localStorage.setItem(
                            "list_block",
                            JSON.stringify(list_block_api)
                        );
                        localStorage.setItem("total_items", amount);
                        let urlRedirect = this.$store.state.is_app ? window.location.href = "/" : window.location.href = "/checkout"
                        amount !== 0
                            ? urlRedirect
                            : window.location.href = "/";
                    }
                } else {
                    window.location.href = "/";
                }
            this.isLoadingButton = false;
            } catch (error) {
                this.isLoadingButton = false;
                console.log(error);
            }
            if (isNewUser) {
                scriptTag1 = document.createElement('script');
                scriptTag1.textContent = `
                    window.dataLayer = window.dataLayer || [];
                    dataLayer.push({
                        'event': 'sign_up',
                        'sign_up_date': '${moment().format('YYYY-MM-DD')}',
                        'method': '${type}',
                        'user_id': ${data.id},
                        'first_name': '${data.first_name || "NA"}',
                        'last_name': '${data.last_name || "NA"}',
                        'mobile': '+${data.phone || "N/A"}',
                        'email': '${data.email || "NA"}'
                    });
                `;
                document.head.appendChild(scriptTag1);
                scriptTag2 = document.createElement('script');
                scriptTag2.textContent = `
                    window.dataLayer = window.dataLayer || [];
                    dataLayer.push({
                        'user_id': ${data.id},
                        'first_name': '${data.first_name || "NA"}',
                        'last_name': '${data.last_name || "NA"}',
                        'mobile': '+${data.phone || "N/A"}',
                        'email': '${data.email || "NA"}'
                    });
                `;
                document.head.appendChild(scriptTag2);
            } 
            scriptTag = document.createElement('script');
            scriptTag.textContent = `
                window.dataLayer = window.dataLayer || [];
                dataLayer.push({
                    'event': 'sign_in',
                    'login_date': '${moment().format('YYYY-MM-DD')}',
                    'method': '${type}',
                    'user_id': ${data.id},
                    'calling_code': '${data.calling_code || "N/A"}',
                    'mobile': '+${data.phone || "N/A"}',
                    'email': '${data.email || "NA"}'
                });
            `;
            document.head.appendChild(scriptTag);
            scriptTag3 = document.createElement('script');
            scriptTag3.textContent = `
                window.dataLayer = window.dataLayer || [];
                dataLayer.push({
                    'user_id': ${data.id},
                    'calling_code': '${data.calling_code || "N/A"}',
                    'mobile': '+${data.phone || "N/A"}',
                    'email': '${data.email || "NA"}'
                });
            `;
            document.head.appendChild(scriptTag3);
        },
        async checkTypeRecaptcha(paramLoginSocial) {
            if(this.recaptchaType === 'v2') {
                this.$nextTick(() => {
                    grecaptcha.enterprise.ready(() => {
                        try {
                            grecaptcha.enterprise.render('recaptcha-login', {
                                sitekey: this.sitekey,
                                action: 'login',
                                hl: localStorage.getItem("locale") || 'en'
                            })
                        } catch (err) {
                            console.log(err)
                        }
                        })
                    }
                )
                // this.$bvModal.show("modalRecaptchaLogin");
            } else {
                this.onSubmitForm(paramLoginSocial)
            }
        },
        async onSubmitForm(paramLoginSocial) {
            this.isLoadingButton = true;
            if (!isStrEmpty(paramLoginSocial?.idToken)) {
                AuthService.loginByGoogle(
                    paramLoginSocial.idToken,
                    paramLoginSocial.type
                ).then((res) => {
                    if (res.status === 200) {
                        this.onFinishLogin(res.data, paramLoginSocial.type, res.data.is_new_user);
                    } else {
                        this.isLoadingButton = false;
                        this.showToast(
                            this.getTranslatedText(res.message),
                            "error"
                        );
                    }
                });
            } else if (this.methodLogin === 1 && this.dataForm.phone !== "") {
                // let token
                // if(this.recaptchaType === 'v2') {
                //     token = grecaptcha.enterprise.getResponse();
                // } else {
                //     token = await grecaptcha.enterprise.execute(this.sitekey, {action: 'login'})
                // }
                // if(!token) {
                //     this.showToast(this.getTranslatedText("recaptcha is required"), "error");
                //     this.isLoadingButton = false
                //     return false
                // }     
                AuthService.loginByPhone(
                    this.dataForm.phone,
                    this.dataForm.password,
                    this.dataForm.calling_code.replace(/\+/g, ""),
                ).then((res) => {
                    if (res.status === 200) {
                        // this.$bvModal.hide("modalRecaptchaLogin");
                        this.onFinishLogin(res.data, 'mobile');
                    } else {
                        this.isLoadingButton = false;
                        // this.$bvModal.hide("modalRecaptchaLogin");
                        if (res.data) {
                          this.dataMessage = res.data
                        } else {
                            let message = "";
                            !isArrayEmpty(res.errors)
                            ? res.errors.forEach((element) => {
                                message += element.message;
                            }) : (message = res.message);
                            this.showToast(this.getTranslatedText(message), "error");
                        }
                    }
                });
            } else {
                AuthService.loginByEmail(
                    this.dataForm.email,
                    this.dataForm.password
                ).then((res) => {
                    if (res.status === 200) {
                        this.onFinishLogin(res.data, 'email');
                    } else {
                        this.isLoadingButton = false;
                        this.showToast(
                            this.getTranslatedText(res.message),
                            "error"
                        );
                    }
                });
            }
        },

        onClickForgotPassword() {
            this.$router.push("/forgot-password");
        },

        // call api check email and phone
        async onBurInputFormData(event, phoneCode) {
            const { name, value } = event.target;
            let { calling_code, phone, email } = this.dataForm;

            switch (true) {
                // check email empty but phone no empty
                case this.formItemEmail[0].state !== null &&
                    name === "email" &&
                    value === "" &&
                    !isStrEmpty(phone):
                    this.formItemEmail[0].state = null;
                    break;
                // check Incorrect email format
                case name === "email" &&
                    !isStrEmpty(value) &&
                    !validateEmail(value):
                    this.formItemEmail[0].state = false;
                    this.formItemEmail[0].textInvalid =
                        "Incorrect email format";
                    this.dataForm.email = value;
                    break;
                // check email already exist
                case name === "email" &&
                    validateEmail(value) &&
                    value !== email:
                    try {
                        const res = await AuthService.checkMail(value);

                        if (res.status === 400) {
                            let message = "";
                            res.errors.forEach((element) => {
                                message += element.message;
                            });
                            this.formItemEmail[0].state = false;
                            this.formItemEmail[0].textInvalid = message;
                        } else {
                            this.formItemEmail[0].state = null;
                        }
                        this.dataForm.email = value;
                    } catch (error) {
                        this.formItemEmail[0].textInvalid = error;
                    }
                    break;
                default:
                    break;
            }
        },
        selectPhoneCode(item) {
            if (
                this.dataForm.calling_code !== item.number &&
                !isStrEmpty(this.dataForm.phone)
            ) {
                let event = {
                    target: {
                        value: this.dataForm.phone,
                        name: "phone",
                    },
                };

                let phoneCode = item.number;

                this.onBurInputFormData(event, phoneCode);
            }
            this.dataForm.calling_code = item.number;
            this.dataForm.code = item.number;
            this.dataForm.flag = item.flag;
            this.dataForm.nameCountry = item.name.replace(
                /^(.{5}[^\s]*).*/,
                "$1"
            );
            this.textSearchPhoneNumber = "";
            this.phoneNumbersFilter = phoneNumbers;
        },
        showDropDow() {
            const dropdown = this.$refs.select_phone_code;
            dropdown.show();
        },
        onChangeFilterPhoneNumbers(value) {
            let phoneNumber = [...phoneNumbers];
            if (!isStrEmpty(value)) {
                this.phoneNumbersFilter = filterFullTextSearch(
                    phoneNumber,
                    value,
                    "name",
                    "number"
                );
            } else {
                this.phoneNumbersFilter = phoneNumber;
            }
        },

        onToggleShowPassword(name) {
            let element =
                this.methodLogin === 2
                    ? this.formItemEmail
                    : this.formItemPhone;
            let index = element.findIndex((x) => x.name === name);
            if (index !== -1) {
                if (this.methodLogin === 2) {
                    this.formItemEmail[index].typeShow =
                        this.formItemEmail[index].typeShow === "password"
                            ? "text"
                            : "password";
                } else {
                    this.formItemPhone[index].typeShow =
                        this.formItemPhone[index].typeShow === "password"
                            ? "text"
                            : "password";
                }
            }
        },
    },
    beforeDestroy() {
      function removeScript(script) {
          if (script && script.parentNode) {
              script.parentNode.removeChild(script);
          }
      }
      removeScript(scriptTag);
      removeScript(scriptTag1);
      removeScript(scriptTag2);
      removeScript(scriptTag3);
      let recap = document.querySelector(".grecaptcha-badge")
      if(recap && recap.parentNode) {
        recap.parentNode.removeChild(recap)
      }
    }
};
</script>

<style scoped lang="scss" src="@/assets/scss/mobile/authenticate/_login.scss" />
