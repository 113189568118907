<template>
    <div>
        <BannerPopup id="show-modal-banner" v-if="isShowBannerPopup==='yes'"/>
        <div v-if="isLoadPage">
            <LoadingApp v-if="isLoadingApp"/>
            <div class="wrapper" v-else :class="addClass()">
                <Header v-if="$route.name !== 'VerifyEmail'"/>
                <a v-if="$store.state.isUpdateProfile && $store.state.isLoggedIn" class="wrapper__warning" href="/personal-detail">
                    <img loading="lazy" class="wrapper__warning__image" src="@/assets/images/toast/error.png" />
                    <p class="wrapper__warning__text" v-html='getTranslatedText(`You account is missing a few details, please update it`)' />
                </a>
                <div class="wrapper__content">
                    <router-view />
                </div>
                <Footer v-if="this.$router.history.current.meta.isShowFooter" />
            </div>
            <Floating :isSetStyle="this.$router.history.current.meta.isSetStyle" v-if="!this.$router.history.current.meta.isHideFloating && isHideAllFloating === 'no'"/>
            <Consent v-if="!this.$router.history.current.meta.isHideConsent && isShowConsent==='yes'"/>
        </div>
    </div>
</template>
<script>
import Header from "@/components/mobile/header/index.vue";
import Footer from "@/components/mobile/footer/index.vue";
import LoadingApp from "@/components/common/loading/index.vue";
import BannerPopup from "@/components/common/banner-popup/index.vue";
import Floating from "@/components/mobile/floating/index.vue";
import Consent from "@/components/mobile/consent/index.vue";
import LanguageMixin from "@/mixins/language/language-mixin";
import toolService from "@/services/tool.service";

const IS_SHOW_CONSENT = process.env.VUE_APP_SHOW_CONSENT;
const IS_SHOW_BANNER_POPUP = process.env.VUE_APP_SHOW_BANNER_POPUP;
const HIDE_ALL_FLOATING = process.env.VUE_APP_HIDE_ALL_FLOATING;

let scriptGoogleTagManager;

export default {
    mixins: [LanguageMixin],
    components: {
        Header,
        Footer,
        LoadingApp,
        Floating,
        Consent,
        BannerPopup
    },
    data() {
        return {
            isLoadingApp: false,
            isLoadPage: false,
            isShowConsent: IS_SHOW_CONSENT,
            isShowBannerPopup: IS_SHOW_BANNER_POPUP,
            isHideAllFloating: HIDE_ALL_FLOATING
        }
    },
    created(){
        if(Object.keys(this.$route.query).length > 0) {
            localStorage.setItem('utm-paramaters', JSON.stringify(this.$route.query))
        }
        if(this.$store.state.is_app) {
            setTimeout(() => {
                this.isLoadingApp = false;
            }, "2000");
            this.isLoadingApp = true;
            const tag = document.createElement("meta");
            tag.name = "robots";
            tag.content = "noindex";
            document.head.appendChild(tag);
        }
        const scriptTag = document.createElement("script");
        scriptTag.type = "text/javascript";
        scriptTag.textContent =`
            (function(c,l,a,r,i,t,y){
                c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
            })(window, document, "clarity", "script", "jk92ax136q");
        `
        document.head.appendChild(scriptTag);

        scriptGoogleTagManager = document.createElement("script");
        scriptGoogleTagManager.type = "text/javascript";
        scriptGoogleTagManager.textContent =`
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-MDV3JX5');
        `
        if(this.isShowConsent === 'yes') {
            this.addScriptConsent()
        }
        this.handleAddScript()
        // this.addScriptWithoutLogin()
        this.fetchMutilanguage()
    },
    methods:{
        addClass() {
            if(this.$router.history.current.meta.hasPaddingTop) {
                return 'pd-top-100'
            } else if(this.$router.history.current.meta.isChangeBgColor) {
                return 'bg-color'
            } else {
                return ''
            }
        },
        addScriptConsent() {
            if (localStorage.getItem('cookieConsent') === 'accepted') {
                const scriptTag = document.createElement('script');
                scriptTag.textContent = `
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('consent', 'update', {
                        'ad_storage': 'granted',
                        'ad_user_data': 'granted',
                        'ad_personalization': 'granted',
                        'analytics_storage': 'granted'
                    });
                    dataLayer.push({'event': 'cookie_consent_accepted'});
                `;
                document.head.appendChild(scriptTag);
            }
        },
        handleAddScript(){
            if(this.$route.name === 'Payment Successful'){
                const scriptElements = document.querySelectorAll('script');
                scriptElements.forEach(script => {
                    if (script.src.includes('https://www.googletagmanager.com/gtm.js?id=GTM-MDV3JX5')) {
                        script.parentNode.removeChild(script);
                    }
                });
                if (scriptGoogleTagManager && scriptGoogleTagManager.parentNode) {
                    scriptGoogleTagManager.parentNode.removeChild(scriptGoogleTagManager);
                }
            }else{
                if(document.head.contains(scriptGoogleTagManager)) return;
                document.head.appendChild(scriptGoogleTagManager);
            }
        },
        // addScriptWithoutLogin() {
        //     if(!this.$store.state.isLoggedIn) {
        //         const scriptTag1 = document.createElement("script");
        //         scriptTag1.src =`https://secure.adnxs.com/seg?add=37009036&t=1`
        //         scriptTag1.type = "text/javascript";
        //         document.head.appendChild(scriptTag1);
        //     }
        // },
        async fetchMutilanguage() {
            try {
                this.isLoadPage = false
                // let supportLocale = ["en", "ar", "in"];
                let supportLocale = ["en"];
                const promises = supportLocale.map(async (lang) => {
                    const data = await toolService.getMutiLanguage(lang);
                    localStorage.setItem(
                        "muti_language_" + lang,
                        JSON.stringify(data)
                    );
                });
                await Promise.all(promises)
                this.isLoadPage = true
                if(this.isShowBannerPopup==='yes') {
                    let n = localStorage.getItem('on_load_counter');
                    if (n === null) {
                        n = 0;
                    }
                    n++;
                    localStorage.setItem("on_load_counter", n);
                    if(n > 3) {
                        this.$bvModal.hide("show-modal-banner");
                    } else {
                        this.$bvModal.show("show-modal-banner");
                    }
                }
            } catch (error) {
                console.log(error);
                this.isLoadPage = false
            }
        },
    },
    watch:{
        $route(to, from) {
            this.handleAddScript()
        }
    }
};
</script>
