import { render, staticRenderFns } from "./index.vue?vue&type=template&id=562a6a1b&scoped=true&data-view=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "@/assets/scss/mobile/static-pages/draws.scss?vue&type=style&index=0&id=562a6a1b&prod&scoped=true&lang=scss&"
import style1 from "@/assets/scss/mobile/wallet/_wallet.scss?vue&type=style&index=1&id=562a6a1b&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "562a6a1b",
  null
  
)

export default component.exports