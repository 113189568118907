<template>
    <div v-if="dataSource">
        <div class="upi">
            <div class="upi-payment">
                <div class="head">
                    <img loading="lazy" src="@/assets/images/common/upi.svg">
                    <div>
                        <p class="title">{{ getTranslatedText('UPI') }}</p>
                        <p class="sub-title">{{ getTranslatedText('Pay with one-set UPI, apps or choose other') }}</p>
                    </div>
                </div>
                <div class="content">
                    <a class="group" @click="$emit('handleUPI')" :href="'gpay://upi' + dataSource.cut_other">
                        <div class="icon"><img loading="lazy" src="@/assets/images/common/ggpay.svg"></div>
                        {{ getTranslatedText('Google Pay') }}
                    </a>
                    <a class="group" @click="$emit('handleUPI')" :href="'phonepe' + dataSource.cut_url">
                        <div class="icon"><img loading="lazy" src="@/assets/images/common/phonepe.svg"></div>
                        {{ getTranslatedText('PhonePe') }}
                    </a>
                    <a class="group" @click="$emit('handleUPI')" :href="'paytmmp' + dataSource.cut_url">
                        <div class="icon"><img loading="lazy" src="@/assets/images/common/paytm.svg"></div>
                        {{ getTranslatedText('PayTM') }}
                    </a>
                </div>
            </div>
        </div>
        <div class="summary">
            <p>{{getTranslatedText('Order Summary')}}</p>
            <div class="sub-order">
                <span>{{ getTranslatedText('Subtotal') }}</span>
                <span>{{ dataSource.currency+ ' ' + dataSource.amountConvert }}</span>
            </div>
            <div class="sub-order gst-main">
                <span class="gst">{{ getTranslatedText('GST') + ' (' + dataSource.gst + '%' + ')'}}</span>
                <span>{{ dataSource.currency + ' ' + dataSource.gstTotal }}</span>
            </div>
            <hr/>
            <div class="sub-order total">
                <span>{{ getTranslatedText('Total') }}</span>
                <span>{{ dataSource.currency+ ' ' + dataSource.amountTotal }}</span>
            </div>
        </div>
    </div>
</template>
<script>
import LanguageMixin from "@/mixins/language/language-mixin";

export default {
    mixins: [LanguageMixin],
    props: {
        dataSource: {
            type: Object,
            default: null,
        }
    },
    data() {
        return {
        };
    },
    components: {},
    methods: {
     
    },
};
</script>
<style scoped lang="scss" src="@/assets/scss/common/upi-payment/upi-payment.scss" />
