<template>
    <div class="payment">
        <BannerCustom title=""/>
        <div class="content">
            <img class="icon-noti" width="70" height="70" src="@/assets/images/payment/fail.svg" alt="" />
            <div class="title-error">{{ getTranslatedText('Payment Failed!') }}</div>
            <div v-if="data && data.message" class="sub-title">{{ data.message }}</div>
            <ButtonCustom
                classBtn="try-button"
                typeBtn="button"
                type="primary"
                text="Try again"
                @onClickButton="onClickMovePage"
            />
        </div>
    </div>
</template>
<script>
import LanguageMixin from "@/mixins/language/language-mixin";
import BannerCustom from "@/components/common/banner";
import ButtonCustom from "@/components/common/button";
import { isArrayEmpty, isStrEmpty } from '@/components/common/utils';

let scriptTag

export default {
    mixins: [LanguageMixin],
    components: { BannerCustom, ButtonCustom },
    props: {
        title: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            data: null,
            methodPayment: -1,
        };
    },
    created() {
        if (isStrEmpty(localStorage.getItem("methodPayment"))) {
            this.$router.push("/home");
        } else {
            this.methodPayment = parseInt(
                localStorage.getItem("methodPayment")
            );
            localStorage.removeItem("methodPayment");
        }
    },
    mounted() {
        this.data = JSON.parse(localStorage.getItem("dataPay"));
        if(this.data && this.data.type === 'checkout') {
            scriptTag = document.createElement('script');
            scriptTag.textContent = `
                window.dataLayer = window.dataLayer || [];
                dataLayer.push({
                    'event': 'green_ticket_purchase_unsuccessful',
                    'order_id': '${this.data.order_id}',
                    'amount': ${this.data.amount},
                    'quantity': ${this.data.quantity},
                    'currency': '${this.data.currency}',
                    'payment_vendor': '${this.data.payment_vendor}',
                    'voucher_code': '${this.data.voucher_code}',
                    'date_draw': '${this.data.draw_date}',
                    'grand_prize': '${this.price(1)}',
                    'eligible_prize': '${this.price(2)}',
                    'draw_number': '${this.data.draw_number}',
                    'double_the_grand_prize': '${this.data.double_the_grand_prize}',
                    'secure_the_grand_prize': '${this.data.secure_the_grand_prize}',
                    'items': ${JSON.stringify(this.data.items)}
                });
            `;
            document.head.appendChild(scriptTag);
        }
    },
    beforeDestroy() {
        function removeScript(script) {
          if (script && script?.parentNode) {
              script.parentNode.removeChild(script);
          }
        }
        removeScript(scriptTag);
        localStorage.removeItem("dataPay")
    },
    methods: {
        price(number) {
            let list_games = !isStrEmpty(localStorage.getItem("list_games"))
                ? JSON.parse(localStorage.getItem("list_games"))
                : [];

            let price = !isArrayEmpty(list_games)
                ? parseFloat(list_games[0]?.estimated_jackpot) * number
                : 0;

            return price;
        },
        onClickMovePage() {
            if(this.data.paymentType && (this.data.paymentType.includes('MBME') || this.data.paymentType.includes('upi'))) {
                if (this.methodPayment === 1) {
                    this.$router.push("/wallet");
                } else {
                    this.$router.push("/checkout");
                }
            } else {
                this.$router.push("/payment");
            }
        },
    }
};
</script>
<style scoped lang="scss" src="@/assets/scss/common/unsuccessful-payment/unsuccessful-payment.scss" />
