<template>
    <div class="section-footer" id="footer-id">
        <div class="section-footer__header">
             <img class="section-footer__logo" loading="lazy" width="213" height="63"
                    @click="routerPush('/')"
                    src="@/assets/images/header/logo.svg"
                />
            <div class="section-footer__header-top">
                <div class="section-footer item-a">
                    <div class="section-footer__need">{{ getTranslatedText('need help?') }}</div>
                    <div class="section-footer__info-contact">
                        <img loading="lazy" width="30" height="30" src="@/assets/images/footer/phone.svg"/>
                        <div class="t-transform-cap">{{ getTranslatedText('800-Millions (800 645 54667)') }}</div>
                    </div>
                    <div class="section-footer__info-contact">
                        <img loading="lazy" width="30" height="30" src="@/assets/images/footer/mail.svg"/>
                        <a class="section-footer__contact-title__email" href="mailto:support@omillionaire.com">
                            {{ getTranslatedText('support@omillionaire.com') }}
                        </a>
                    </div>
                </div>
                <div class="section-footer__group-menu item-b">
                    <div class="head-menu" @click="isActive = !isActive" v-if="isScreenMobile">
                        <div class="link-qui">{{ getTranslatedText('Quick Links') }}</div>
                        <div>
                            <img class="section-footer__icon" loading="lazy"
                                v-if="!isActive"
                                src="@/assets/images/footer/plus.svg"
                            />
                            <img class="section-footer__icon" loading="lazy"
                                v-if="isActive"
                                src="@/assets/images/footer/minus.svg"
                            />
                        </div>
                    </div>
                    <div v-else class="link-qui">{{ getTranslatedText('Quick Links') }}</div>
                    <ul class="section-footer__list-menu" v-if="isActive">
                        <li
                            v-for="(item, index) in listMenu"
                            :key="index"
                            class="section-footer__item-menu"
                        >
                            <a class="t-transform-cap menu" @click="handleClickAccountItem(item)">{{ getTranslatedText(item.title) }}</a>

                        </li>
                    </ul>
                </div>
                <div class="section-footer__contact item-c">
                    <div class="section-footer__contact-title">
                        {{ getTranslatedText('Engage With Us') }}
                    </div>
                    <ul class="section-footer__list-contact">
                        <li
                            v-for="(item, index) in listContact"
                            :key="index"
                            class="section-footer__item-contact"
                            v-if="!item.isHide"
                        >
                            <a :href="item.href" target="_blank">
                                <img loading="lazy"
                                    width="48"
                                    height="48"
                                    class="section-footer__item-contact__icon"
                                    :class="[`section-footer__item-contact__${item.class}`]"
                                    :src="
                                        require(`@/assets/images/footer/${item.icon}`)
                                    "
                                    :alt="item.icon"
                                />
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="section-footer__footer">
            <div class="licensing">
                <div id="anj-38b2b64e-1339-40e9-9c0e-ce114fb86606" data-anj-seal-id="38b2b64e-1339-40e9-9c0e-ce114fb86606" data-anj-image-size="45" data-anj-image-type="basic-small"></div>
                {{ getTranslatedText('Anjouan Licensing Services Inc.') }}
            </div>
            <ul class="section-footer__footer-list-copy">
                <li
                    v-for="(item, index) in listMenuCopyRight"
                    :key="index"
                    class="section-footer__footer-item-copy t-transform-cap"
                >
                    <template v-if="item.href">
                        <a :href="item.href" class="t-transform-cap">{{
                            getTranslatedText(item.title)
                        }}</a>
                    </template>
                    <template v-else>
                        <div class="title-copy">
                            <img loading="lazy" width="18" height="18" src="@/assets/images/footer/c.svg"/>
                            {{ getTranslatedText(item.title) }}
                        </div>
                    </template>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
import languageMixin from "@/mixins/language/language-mixin";
import RouterMixin from "@/mixins/router/router-mixin"
import {
    redirectPathApp,
    redirectNewTab,
} from "@/components/common/utils";

const IS_SHOW_CONSENT = process.env.VUE_APP_SHOW_CONSENT;

export default {
    mixins: [languageMixin, RouterMixin],
    data() {
        return {
            isActive: false,
            screenWidth: window.innerWidth,
            isScreenMobile: false,
            isShowConsent: IS_SHOW_CONSENT,
            listMenu: [
                {
                    title: "Buy Now",
                    href: "/buy/green-certificates",
                    isRedirect: true
                },
                {
                    title: "About Us",
                    href: "/about-us",
                },
                {
                    title: "Brand Ambassador/Host",
                    href: "/brand-ambassador",
                },
                {
                    title: "Draw Results",
                    href: "/draws",
                },
                {
                    title: "Live watch ",
                    href: "/watch-live",
                },
                {
                    title: "Contact Us",
                    href: "/contact-us",
                },
                {
                    title: "The Green Oasis Park",
                    href: "https://oasispark.org/",
                    isNewTab: true
                },
                {
                    title: "Media Center",
                    href: "https://omillionaire.com/media-center/",
                    isNewTab: true
                },
                {
                    title: "News",
                    href: "https://omillionaire-mediacenter.com/",
                    isNewTab: true
                },
                {
                    title: "Shop Now",
                    href: "https://omillionaire.shop/",
                    isNewTab: true
                },
                {
                    title: "T&Cs",
                    href: "/terms-and-conditions",
                },
                {
                    title: "Prize Breakdown",
                    href: "/prize-breakdown",
                },
            ],
            listContact: [
                {
                    icon: "Icon_Youtube.svg",
                    class: "icon-youtube",
                    href: process.env.VUE_APP_YOUTUBE_URL,
                },
                {
                    icon: "Icon_Instagram.svg",
                    href: process.env.VUE_APP_INSTAGRAM_URL,
                },
                {
                    icon: "Icon_Facebook.svg",
                    href: process.env.VUE_APP_FACEBOOK_URL,
                },
                {
                    icon: "Icon_Twitter.svg",
                    href: process.env.VUE_APP_TWITTER_URL,
                },
                {
                    icon: "Icon_Chat.svg",
                    href: process.env.VUE_APP_WHATSAPP_URL,
                    isHide: process.env.VUE_APP_HIDE_ALL_WHATSAPP === 'yes'
                },
                {
                    icon: "Icon_Tiktok.svg",
                    href: process.env.VUE_APP_TIKTOK_URL,
                },
                {
                    icon: "Icon_Linkedin.svg",
                    href: process.env.VUE_APP_LINKEDIN_URL,
                },
                {
                    icon: "Icon_Snapchat.svg",
                    href: process.env.VUE_APP_SNAPCHAT_URL,
                },
            ],
            listMenuCopyRight: [
                {
                    title: `O! Millionaire ${new Date().getFullYear()} All Rights Reserved ${new Date().getFullYear()}`,
                }
            ],
        };
    },
    watch: {
        screenWidth(value) {
            if (value <= 991) {
                this.isScreenMobile = true
            }
            else {
                this.isScreenMobile = false
                this.isActive = true
            }
        },
    },
    created() {
        if (this.screenWidth <= 991) {
                this.isScreenMobile = true
            }
        else {
            this.isScreenMobile = false
            this.isActive = true
        }
        window.addEventListener('resize', this.handleResize)
    },
    mounted() {
        anj_38b2b64e_1339_40e9_9c0e_ce114fb86606.init()
        if(!localStorage.getItem('cookieConsent') && this.isShowConsent=== 'yes') {
            if(document.getElementById("footer-id")) {
                document.getElementById("footer-id").style.marginBottom = "200px"
            }
        } else {
            document.getElementById("footer-id").style.marginBottom = "0"
        }
    },
    methods: {
        handleResize() {
            this.screenWidth = window.innerWidth
        },
        handleClickAccountItem(item) {
            if (item.isNewTab) {
                redirectNewTab(item.href);
            } else if (this.$store.state.is_app && item.isRedirect) {
                redirectPathApp(item.href);
            } else {
                this.routerPush(item.href);
            }
        },
    },
};
</script>
<style scoped lang="scss" src="@/assets/scss/mobile/_footer.scss" />
