<template>
     <b-modal
        :id="id"
        modal-class="modal-fullscreen share-credit"
        style="max-width: 100% !important"
        centered
        header-class="modal-header"
        hide-footer
        hide-header
        no-close-on-backdrop
        @show="resetModal"
        @hidden="resetModal"
    >
        <div class="close-btn" @click="resetModal"><img loading="lazy" src="@/assets/images/common/close.svg"/></div>
        <div class="section-mobile-number">
            <div class="section-mobile-number__header">
                <span>{{ getTranslatedText("share credit") }}</span>
            </div>
            <div class="section-mobile-number__content-header">
                <ButtonCustom
                    type="go-back"
                    text="mobile number"
                    :isGoBackHistory="false"
                    @onClickButton="$emit('onClickGoBack')"
                />

                <div class="section-mobile-number__box-btn-desktop" slot="button">
                    <button
                        class="t-transform-cap"
                        type="submit"
                        :disabled="isLoadingButton"
                        @click="onSubmitForm"
                    >
                        <b-spinner v-if="isLoadingButton" />
                        {{ getTranslatedText("confirm") }}
                        <img loading="lazy"
                            src="@/assets/images/wallet/vector-right.png"
                            alt="vector-right"
                        />
                    </button>
                </div>

                <div
                    class="section-mobile-number__transfer-amount section-mobile-number__transfer-amount__mobile"
                >
                    <p
                        class="section-mobile-number__transfer-amount-text t-transform-cap"
                    >
                        {{ getTranslatedText("amount") }}
                    </p>
                    <div class="section-mobile-number__transfer-amount-price">
                        <b-form-input
                            v-model="amount"
                            id="input-add-credit"
                            @blur="formatter"
                            @keydown.native="checkDigit"
                        ></b-form-input>
                        <!-- <b-form-input
                            v-model="amount"
                            id="input-amount-phone"
                            @blur="formatter"
                            @keydown.native="checkDigit"
                        ></b-form-input> -->
                        <span>{{ getTranslatedText(currencyCode) }}</span>
                    </div>
                </div>
            </div>

            <div class="section-mobile-number__content-form">
                <div
                    class="section-mobile-number__transfer-amount section-mobile-number__transfer-amount__desktop"
                >
                    <p
                        class="section-mobile-number__transfer-amount-text t-transform-cap"
                    >
                        {{ getTranslatedText("amount") }}
                        <span class="obligatory">*</span>
                    </p>
                    <div class="section-mobile-number__transfer-amount-price">
                        <b-form-input
                            placeholder="enter amount here"
                            v-model="amount"
                            id="input-amount-phone-transfer"
                            @blur="formatter"
                            @keydown.native="checkDigit"
                        ></b-form-input>
                    </div>
                </div>
                <p class="section-mobile-number__content-form-title">
    <!--                {{-->
    <!--                    getTranslatedText(-->
    <!--                        `minimum the withdrawal amount is ${limitAmount} OMR`-->
    <!--                    )-->
    <!--                }}-->
                </p>
                <FormCommon
                    :formItem="formItem"
                    :dataForm="dataForm"
                    @update-form-data="updateFormData"
                    @onSubmitForm="onSubmitForm"
                >
                    <b-input-group-prepend
                        slot="propend-phone-number"
                        class="phone-code"
                    >
                        <button
                            class="show-dropdown"
                            type="button"
                            @click="showDropDow"
                        >
                        <div class="flag-group">
                            <img loading="lazy"
                                class="user-avatar"
                                :src="
                                    require(`@/assets/images/flags/${dataForm.flag}`)
                                "
                                :alt="dataForm.flag"
                            />
                        </div>
                            {{ dataForm.calling_code }}
                        </button>

                        <b-dropdown ref="select_phone_code">
                            <b-form-input
                                v-model="textSearchPhoneNumber"
                                :placeholder="getTranslatedText('search')"
                                @input="onChangeFilterPhoneNumbers"
                            ></b-form-input>
                            <b-dropdown-item
                                @click="selectPhoneCode(item)"
                                v-for="(item, index) in phoneNumbersFilter"
                                :key="index"
                            >
                                <div>
                                    <div class="flag-group">
                                    <img loading="lazy"
                                        class="user-avatar"
                                        :src="
                                            require(`@/assets/images/flags/${item.flag}`)
                                        "
                                        :alt="item.flag"
                                    />
                                    </div>
                                    <span class="name-country">{{
                                        item.name
                                    }}</span>
                                    ({{ item.number }})
                                </div>
                            </b-dropdown-item>
                        </b-dropdown>
                        <img loading="lazy"
                            class="dropdown-phone-icon"
                            src="@/assets/images/personal-detail/dropdown-black.png"
                        />
                    </b-input-group-prepend>

                    <p
                        slot="textOr"
                        class="section-mobile-number__text-form t-transform-cap"
                    >
                        {{ getTranslatedText("or") }}
                    </p>

                    <div class="section-mobile-number__box-btn" slot="button">
                        <button
                            class="t-transform-cap"
                            type="submit"
                            :disabled="isLoadingButton"
                        >
                            <b-spinner v-if="isLoadingButton" />
                            {{ getTranslatedText("continue to transfer") }}
                            <img loading="lazy"
                                src="@/assets/images/wallet/vector-right.png"
                                alt="vector-right"
                            />
                        </button>
                    </div>
                </FormCommon>
            </div>
            <div class="guide">
                <p>{{ getTranslatedText('Share credit by entering the mobile number of a registered user.') }}</p>
                <br/>
                <a @click="$router.push('/terms-and-conditions')">{{ getTranslatedText('Terms and Conditions') }}</a>
                {{ getTranslatedText('apply') }}
            </div>
        </div>
     </b-modal>
</template>
<script>
import { phoneNumbers } from "@/resources/phoneNumber";
import {
    checkDigitCommon,
    filterFullTextSearch,
    isStrEmpty,
    renderLocalePrice
} from "@/components/common/utils";

import FormCommon from "@/components/common/form";
import ButtonCustom from "@/components/common/button";
import languageMixin from "@/mixins/language/language-mixin";
import defaultCountryCodeMixin from "@/mixins/default-country-code/default-country-code-mixin";
import AmountPopup from "@/components/common/amount-popup";

const VUE_APP_WITH_AMOUNT = process.env.VUE_APP_WITH_AMOUNT;
const VUE_APP_WITH_AMOUNT_MAXIUM = process.env.VUE_APP_WITH_AMOUNT_MAXIUM_SHARE_CREDIT;
const VUE_APP_WITH_AMOUNT_MINIMUM = process.env.VUE_APP_WITH_AMOUNT_MINIMUM;
const CURRENCY_CODE = process.env.VUE_APP_CURRENCY_CODE;
const EXCHANGE_RATE = process.env.VUE_APP_EXCHANGE_RATE;

export default {
    mixins: [languageMixin, defaultCountryCodeMixin],
    props: {
        isLoadingButton: {
            type: Boolean,
            default: false,
        },
        id: {
            type: String,
            default: "",
        },
    },
    components: { FormCommon, ButtonCustom, AmountPopup },
    data() {
        return {
            toastData: {
                message: "",
                type: "success",
            },
            textSearchPhoneNumber: '',
            limitAmount: renderLocalePrice(parseFloat(VUE_APP_WITH_AMOUNT * EXCHANGE_RATE)),
            minimumAmount: VUE_APP_WITH_AMOUNT_MINIMUM * EXCHANGE_RATE,
            maxiumAmount: VUE_APP_WITH_AMOUNT_MAXIUM * EXCHANGE_RATE,
            phoneNumbers,
            phoneNumbersFilter: phoneNumbers,
            amount: renderLocalePrice(parseFloat(VUE_APP_WITH_AMOUNT_MINIMUM * EXCHANGE_RATE)),
            currencyCode: CURRENCY_CODE,
            exchangeRate: EXCHANGE_RATE,
            dataForm: {
                flag: "Flag_of_Oman.svg",
                calling_code: "+968",
                code: "+968",
                phone: "",
                nameCountry: "Oman",
            },
            formItem: [
                {
                    type: "input-group",
                    typeInput: "text",
                    placeholder: "",
                    name: "phone",
                    label: "mobile number",
                    required: true,
                    showIconRequired: true,
                    for: "input-phone",
                    id: "input-phone",
                    prepend: "code",
                    nameSlotInputPrepend: "propend-phone-number",
                    state: null,
                    textInvalid: "please enter phone",
                    checkDigit: this.checkDigit,
                }
            ],
        };
    },
    mounted() {
        this.handleDefaultCountry(this.dataForm)
    },
    methods: {
        resizeInput() {
            let input = document.getElementById("input-amount-phone");
            input.style.width =
                input.value.replaceAll(".", "").length + 1 + "ch";
        },
        resetModal() {
            this.$bvModal.hide(this.id);
            this.dataForm.phone = ''
            this.amount = renderLocalePrice(parseFloat(VUE_APP_WITH_AMOUNT_MINIMUM * EXCHANGE_RATE))
        },
        onSubmitForm() {
            if (
                !this.amount ||
                parseFloat(this.amount.replaceAll(",", "")) === 0
            ) {
                this.$emit(
                    "showToast",
                    "please enter amount",
                    "error"
                );

                return false;
            }

            if (
                parseFloat(this.amount.replaceAll(",", "")) <
                parseFloat(this.minimumAmount)
            ) {
                this.$emit(
                    "showToast",
                    "Minimum amount is " + this.minimumAmount + ' ' + this.currencyCode,
                    "error"
                );

                return false;
            }

            if (
                parseFloat(this.amount.replaceAll(",", "")) >
                parseFloat(this.maxiumAmount)
            ) {
                this.$emit(
                    "showToast",
                    "Maximum withdrawal amount is " +
                        this.maxiumAmount + ' ' + 
                        this.currencyCode,
                    "error"
                );

                return false;
            }

            let param = { ...this.dataForm };
            param["amount"] = parseFloat(this.amount.replaceAll(",", "") / this.exchangeRate);
            this.$emit("onSubmit", param);
        },

        updateFormData(item) {
            let { value, name } = item;
            this.dataForm[name] = value;
        },

        // change phone code
        selectPhoneCode(item) {
            if (
                this.dataForm.calling_code !== item.number &&
                !isStrEmpty(this.dataForm.phone)
            ) {
                let event = {
                    target: {
                        value: this.dataForm.phone,
                        name: "phone",
                    },
                };

                let phoneCode = item.number;

                // this.onBurInputFormData(event, phoneCode);
            }
            this.dataForm.calling_code = item.number;
            this.dataForm.code = item.number;
            this.dataForm.flag = item.flag;
            this.dataForm.nameCountry = item.name.replace(
                /^(.{5}[^\s]*).*/,
                "$1"
            );
            this.textSearchPhoneNumber = "";
            this.phoneNumbersFilter = phoneNumbers;
        },
        showDropDow() {
            const dropdown = this.$refs.select_phone_code;
            dropdown.show();
        },

        formatter(event) {
            const { value } = event.target;
            let isContainsLowercase = /^(?=.*[a-z]).*$/;
            let isNumber = /^[0-9.]*$/
            this.amount = !isStrEmpty(value) && !isContainsLowercase.test(value) && isNumber.test(value) ? parseFloat(value.replace(/,/g, ""))
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '0.00';
            // let input = document.getElementById("input-amount-phone");
            // let length = this.amount.replaceAll(".", "").length;
            // let numberMore = (this.amount.match(/,/g) || []).length;
            // numberMore = numberMore > 0 ? -numberMore + 1 : 0.5;
            // input.style.width = length + numberMore + "ch";
        },

        checkDigit(event) {
            return checkDigitCommon(event);
        },
        onChangeFilterPhoneNumbers(value) {
            let phoneNumber = [...phoneNumbers];
            if (!isStrEmpty(value)) {
                this.phoneNumbersFilter = filterFullTextSearch(
                    phoneNumber,
                    value,
                    "name",
                    "number"
                );
            } else {
                this.phoneNumbersFilter = phoneNumber;
            }
        },
    },
};
</script>

<style scoped lang="scss" src="@/assets/scss/mobile/wallet/_wallet.scss" />
