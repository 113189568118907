<template>
    <div>
        <div class="loading-full-page" v-if="isLoadingPagePayment">
            <b-card>
                <b-skeleton animation="wave" width="85%"></b-skeleton>
                <b-skeleton animation="wave" width="55%"></b-skeleton>
                <b-skeleton animation="wave" width="70%"></b-skeleton>
            </b-card>
        </div>
        <div class="loading-full-page" v-else-if="isLoadingPage">
            <b-card>
                <b-skeleton animation="wave" width="85%"></b-skeleton>
                <b-skeleton animation="wave" width="55%"></b-skeleton>
                <b-skeleton animation="wave" width="70%"></b-skeleton>
            </b-card>
        </div>
        <div
            v-else
            class="section-checkout"
            :class="{
                'bgr-white': step === 2,
                'no-padding': listCheckouts.length === 0,
                'padding-bottom': isScroll,
            }"
        >
            <div class="main" :class="listCheckouts.length === 0 ? 'main-empty' : ''" v-if="step === 1" id="main-checkout">
            <DrawsType/>
            <div class="no-empty" v-if="listCheckouts.length === 0">
            <div class="group-title">
                <p class="title">{{ getTranslatedText('YOUR SHOPPING CART')}}</p>
                <p class="sub-title">{{ getTranslatedText('Each Green Certificate you purchase is valid as')}}
                    <span>{{ getTranslatedText('ONE ITEM') }}.</span>
                </p>
            </div>    
            <NoEmpty
                :title="`${getTranslatedText(
                    'YOUR SHOPPING CART'
                )}`"
                :subTitle="`${getTranslatedText(
                    'Your Cart is Empty.'
                )}`"
                :description="`${getTranslatedText(
                    'Click on Buy Now to purchase your Green Certificate.'
                )}`"
                :isShowEmptyCart="true"
            />
            </div>
            <template v-else>
                    <div class="checkout-contents">
                        <div class="section-checkout__breadcrumbs">
                            <h3 class="section-checkout__breadcrumbs__title">{{ getTranslatedText('YOUR SHOPPING CART') }}</h3>
                            <p class="section-checkout__breadcrumbs__description">
                                {{
                                    getTranslatedText(
                                        "Each Green Certificate Tree Seed you purchase is valid as"
                                    )
                                }}
                                <span>{{ getTranslatedText('ONE ITEM') }}</span>
                            </p>
                        </div>
                        <div class="checkout-content">
                            <div class="section-checkout__items" :class="{ 'show-one-item': listCheckouts.length === 1 }">
                                <div
                                    v-for="(item, index) in listCheckouts"
                                    :key="index"
                                    class="section-checkout__item"
                                >
                                    <div class="section-checkout__item__left--title">
                                        <div>{{ renderTitleTicket(item.name) }}</div>
                                        <div v-if="isScreenMobile" class="section-checkout__item__left--manager">
                                            <div class="section-checkout__item__left--edit">
                                                <span @click="onClickConfirmModal(item.id)"><img loading="lazy" src="@/assets/images/common/delete-item.svg"></span>
                                                <span @click="handleEditInfoItem(item.idBlock)"><img loading="lazy" src="@/assets/images/common/edit-item.svg"></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="section-checkout__item__top">
                                        <div class="section-checkout__item__left">
                                            <div class="section-checkout__item__left--date">
                                                <div class="date">{{  getTranslatedText("draw date")}}:</div>
                                                <div class="date-value">{{ item.draw_date }}</div>
                                                <!-- <div class="section-checkout__item__left--time-left">{{ updateRemainingTime(item.date) }}</div> -->
                                            </div>
                                            <div v-if="!isScreenMobile" class="section-checkout__item__left--manager">
                                                <div class="section-checkout__item__left--edit">
                                                    <span @click="onClickConfirmModal(item.id)"><img loading="lazy" src="@/assets/images/common/delete.svg"></span>
                                                    <span @click="handleEditInfoItem(item.idBlock)"><img loading="lazy" src="@/assets/images/common/edit.svg"></span>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="section-checkout__item__numbers">
                                            <div v-for="(number, index) in item.bets" :key="index" :class="`section-checkout__item__number ${renderClass( number)}`">
                                                <div class="circle-inside">
                                                    <a> {{ renderNumberLocale(number) }} </a>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- <div class="section-checkout__item__handle">
                                            <div class="section-checkout__item__handle--text">
                                                <span>{{ getTranslatedText('Subscribe to play the same numbers') }}:</span>
                                                <img loading="lazy"
                                                    style="cursor: pointer"
                                                    src="@/assets/images/checkout/info.svg"
                                                    v-b-tooltip.hover
                                                    :title="getTranslatedText('You have selected to purchase the same item each week. Free cancellation anytime.')"
                                                />
                                            </div>
                                            <div class="section-checkout__item__handle--button">
                                                <ButtonCustom
                                                    typeBtn="button"
                                                    text="Next Draw"
                                                    type="default"
                                                    :class="{'active': item.activeNextDraw}"
                                                    classBtn="button-next"
                                                    @onClickButton="toggleItemActive(index, 'nextDraw')"
                                                />
                                                <ButtonCustom
                                                    typeBtn="button"
                                                    text="Weekly"
                                                    type="default"
                                                    classBtn="button-weekly"
                                                    :class="{'active': item.activeWeekly}"
                                                    @onClickButton="toggleItemActive(index, 'weekly')"
                                                />
                                            </div>
                                        </div> -->
                                        <div class="section-checkout__item__right">
                                            <div v-for="(check, key) in item.doubles" :key="key" class="section-checkout__item__right--check">
                                                <div class="section-checkout__item__right--check-item">
                                                    <div class="section-checkout__item__right--check-icon"  @click="onChangeDouble(index,key)">
                                                        <div v-if="check.id === 1" :class="{'active': check.isCheck}"></div>
                                                        <div v-else :class="{'active': check.isCheck}"></div>
                                                    </div>
                                                    <div class="section-checkout__item__right--check-text" >
                                                        {{ check.title }}
                                                        <img loading="lazy"
                                                            style="cursor: pointer"
                                                            src="@/assets/images/checkout/info.svg"
                                                            v-b-tooltip.hover
                                                            :title="
                                                                check.id === 2
                                                                    ? getTranslatedText(
                                                                            'Selecting SECURE THE GRAND PRIZE will allow you to secure the GRAND PRIZE without splitting with other winners.'
                                                                        )
                                                                    : getTranslatedText(
                                                                            'Selecting DOUBLE THE GRAND PRIZE will allow you to double the GRAND PRIZE.'
                                                                        )
                                                            "
                                                        />
                                                    </div>
                                                    <div class="currency">{{ getTranslatedText(currencyCode) }}</div>
                                                    <div class="section-checkout__item__right--check-value">
                                                        <span class="section-checkout__price">{{ formatLocalePrice(check.value) }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="section-checkout__item__right--result">
                                                <div class="section-checkout__item__right--result-double" >
                                                    {{ getTranslatedText(currencyCode) }}
                                                    <span v-if="item.doubles && item.doubles[0]?.isCheck">{{ formatNumberWinners(2) }}</span>
                                                    <span v-else>{{ formatNumberWinners(1) }}</span>
                                                </div>
                                                <div class="currency">{{ getTranslatedText(currencyCode) }}</div>
                                                <div class="section-checkout__item__right--result-main">
                                                    <span class="section-checkout__price">{{ formatLocalePrice(item.amountClone) }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="section-checkout__footer">
                                <div class="section-checkout__footer__left">
                                  
                                <div class="section-checkout__footer__left--btn">
                                    <div class="section-checkout__footer__left--voucher">
                                        <span>{{ getTranslatedText('Redeem Gift Card') }}</span>
                                        <div>
                                            <b-form-input
                                            v-model="voucherCode"
                                            type="text"
                                            :placeholder="getTranslatedText('Eg. Your text here')"
                                            ></b-form-input>
                                            <ButtonCustom
                                                typeBtn="button"
                                                type="primary"
                                                text="Validate"
                                                classBtn="button-validate"
                                                @onClickButton="handleValidateVoucher"
                                                :isDisabled="voucherCode === '' "
                                                :isHiddenValue="isLoadingBtnValidate"
                                                :isLoading="isLoadingBtnValidate"
                                            />
                                        </div>
                                        <p v-if="messageVoucher.message" class="message-voucher" :class="messageVoucher.isSuccess ? 'success' : 'failed'">{{ getTranslatedText(messageVoucher.message) }}</p>
                                        <div class="email-input" v-if="(paymentType.includes('MBME') || paymentType.includes('upi')) && !emailExist && totalAmount > 0">
                                            <span>{{ getTranslatedText('Email') }}</span>
                                            <b-form-input
                                            v-model="valueEmail"
                                            type="text"
                                            :placeholder="getTranslatedText('Eg. Your text here')"
                                            @input="(value) => changeEmail(value)"
                                            ></b-form-input>
                                            <p v-if="messageEmail" class="message-voucher failed">{{ getTranslatedText(messageEmail) }}</p>
                                        </div>
                                    </div>
                                    <div class="section-checkout__footer__select-payment" id="payment-method" v-if="this.totalAmount !== 0 && $store.state.isLoggedIn">
                                        <p class="title">{{ getTranslatedText('Select Payment Method') }}</p>
                                        <b-form-radio-group
                                            v-model="paymentSelected"
                                            :options="optionsPayment"
                                            @change="updatePaymentMethod"
                                            class="option-payment"
                                            value-field="item"
                                            text-field="name"
                                        ></b-form-radio-group>
                                        <p v-if="messagePaymentSelected" class="message-error">{{ getTranslatedText(messagePaymentSelected) }}</p>
                                    </div>
                                <div class="section-checkout__footer__right " id="action-checkout">

                                    <div class="section-checkout__footer__right--voucher">
                                        <span>{{ getTranslatedText('Redeem Gift Card') }}</span>
                                        <div>
                                            <b-form-input
                                            v-model="voucherCode"
                                            type="text"
                                            :placeholder="getTranslatedText('Eg. Your text here')"
                                            ></b-form-input>
                                            <ButtonCustom
                                                typeBtn="button"
                                                type="primary"
                                                text="Validate"
                                                classBtn="button-validate"
                                                @onClickButton="handleValidateVoucher"
                                                :isDisabled="voucherCode === '' "
                                                :isHiddenValue="isLoadingBtnValidate"
                                                :isLoading="isLoadingBtnValidate"
                                            />
                                        </div>
                                        <p v-if="messageVoucher.message" class="message-voucher" :class="messageVoucher.isSuccess ? 'success' : 'failed'">{{ getTranslatedText(messageVoucher.message) }}</p>
                                        <div class="email-input" v-if="paymentType.includes('MBME') && !emailExist && totalAmount > 0">
                                            <span>{{ getTranslatedText('Email') }}</span>
                                            <b-form-input
                                            v-model="valueEmail"
                                            type="text"
                                            :placeholder="getTranslatedText('Eg. Your text here')"
                                            @input="(value) => changeEmail(value)"
                                            ></b-form-input>
                                            <p v-if="messageEmail" class="message-voucher failed">{{ getTranslatedText(messageEmail) }}</p>
                                        </div>
                                    </div>
                                    <div class="section-checkout__footer__right--price">
                                        <div class="title">{{ isScreenMobile ? getTranslatedText("Order SUMMARY") : getTranslatedText('Summary') }}</div>
                                        <div class="section-checkout__footer__right--subtotal">
                                            <span>{{ getTranslatedText('Subtotal') }}</span>
                                            <span><span>{{ getTranslatedText(currencyCode) }}</span>{{ formatLocalePrice(subtotal) }}</span>
                                        </div>
                                        <div class="section-checkout__footer__right--wallet-redeemed" v-if="walletReemed !== 0">
                                            <span>{{ getTranslatedText('Gift Card Redeemed') }}</span>
                                            <span><span>-{{ getTranslatedText(currencyCode) }}</span>{{ formatLocalePrice(walletReemed) }}</span>
                                        </div>
                                        <div class="section-checkout__footer__right--wallet-redeemed" v-if="balance !== 0">
                                            <span>{{ getTranslatedText('Wallet Balance') }}</span>
                                            <span><span>-{{ getTranslatedText(currencyCode) }}</span>{{ formatLocalePrice(balance) }}</span>
                                        </div>
                                        <div class="section-checkout__footer__right--total">
                                            <span class="total">{{ getTranslatedText('Total Amount') }}</span>
                                            <span><span>{{ getTranslatedText(currencyCode) }}</span>{{ formatLocalePrice(totalAmount) }}</span>
                                        </div>
                                        <p class="error-upi">{{getTranslatedText(messageAmountUPI)}}</p>
                                    </div>
                                    <div class="section-checkout__footer__right--btn">
                                        <ButtonCustom
                                            :isDisabled="isLoadingBtnClear"
                                            :isHiddenValue="isLoadingBtn || isLoadingPayment "
                                            :isLoading="isLoadingBtn || isLoadingPayment"
                                            classBtn="button-check-out"
                                            typeBtn="button"
                                            type="primary"
                                            text="checkout"
                                            @onClickButton="handleRedirectBeforePayment()"
                                        />
                                    </div>
                                    <div 
                                        class="btn-checkout-mb" @click="handleRedirectBeforePayment('card')" 
                                        :class="getClassPaymentCheckout()" id="checkout-payment-card"
                                        v-if="paymentSelected !== 'apple-pay' && paymentSelected !== 'google-pay'">
                                        <div class="load" v-if="isLoadingBtn"><b-spinner/></div>
                                        <div class="content">
                                            <p class="item">
                                                {{ listCheckouts.length === 1 ? getTranslatedText('1 item') : listCheckouts.length + ' ' + getTranslatedText('items') }}
                                            </p>
                                            <p class="currency"><span>{{ getTranslatedText(currencyCode) }} </span>{{ formatLocalePrice(totalAmount) }}</p>
                                        </div>
                                        <p class="checkout">{{ getTranslatedText('checkout') }}</p>
                                        <img class="circle" loading="lazy" src="@/assets/images/common/circle.svg">
                                    </div>
                                    <div v-if="paymentSelected === 'apple-pay' && paymentType === 'ccavenue'" id="apple-pay-payment" :class="getClassPaymentCheckout()"
                                        class="apple-pay-button-with-text-cart apple-pay-button-white-with-text" @click="beginApplePaySession">
                                        <span class="text">Buy with</span>
                                        <span class="logo"></span>
                                    </div>
                                    <div id="express-checkout-element" :class="getClassPaymentCheckout()"></div>
                                </div>
                                <div class="section-checkout__footer__left--checkbox-ip">
                                    <label for="agree_checkout">
                                        <span class="text_terms_conditions">{{ getTranslatedText(`By finalizing your purchase through the Check Out button, you agree to O! Millionaire's`) }}
                                        <a href="/terms-and-conditions" target="_blank">{{ getTranslatedText('Terms and Conditions.') }}</a>
                                        {{ getTranslatedText('All payments will be deducted from your accounts in AED and inclusive of VAT.') }}
                                        </span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                    <div class="button-action">
                        <ButtonCustom
                            classBtn="button-back"
                            typeBtn="button"
                            type="default"
                            text="back"
                            :isDisabled="isLoadingBtn || isLoadingPayment || isLoadingBtnClear"
                            @onClickButton="onGoBackPage"
                        />

                        <ButtonCustom
                        classBtn="button-clear-all"
                            typeBtn="button"
                            type="default"
                            text="clear all"
                            :isDisabled="isLoadingBtn || isLoadingPayment"
                            :isLoading="isLoadingBtnClear"
                            @onClickButton="onClickConfirmModal(-1)"
                        />
                    </div>
                <ConfirmModal
                    id="modal-confirm-remove-block"
                    :title="
                        getTranslatedText('are you sure you want to delete?')
                    "
                    :description="
                        getTranslatedText(
                            'once the number is deleted cannot be recovered. Do you want to delete it?'
                        )
                    "
                    textBtnCancel="Cancel"
                    textBtnDelete="Delete"
                    :isLoadingButton="isLoadingButtonConfirm"
                    :showBtnCancel="true"
                    @onCancelModal="onCancelRemove"
                    @onDelete="
                        idIndex !== -1
                            ? handleDeleteInfoItem(idIndex)
                            : onClearCart()
                    "
                />
            </template>
            <template v-if="step === 2">
                <CardDetail
                    :totalAmount="totalAmountPayment"
                    :paramCheckout="paramCheckout"
                    :isRecurring="isRecurring"
                    @onClickGoBack="onClickGoBack"
                    @showToast="showToast"
                />
            </template>
            </div>
            <div v-if="this.listCards.length > 0">
                <vue-bottom-sheet max-height="90%" ref="bottomChangeCard">
                    <BottomSheet :isHiddenBtn="true">
                        <template slot="body-sheet">
                            <div class="section-add-credit__bottom-change-card">
                                <h1
                                    class="section-add-credit__bottom-change-card-title t-transform-cap"
                                >
                                    {{
                                        getTranslatedText("select credit card")
                                    }}
                                </h1>
                                <FormCommon
                                    :formItem="formItem"
                                    @onSubmitForm="onSubmitChangeCard"
                                >
                                    <template slot="radioButtonCard">
                                        <b-form-group>
                                            <b-form-radio
                                                v-model="cardId"
                                                class="section-add-credit__item-card"
                                                v-for="(
                                                    item, index
                                                ) in listCards"
                                                :value="item.id"
                                                :key="index"
                                                ><div
                                                    class="section-add-credit__change-card"
                                                >
                                                    <div
                                                        class="section-add-credit__change-card-info"
                                                    >
                                                        <h4
                                                            class="section-add-credit__change-card-info-number"
                                                        >
                                                            {{
                                                                item.card_number
                                                            }}
                                                        </h4>
                                                        <p
                                                            class="section-add-credit__change-card-info-description t-fir-transform-cap"
                                                        >
                                                            {{
                                                                getTranslatedText(
                                                                    "valid thru 24/28"
                                                                )
                                                            }}
                                                        </p>
                                                        <p
                                                            class="section-add-credit__change-card-info-name"
                                                        >
                                                            {{
                                                                item.card_holder_name
                                                            }}
                                                        </p>
                                                        <div
                                                            v-if="
                                                                item.is_default
                                                            "
                                                            class="section-add-credit__change-card-info-default t-transform-cap"
                                                        >
                                                            {{
                                                                getTranslatedText(
                                                                    "marked as default"
                                                                )
                                                            }}
                                                        </div>
                                                    </div>
                                                    <img loading="lazy"
                                                        class="section-add-credit__card-type"
                                                        :src="
                                                            require(`@/assets/images/common/${renderTypeCard(
                                                                item.card_type_name
                                                            )}`)
                                                        "
                                                        alt="card"
                                                    />
                                                </div>
                                            </b-form-radio>
                                        </b-form-group>
                                        <div class="bottom-change-card">
                                            <ButtonCustom
                                                typeBtn="button"
                                                type="default"
                                                classBtn="button-change-card"
                                                text="add new card"
                                                @onClickButton="
                                                    $router.push(
                                                        '/manage-cards'
                                                    )
                                                "
                                            />
                                            <ButtonCustom
                                                typeBtn="submit"
                                                type="primary"
                                                classBtn="button-change-card"
                                                text="select"
                                                :isLoading="isLoadingChangeCard"
                                            />
                                        </div>
                                    </template>
                                </FormCommon>
                            </div>
                        </template>
                    </BottomSheet>
                </vue-bottom-sheet>
            </div>

            <MethodPayment
                id="modalMethodPayment"
                :totalAmountPayment="totalAmountPayment"
                @showToast="showToast"
                methodPayment="2"
            />
            <b-modal
                id="input-ccv"
                centered
                v-model="modalShowCvv"
                :title="getTranslatedText('CVV')"
                :hide-footer="true"
            >
                <div class="input-ccv">
                    <b-form-input
                        :state="stateCvv"
                        id="input-live"
                        v-model="card_security_code"
                        type="password"
                        aria-describedby="input-live-cvv"
                        :placeholder="getTranslatedText('Enter cvv')"
                    ></b-form-input>
                    <b-form-invalid-feedback id="input-live-cvv">
                        {{ getTranslatedText("enter 3 numbers") }}
                    </b-form-invalid-feedback>
                    <ButtonCustom
                        typeBtn="button"
                        type="primary"
                        text="Done"
                        @onClickButton="onAddSvv"
                    />
                </div>
            </b-modal>
            <b-modal
                v-if="status"
                id="modalNotifyPayment"
                modal-class="modal-fullscreen"
                centered
                body-class="notify__modal-body"
                hide-header
                hide-footer
                :no-close-on-backdrop="true"
                :hide-header-close="true">
                <div v-if="status === 'loading'">
                    <span class="loading"></span>
                </div>
                <div v-if="status === 'success'">
                    <img class="icon-noti" width="70" height="70" src="@/assets/images/payment/success.svg" alt="" />
                    <div class="title title-success">{{ getTranslatedText('Payment Successful!') }}</div>
                    <div class="sub-title">{{ getTranslatedText('Generating the Green Certificate. Please wait.') }}</div>
                    <span class="loader"></span>
                </div>
                <div v-if="status === 'fail'">
                    <img class="icon-noti" width="70" height="70" src="@/assets/images/payment/fail.svg" alt="" />
                    <div class="title title-error">{{ getTranslatedText('Payment Unsuccessful!') }}</div>
                    <div v-if="messagePaymentFail" class="sub-title">{{ messagePaymentFail }}</div>
                    <ButtonCustom
                        classBtn="try-button"
                        typeBtn="button"
                        type="primary"
                        text="Try again"
                        @onClickButton="$bvModal.hide('modalNotifyPayment')"
                    />
                </div>
            </b-modal>
            <b-toast id="toast-id"
                toast-class="toast-custom-mobile"
                solid
                no-close-button
                auto-hide-delay="3000"
                toaster="b-toaster-top-full"
            >
                <div class="toast__content toastContentMobile">
                    <div class="toast__content__icon">
                        <img loading="lazy" src="@/assets/images/toast/error.png" />
                    </div>
                    <div class="toast__content__title errorToast">
                        {{ getTranslatedText(messagePaymentFail) }}
                    </div>
                </div>
            </b-toast>
            <Toast :title="toastData.message" :type="toastData.type" />
        </div>
    </div>
</template>
<script>
import "@/assets/scss/mobile/wallet/_wallet.scss";
import {
    creditCardMask,
    formatNumberMoney,
    idRandom,
    isArrayEmpty,
    isStrEmpty,
    removeDouble,
    renderLocaleNumber,
    renderLocalePrice,
    validateEmail
} from "@/components/common/utils";
import { getUserInfo } from "@/services/axios";
import EventBus from '@/components/common/EventBus';

import gameService from "@/services/game.service";
import toolService from "@/services/tool.service";
import authService from "@/services/auth.service";
import PaymentService from "@/services/payment.servive";
import VoucherService from "@/services/voucher.service";
import UserService from "@/services/user.service";

import ConfirmModal from "@/components/common/confirm";
import NoEmpty from "@/components/common/no-empty";
import ButtonCustom from "@/components/common/button";
import FormCommon from "@/components/common/form";
import BottomSheet from "@/components/common/bottom-sheet";
import MethodPayment from "@/components/common/methodPayment";

import CardDetail from "@/views/mobile/card/index.vue";
import cardService from "@/services/card.service";

import languageMixin from "@/mixins/language/language-mixin";
import paymentMixin from "@/mixins/payment/payment-mixin";

import Toast from "@/components/common/toast/Toast.vue";
import moment from "moment";
import { redirectDomain } from "@/components/common/utils";
import DrawsType from "@/components/common/draws-type";
import convertGSTtime from "@/mixins/convert-gst-time/convert-gst-time-mixin";
import drawDateMixin from "@/mixins/draw-date/draw-date-mixin";

const ENABLE_MANAGE_CREDIT_CARDS =
    process.env.VUE_APP_ENABLE_MANAGE_CREDIT_CARDS;
const CURRENCY_CODE = process.env.VUE_APP_CURRENCY_CODE;
const EXCHANGE_RATE = process.env.VUE_APP_EXCHANGE_RATE;
const PAYMENT_TYPE = process.env.VUE_APP_PAYMENT;
const VUE_APP_APP_DOMAIN = process.env.VUE_APP_APP_DOMAIN;
const TIME_PER_GEN = process.env.VUE_APP_TIME_PER_GEN_CERTIFICATE;
const TIME_TOTAL_GEN = process.env.VUE_APP_TIME_TOTAL_GEN_CERTIFICATE;
const API_URL = process.env.VUE_APP_API_URL;
const VUE_APP_STRIPE_APPLE_PAY = process.env.VUE_APP_STRIPE_APPLE_PAY;
const VUE_APP_STRIPE_GOOGLE_PAY = process.env.VUE_APP_STRIPE_GOOGLE_PAY;
const IS_SHOW_CONSENT = process.env.VUE_APP_SHOW_CONSENT;

let scriptTag, scriptTag1

export default {
    mixins: [languageMixin, paymentMixin, convertGSTtime, drawDateMixin],
    components: {
        ConfirmModal,
        NoEmpty,
        CardDetail,
        ButtonCustom,
        FormCommon,
        BottomSheet,
        Toast,
        MethodPayment,
        DrawsType
    },
    data() {
        return {
            screenWidth: window.innerWidth,
            isScreenMobile: false,
            isShowConsent: IS_SHOW_CONSENT,
            toastData: {
                message: "",
                type: "",
            },
            cssProps: {
                backgroundImage: `url(${require("@/assets/images/checkout/background-ball.png")})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
            },
            selectedPayment: "",
            cardId: -1,
            cardSelect: {
                id: -1,
            },
            listCheckouts: [],
            isScroll: false,
            listCards: [],
            isLoadingChangeCard: false,
            isLoadingButtonConfirm: false,
            isLoadingBtn: false,
            isLoadingBtnClear: false,
            isShowListCard: false,
            step: 1,
            minimumBall: -1,
            maxiumBlue: -1,
            maxiumGreen: -1,
            idIndex: "",
            card_security_code: "",
            modalShowCvv: false,
            totalAmountPayment: 0,
            paramCheckout: [],
            stateCvv: null,
            isRecurring: false,
            timer: null,
            isLoadingPage: false,
            currencyCode: CURRENCY_CODE,
            exchangeRate: EXCHANGE_RATE,
            paymentType: PAYMENT_TYPE,
            domain: VUE_APP_APP_DOMAIN,
            formItem: [
                {
                    type: "slot",
                    nameSlot: "radioButtonCard",
                },
            ],
            voucherCode:'',
            voucherCodeTemp: '',
            isLoadingBtnValidate:false,
            subtotal:0,
            walletReemed: 0,
            totalAmount: 0,
            balance: 0,
            isWeeklyDefault: false,
            messageVoucher: {
                isSuccess: false,
                message: ''
            },
            optionsPayment: [
                { item: 'card', name: 'Credit / Debit Card' },
                // { item: 'apple-pay', name: 'Apple Pay' },
                // { item: 'google-pay', name: 'Google Pay' }
            ],
            paymentSelected: '',
            messagePaymentSelected: '',
            showFloating: false,
            expressCheckoutElement: null,
            messagePaymentFail: '',
            status: null,
            code: [],
            timeTotalGen: TIME_TOTAL_GEN,
            timePerGen: TIME_PER_GEN,
            countDown: TIME_TOTAL_GEN,
            stripeElements: null,
            totalPayment: 0,
            domainUrl: API_URL,
            valueEmail: null,
            messageEmail: null,
            emailExist: null,
            messageAmountUPI: null
        };
    },

    mounted() {
        window.addEventListener("scroll", this.onScroll);
        let elm = document.getElementById("checkout-payment-card")
        let elm1 = document.getElementById("express-checkout-element")
        let elm2 = document.getElementById("apple-pay-payment")
        if(elm) {
            if(!localStorage.getItem('cookieConsent') && this.isShowConsent=== 'yes') {
                elm.style.bottom = "210px"
            } else {
                elm.style.bottom = "10px"
            }
        }
        if(elm1) {
            if(!localStorage.getItem('cookieConsent') && this.isShowConsent=== 'yes') {
                elm1.style.bottom = "210px"
            } else {
                elm1.style.bottom = "10px"
            }
        }
         if(elm2) {
            if(!localStorage.getItem('cookieConsent') && this.isShowConsent=== 'yes') {
                elm2.style.bottom = "210px"
            } else {
                elm2.style.bottom = "10px"
            }
        }
    },

    updated() {
        if(!this.isLoadingPage) {
            let elm1 = document.getElementById("action-checkout")
            let elm2 = document.getElementById("main-checkout")
            let el = document.getElementById('apple-pay-payment')
            if(el && window.getComputedStyle(el).display === 'none') {
                const element = document.getElementById('payment-method');
                element.style.border = '1px solid red';
                this.messagePaymentSelected = 'This Payment Method Is Not Available on your browser';
            } else if(this.totalAmount < 2 && el && window.getComputedStyle(el).display !== 'none') {
                this.showToast('Not enough amount in your wallet. You must charge at least 2 AED by your card', "error");
            }
            // if(this.paymentSelected === 'upi' && this.totalAmount < 5) {
            //     this.messageAmountUPI = 'Not enough amount in your wallet. You must charge at least 5 AED by your card'
            // } else {
            //     this.messageAmountUPI = null
            // }
            if(window.screen.width <= 991 && window.screen.width > 500) {
                if(!localStorage.getItem('cookieConsent') && this.isShowConsent=== 'yes') {
                    if(elm1) {
                        elm1.style.bottom = "150px"
                    }
                    if(this.listCheckouts.length === 0) {
                        elm2.style.marginBottom = "250px"
                    } else {
                        elm2.style.marginBottom = "250px"
                    }
                } else {
                    if(elm1) {
                        elm1.style.bottom = "0"
                    } 
                    if(this.listCheckouts.length === 0) {
                        elm2.style.marginBottom = "250px"
                    } else {
                        elm2.style.marginBottom = "50px"      
                    }                  
                }
            } else if(window.screen.width <= 500) {
                if(!localStorage.getItem('cookieConsent') && this.isShowConsent=== 'yes') {
                    if(elm1) {
                        elm1.style.bottom = "210px"
                    } 
                    if(this.listCheckouts.length === 0) {
                        elm2.style.marginBottom = "250px"
                    } else {
                        elm2.style.marginBottom = "250px"
                    }
                } else {
                    if(elm1) {
                        elm1.style.bottom = "0"
                    }
                    if(this.listCheckouts.length === 0) {
                        elm2.style.marginBottom = "250px"
                    } else {
                        elm2.style.marginBottom = "50px"       
                    }                 
                }
            }
        }
    },

    watch: {
        screenWidth(value) {
            if (value <= 991) {
                this.isScreenMobile = true
            }
            else {
                this.isScreenMobile = false
            }
        },
    },

    created() {
        this.addScriptCanonical()
        const parameters = this.$route.query;
        if(parameters?.weekly === 'true') {
            this.isWeeklyDefault = true
            this.$router.replace({'query': null});
        }
        if (this.screenWidth <= 991) {
            this.isScreenMobile = true
        }
        else {
            this.isScreenMobile = false
        }
        window.addEventListener('resize', this.handleResize)
        if(this.paymentType.includes('stripe')) {
            this.optionsPayment.push({
                item: 'apple-pay', name: 'Apple Pay'
            })
        }
        if(this.paymentType === 'ccavenue') {
            this.optionsPayment.push({
                item: 'google-pay', name: 'Google Pay'
            })
        }
        this.fetchDataListCart();
        this.fetchDataTool();
        if (ENABLE_MANAGE_CREDIT_CARDS !== "no") {
            this.getListCards();
        }
        EventBus.$on('onFetchDataListCart', () => {
            this.fetchDataListCart();
        })
        if(this.paymentType.includes('MBME') || this.paymentType.includes('upi')) {
            let userInfo = JSON.parse(localStorage.getItem("user_info"));
            this.emailExist = userInfo.email 
            this.getUserData()
        }
    },
    beforeDestroy() {
        window.removeEventListener("scroll", this.onScroll);
        EventBus.$off('onFetchDataListCart')
        function removeScript(script) {
            if (script && script.parentNode) {
                script.parentNode.removeChild(script);
            }
        }
        removeScript(scriptTag1);
        if(this.expressCheckoutElement) {
            this.expressCheckoutElement.destroy();
        }
    },
    methods: {
        addScriptCanonical() {
            scriptTag1 = document.createElement("link");
            scriptTag1.rel = "canonical";
            scriptTag1.href =`https://omillionaire.com/checkout`
            document.head.appendChild(scriptTag1);
        },
        handleResize() {
            this.screenWidth = window.innerWidth
        },
        showToast(message, type) {
            this.toastData.message = message;
            this.toastData.type = type;
            this.$bvToast.show("my-toast");
        },
        changeEmail(value) {
            if(!validateEmail(value) && !isStrEmpty(value)) {
                this.messageEmail = 'Incorrect email format'
            } else if (isStrEmpty(value)) {
                this.messageEmail = 'Please enter email'
            } else {
                this.messageEmail = null
            }
        },
        getListCards() {
            cardService.listCards().then((res) => {
                if (res.status === 200 && !isArrayEmpty(res.data)) {
                    let index = res.data.findIndex((x) => x.is_default);
                    index = index !== -1 ? index : 0;
                    this.cardSelect = res.data[index];
                    this.cardId = res.data[index].id;
                    this.listCards = res.data;
                }
            });
        },

        createAndMountFormElements(type) { 
            if(type === 'google-pay' || (type === 'apple-pay' && this.paymentType.includes('stripe'))) {
                let amount
                if (this.totalPayment * this.exchangeRate >=2 ) {
                    amount = this.totalPayment * this.exchangeRate * 100
                } else {
                    amount = 2 * 100
                    this.totalPayment = 2 / this.exchangeRate
                    this.showToast('Not enough amount in your wallet. You must charge at least 2 AED by your card', "error");
                }
                const options = {
                    mode: 'payment',
                    amount: parseFloat(this.formatLocalePrice(amount)),
                    currency: this.currencyCode.toLowerCase(),
                    paymentMethodCreation: 'manual',
                    appearance: {
                        variables: {
                            fontFamily: 'Poppins',
                            fontWeight: '700',
                            borderRadius: '30px',
                        }
                    }
                };

                this.stripeElements = this.$stripe.elements(options);
                this.expressCheckoutElement = this.stripeElements.create('expressCheckout', {
                    buttonType: {
                        googlePay: 'buy',
                    },
                    buttonTheme: {
                        googlePay: 'white'
                    },
                    paymentMethods: {
                        applePay: VUE_APP_STRIPE_APPLE_PAY,
                        googlePay: VUE_APP_STRIPE_GOOGLE_PAY
                    },
                    layout: {
                        maxColumns: 1,
                        maxRows: 0,
                        overflow: 'never'
                    },
                    paymentMethodOrder: ['googlePay', 'applePay']
                });
                
                this.expressCheckoutElement.mount('#express-checkout-element');
                const expressCheckoutDiv = document.getElementById('express-checkout-element');
                expressCheckoutDiv.style.visibility = 'visible'
                expressCheckoutDiv.style.maxWidth = '100%';
                expressCheckoutDiv.style.margin = '20px auto 10px auto';
                expressCheckoutDiv.style.borderRadius = '34px';
                expressCheckoutDiv.style.height = '50px';
                this.expressCheckoutElement.on('ready', ({availablePaymentMethods}) => {
                    if (availablePaymentMethods && availablePaymentMethods.applePay) {
                        this.isApplePay = true
                    } else if (availablePaymentMethods && !availablePaymentMethods.googlePay) {
                        const element = document.getElementById('payment-method');
                        element.style.border = '1px solid red';
                        this.messagePaymentSelected = 'This Payment Method Is Not Available on your browser';
                    } else if (availablePaymentMethods && availablePaymentMethods.googlePay) {
                        const element = document.getElementById('payment-method');
                        element.style.border = 'none';
                        this.messagePaymentSelected = '';
                    } else {
                        const element = document.getElementById('payment-method');
                        element.style.border = '1px solid red';
                        this.messagePaymentSelected = 'This Payment Method Is Not Available on your browser';
                        this.isApplePay = false
                    }
                });
                this.expressCheckoutElement.on('confirm', async (event) => {
                    const {error: submitError} = await this.stripeElements.submit();
                    if (submitError) {
                        return;
                    }
                    if (event.expressPaymentType === 'google_pay') {
                        this.handlePay('google-pay')
                    } else {
                        this.handlePay('apple-pay')
                    }
                })
            } else {
                const checkoutDiv = document.getElementById("express-checkout-element")
                checkoutDiv.style.visibility = 'hidden'
                checkoutDiv.style.height = '0px';
            }
        },
        createFormDataPayment(data) {
            const form = document.createElement('form');
            form.method = 'post';
            form.action = data.payment_url;
            form.name = 'redirect';

            // Create hidden input for encRequest
            const encRequestInput = document.createElement('input');
            encRequestInput.type = 'hidden';
            encRequestInput.name = 'encRequest';
            encRequestInput.value = data.encRequest;
            form.appendChild(encRequestInput);

            // Create hidden input for accessCode
            const accessCodeInput = document.createElement('input');
            accessCodeInput.type = 'hidden';
            accessCodeInput.name = 'access_code';
            accessCodeInput.value = data.access_code;
            form.appendChild(accessCodeInput);

            // Append form to body and submit
            document.body.appendChild(form);
            form.submit();
        },
        beginApplePaySession() {
            this.handlePay('apple-pay');
            let userInfo = JSON.parse(localStorage.getItem("user_info"));            
            const paymentRequest = {
                countryCode: 'AE',
                currencyCode: this.currencyCode,
                total: {
                    label: userInfo.name,
                    amount: this.totalAmount >= 2 ? parseFloat(this.formatLocalePrice(this.totalAmount)): parseFloat(this.formatLocalePrice(2))
                },
                supportedNetworks: ['visa', 'masterCard', 'amex'],
                merchantCapabilities: ['supports3DS']
            };  
            const session = new ApplePaySession(3, paymentRequest);
            session.onvalidatemerchant = (event) => {
                const validationURL = event.validationURL;
                this.validateMerchanApplePay(validationURL, session)
            };

            session.onpaymentauthorized = (event) => {
                const payment = event.payment;
                this.processPaymentApplePay(payment.token);
            };

            session.begin();
        },
        async validateMerchanApplePay(validationURL, session) {
            try {
                const resp = await PaymentService.validateMerchanApplePay(validationURL)
                if(resp.status === 200) {
                    session.completeMerchantValidation(resp.data);
                } else {
                    session.abort();
                    this.showToast(this.getTranslatedText(resp.message), "error");
                }
            }
            catch (error) {
                console.log(error)
            }
        },
        async processPaymentApplePay(token) {
            try {
                let utm = JSON.parse(localStorage.getItem('utm-paramaters'))
                let {utm_campaign, utm_content, utm_medium, utm_source, utm_term} = utm ? utm : {}
                const param = {
                    token: token,
                    amount: this.totalAmount >= 2 ? parseFloat(this.formatLocalePrice(this.totalPayment)) : parseFloat(this.formatLocalePrice(2 / this.exchangeRate)),
                    is_fund_deposit: false,
                    reference: null,
                    payment_method: 'apple-pay',
                    utm_campaign,
                    utm_content,
                    utm_medium,
                    utm_source,
                    utm_term
                }
                const resp = await PaymentService.processPaymentApplePay(param)
                if(resp.status === 200) {
                    localStorage.setItem("methodPayment", "2");
                    this.createFormDataPayment(resp.data)
                } else {
                    this.showToast(this.getTranslatedText(resp.message), "error");
                }
            }
            catch (error) {
                console.log(error)
            }
        },
        countDownTimer(reference) {
            if (this.countDown <= this.timeTotalGen && this.countDown > 0) {
                this.createCertificate(reference)
                setTimeout(() => {
                    this.countDown -= this.timePerGen
                    this.countDownTimer(reference)
                }, this.timePerGen)
            }
        },

        async purchase(paymentMethod) {
            try {
                let utm = JSON.parse(localStorage.getItem('utm-paramaters'))
                let {utm_campaign, utm_content, utm_medium, utm_source, utm_term} = utm ? utm : {}
                const param = {
                    amount: parseFloat(this.formatLocalePrice(this.totalPayment)),
                    payment_method: paymentMethod,
                    is_fund_deposit: false,
                    reference: null,
                    utm_campaign,
                    utm_content,
                    utm_medium,
                    utm_source,
                    utm_term
                }
                const resp = await PaymentService.purchaseStripe(param)
                if(resp.status === 200) {
                    localStorage.setItem("methodPayment", "2");
                    this.confirmPayment(resp.data.client_secret)
                }
            }
            catch (error) {
                console.log(error)
            }
        },

        async confirmPayment(clientSecret) {
            try {
                this.status = 'loading'
                const {error: submitError} = await this.stripeElements.submit();
                if (submitError) {
                    return;
                }
                const res = await this.$stripe.confirmPayment({
                    elements: this.stripeElements,
                    clientSecret,
                    confirmParams: {
                        return_url: `${this.domainUrl}/stripe-payment/callback/result`,
                    },
                    redirect: "if_required"
                });
                let paymentIntent
                if(res.error) {
                    paymentIntent = res.error.payment_intent.id
                    this.messagePaymentFail = res.error.message
                    if(this.isApplePay) {
                        this.$bvToast.show('toast-id');
                    } else {
                        this.status = 'fail';
                        this.$bvModal.show("modalNotifyPayment")
                    }
                    return false
                }
                if(res.paymentIntent) {
                    paymentIntent = res.paymentIntent.id
                    this.status = 'loading'
                    this.$bvModal.show("modalNotifyPayment")
                    this.callBack(paymentIntent)
                }
            }
            catch (error) {
                console.log(error)
            }
        },
        async callBack(paymentIntent) {
            this.status = 'loading'
            this.$bvModal.show("modalNotifyPayment")
            try {
                const res = await PaymentService.callBackStripe(paymentIntent)
                if (res.data) {
                    this.status = res.data.status
                    this.$bvModal.show("modalNotifyPayment")
                    if (res.data.status === 'success') {
                        this.getTransaction()
                    }
                }
            }
            catch (error) {
                console.log(error)
            }
        },
        async getTransaction() {
            try {
                const resp = await gameService.transact(this.paramCheckout);
                if (resp.status === 200) {
                    this.getReference(resp.data.reference)
                }
            }
            catch (error) {
                console.log(error)
            }
        },
        async getReference(reference) {
            try {
                const resp = await gameService.getReference(reference);
                if (resp.status === 200) {
                    resp.data.transactions.map((item, index) => {
                        this.code.push(item.raffle_id)
                    })
                }
                if(!isArrayEmpty(this.code)) {
                    this.countDownTimer(reference)
                }
            }
            catch (error) {
                console.log(error)
            }
        },
        async createCertificate(reference) {
            try {
                const resp = await PaymentService.createCertificate(this.code);
                if (resp.status === 200 && resp.data.status) {
                    this.countDown = 0
                    await authService.removeListCart();
                    localStorage.removeItem("list_block");
                    localStorage.removeItem("list_block_tmp");
                    localStorage.removeItem("cart_checkout");
                    localStorage.removeItem("total_items");
                    localStorage.removeItem("referencePayment");
                    localStorage.removeItem("totalAmountPayment");
                    this.$store.dispatch("onChangeAnountCart", 0);
                    this.listCheckouts = [];
                    this.$store.dispatch("setBalance");
                    this.$router.push(`/thank-you?reference=${reference}`);
                    window.performance.navigation.TYPE_RELOADED = true
                }
            }
            catch (error) {
                console.log(error)
            }
        },
        async fetchDataTool() {
            try {
                const respTool = await toolService.getValidateTicket();

                this.minimumBall = respTool.minMaxTickets[0];
                this.maxiumBlue = respTool.maxiumBlue;
                this.maxiumGreen = respTool.maxiumGreen;
            } catch (error) {
                console.log(error);
            }
        },

        async callApiListCart() {
            let totalCart = !isStrEmpty(localStorage.getItem("total_items"))
                ? parseInt(localStorage.getItem("total_items"))
                : 0;
            try {
                const resp = await authService.getListCart();
                if (resp.status === 200) {
                    let list_block_tmp =
                        totalCart > 0
                            ? JSON.parse(localStorage.getItem("list_block_tmp"))
                            : [];
                    let list_block_api =
                        parseInt(resp.data?.amount) > 0
                            ? JSON.parse(resp.data.cart_data)
                            : [];

                    if (!isArrayEmpty(list_block_tmp)) {
                        list_block_tmp = list_block_api.concat(list_block_tmp);
                        list_block_tmp = [...new Set(list_block_tmp)];

                        let amount = 0;

                        list_block_tmp = list_block_tmp.filter(
                            (v, i, a) =>
                                a.findIndex((v2) => v2.id === v.id) === i
                        );

                        list_block_tmp.map((x) => {
                            amount += x.date.length;
                        });

                        let param = {
                            cart_data: list_block_tmp,
                            items: list_block_tmp.length,
                            amount,
                        };

                        this.$store.dispatch(
                            "onChangeAnountCart",
                            parseInt(param.amount)
                        );
                        localStorage.setItem(
                            "list_block_tmp",
                            JSON.stringify(list_block_tmp)
                        );
                        localStorage.setItem(
                            "list_block",
                            JSON.stringify(list_block_tmp)
                        );
                        localStorage.setItem("total_items", param.amount);
                        this.fetchDataListCart();
                    } else {
                        let amount = resp?.data?.amount
                            ? parseInt(resp.data.amount)
                            : 0;

                        localStorage.setItem(
                            "list_block_tmp",
                            JSON.stringify(list_block_api)
                        );
                        localStorage.setItem(
                            "list_block",
                            JSON.stringify(list_block_api)
                        );
                        localStorage.setItem("total_items", amount);
                        this.fetchDataListCart();
                    }
                }
            } catch (error) {
                console.log(error);
            }
        },

        async fetchDataListCart() {
            this.isLoadingPage = true;
            let listBlockTickets = localStorage.getItem("list_block_tmp");
            let listGames = localStorage.getItem("list_games");
            if (isStrEmpty(listBlockTickets) && this.$store.state.isLoggedIn) {
                this.callApiListCart();
                return false;
            }

            listGames = !isStrEmpty(listGames)
                ? [...JSON.parse(listGames)]
                : [];
            listBlockTickets = !isStrEmpty(listBlockTickets)
                ? [...JSON.parse(listBlockTickets)]
                : [];

            let paramAddToCard = [];

            listBlockTickets = listBlockTickets.filter(
                (x) =>
                    !isArrayEmpty(x.date) ||
                    x.automatically ||
                    x.numbersChecked.length >= this.minimumBall
            );

            listBlockTickets.map((value) => {
                if (value.automatically) {
                    let index = !isArrayEmpty(value.doubles)
                        ? value.doubles.findIndex(
                              (x) => x.id === listGames[0].event_id
                          )
                        : -1;
                    let itemListCart = {
                        id: idRandom(),
                        idBlock: value.id,
                        gameId: listGames[0].game_id,
                        name: `ITEM ${paramAddToCard.length + 1}`,
                        game_slug: listGames[0].game_slug,
                        event_id: listGames[0].event_id,
                        amount: listGames[0].price,
                        amountClone:listGames[0].price,
                        date: listGames[0].draw_date,
                        draws: "1",
                        double_jackpot_price: parseFloat(
                            listGames[0].double_jackpot_price
                        ),
                        secure_jackpot_price: parseFloat(
                            listGames[0].secure_jackpot_price
                        ),
                        is_recurring: 1,
                        valueDoub: [],
                        amountDeducted: parseFloat(listGames[0].price),
                    };

                    if (index !== -1) {
                        itemListCart.valueDoub = value.doubles[index].value;
                    }
                    itemListCart["bets"] = value.numbersChecked;
                    paramAddToCard.push(itemListCart);
                } else {
                    value.date.map((v) => {
                        let indexGame = listGames.findIndex(
                            (x) => x.event_id.toString() === v
                        );
                        if (indexGame !== -1) {
                            let index = !isArrayEmpty(value.doubles)
                                ? value.doubles?.findIndex(
                                      (x) =>
                                          x.id === listGames[indexGame].event_id
                                  )
                                : -1;

                            let itemListCart = {
                                id: idRandom(),
                                idBlock: value.id,
                                gameId: listGames[indexGame].game_id,
                                name: `ITEM ${paramAddToCard.length + 1}`,
                                game_slug: listGames[indexGame].game_slug,
                                event_id: listGames[indexGame].event_id,
                                amount: listGames[indexGame].price,
                                amountClone:listGames[0].price,
                                date: listGames[indexGame].draw_date,
                                draws: "1",
                                double_jackpot_price: parseFloat(
                                    listGames[indexGame].double_jackpot_price
                                ),
                                secure_jackpot_price: parseFloat(
                                    listGames[indexGame].secure_jackpot_price
                                ),
                                valueDoub: [],
                                amountDeducted: -1,
                            };

                            if (index !== -1) {
                                itemListCart.valueDoub =
                                    value.doubles[index].value;
                            }
                            itemListCart["bets"] = value.numbersChecked;
                            paramAddToCard.push(itemListCart);
                        }
                    });
                }
            });

            if (!isArrayEmpty(paramAddToCard)) {
                paramAddToCard.map((v) => {
                    if (this.isScreenMobile) {
                        v["draw_date"] = moment(v.date).format("DD MMMM YYYY");
                    } else {
                        v["draw_date"] = moment(v.date).format("DD/MM/YYYY");
                    }
                    v["date"] = v.date
                    v["activeWeekly"] = this.isWeeklyDefault ? true : false
                    // this.$router.replace({'query': null});
                    if (v.valueDoub.includes(1)) {
                        v.amountClone += parseFloat(v.double_jackpot_price);
                        if (v.amountDeducted !== -1) {
                            v.amountDeducted += parseFloat(
                                v.double_jackpot_price
                            );
                        }
                    }

                    if (v.valueDoub.includes(2)) {
                        v.amountClone += parseFloat(v.secure_jackpot_price);
                        if (v.amountDeducted !== -1) {
                            v.amountDeducted += parseFloat(
                                v.secure_jackpot_price
                            );
                        }
                    }
                    this.subtotal += parseFloat(v.amountClone);

                    v["doubles"] = [
                        {
                            id: 1,
                            isCheck: v.valueDoub.includes(1),
                            title: this.getTranslatedText(
                                "double the grand prize 1"
                            ),
                            value: v.double_jackpot_price,
                        },
                        {
                            id: 2,
                            isCheck: v.valueDoub.includes(2),
                            title: this.getTranslatedText(
                                "secure the grand prize"
                            ),
                            value: v.secure_jackpot_price,
                        },
                    ];
                });
                if (ENABLE_MANAGE_CREDIT_CARDS !== "no") {
                    this.checkShowCard(paramAddToCard);
                }
                this.listCheckouts = paramAddToCard;
                this.isLoadingPage = false;
            } else {
                this.isLoadingPage = false;
            }
            let data = await JSON.parse(localStorage.getItem("balance_data"))
            let redeemed = await JSON.parse(localStorage.getItem("gift_redeemed"))
            this.walletReemed = redeemed ? redeemed : 0
            const valueBalance = data ? data.balance.split(',') : '0'.split(',');
            const newValueBalance = valueBalance.join('')
            this.balance = redeemed ? Number(newValueBalance) - redeemed : Number(newValueBalance)
            const weekly = this.listCheckouts.filter((value) => value.activeWeekly)
            const next = this.listCheckouts.filter((value) => value.activeNextDraw)
            if(weekly.length > 0 || next.length > 0) {
                this.balance = 0
                this.walletReemed = 0
            } else {
                this.balance = redeemed ? Number(newValueBalance) - redeemed : Number(newValueBalance)
                this.walletReemed = redeemed ? redeemed : 0
            }
            let total = this.subtotal - (this.walletReemed + this.balance)
            this.totalAmount = total > 0 ? total : 0
            this.totalPayment = this.totalAmount / this.exchangeRate
            if(this.balance < 0) {
                this.walletReemed = 0
                this.balance = Number(newValueBalance)
            }
            if(this.screenWidth <= 991 && this.totalAmount !== 0 && this.$store.state.isLoggedIn) {
                this.getLastPaymentMethod()
            }
        },

        async checkShowCard(listCart) {
            try {
                authService.getBalance().then((res) => {
                    if (res.status === 200) {
                        let paramAddToCardFilter = listCart.filter(
                            (x) => x?.is_recurring === 1
                        );

                        if (
                            parseFloat(res.data.total) <= this.totalAmount ||
                            !isArrayEmpty(paramAddToCardFilter)
                        ) {
                            this.isShowListCard = true;
                        } else {
                            this.isShowListCard = false;
                        }
                    }
                });
            } catch (error) {
                console.log(error);
            }
        },

        // call apitransact
        async callApiTransact(paramCheckout, isStopCallBack) {
            const resp = await gameService.transact(paramCheckout);
            if (resp.status === 200) {
                await authService.removeListCart();

                localStorage.removeItem("list_block");
                localStorage.removeItem("list_block_tmp");
                localStorage.removeItem("total_items");
                localStorage.removeItem("gift_redeemed");
                this.$store.dispatch("onChangeAnountCart", 0);
                this.listCheckouts = [];
                this.showToast(resp.message, "success");
                this.$store.dispatch("setBalance");
                localStorage.setItem("payment_vendor", "wallet_pay");
                this.$router.push(`/thank-you?reference=${resp.data.reference}`);
                window.performance.navigation.TYPE_RELOADED = true
                this.isLoadingBtn = false;
            } else {
                if(isStopCallBack) {
                    setTimeout(() => {
                        this.callApiTransact(paramCheckout, true)
                    }, 5000);
                } else {
                    this.isLoadingBtn = false;
                    this.showToast(resp.message, "error");
                }
            }

        },

        async handlePay(type) {
            this.isLoadingBtn = true;
            try {
                if (!this.$store.state.isLoggedIn) {
                    this.showToast("please log in", "error");
                    this.$router.push("/login");
                    return false;
                }

                let userInfo = getUserInfo();
                userInfo = JSON.parse(userInfo);
                const respCheckLimit = await gameService.checkLimitGame({
                    user_id: userInfo?.id,
                    quantity: this.$store.state.amoutCart,
                });

                if (!respCheckLimit.data.status) {
                    this.showToast(respCheckLimit.data.message, "error");
                    this.isLoadingBtn = false;
                    return false;
                }

                let totalAmount = this.subtotal / this.exchangeRate;
                let totalAmountRecurring = 0;
                let games = [];
                let items = [];
                let drawNumbers = []
                let double = []
                let secure = []
                this.listCheckouts.map((x) => {
                    let index = games.findIndex(
                        (v) => v.event_id === x.event_id
                    );
                    let type
                    let isRec
                    if (x.activeNextDraw) {
                        type = 1
                        isRec = x.activeNextDraw
                    } else if (x.activeWeekly) {
                        type = 2
                        isRec = x.activeWeekly
                    } else {
                        type = ''
                        isRec = false
                    }
                    let itemBets = {
                        type: "jackpot",
                        amount: parseFloat(x.amount / this.exchangeRate),
                        bet: x.bets,
                        is_recurring: isRec,
                        recurring_type: type,
                        draws: "1",
                    };

                    if (x.doubles[0].isCheck) {
                        itemBets["double_jackpot_price"] = parseFloat(
                            x.double_jackpot_price / this.exchangeRate
                        );
                    }
                    if (x.doubles[1].isCheck) {
                        itemBets["secure_jackpot_price"] = parseFloat(
                            x.secure_jackpot_price / this.exchangeRate
                        );
                    }

                    let itemListCart = {
                        bets: [],
                        game_id: x.gameId,
                        game_slug: x.game_slug,
                        event_id: x.event_id,
                    };

                    if (x.is_recurring === 1) {
                        itemBets["is_recurring"] = 1;

                        x.doubles.map((item) => {
                            if (item.isCheck) {
                                totalAmountRecurring += item.value;
                            }
                        });
                        totalAmountRecurring += parseFloat(x.amount);
                    }

                    itemListCart.bets.push(itemBets);
                    if (index === -1) {
                        games.push(itemListCart);
                    } else {
                        games[index].bets.push(itemBets);
                    }
                    let price
                    if (x.doubles[0].isCheck && !x.doubles[1].isCheck) {
                      price = x.amount + x.doubles[0].value
                    } else if (x.doubles[1].isCheck && !x.doubles[0].isCheck) {
                      price = x.amount + x.doubles[1].value
                    } else if (x.doubles[0].isCheck && x.doubles[1].isCheck) {
                      price = x.amount + x.doubles[0].value + x.doubles[1].value
                    } else {
                        price = x.amount
                    }
                    let production = {
                        product_price: price,
                        draw_date: moment(x.date).format('YYYY-MM-DD'),
                        double_the_grand_prize: x.doubles[0].isCheck ? 'yes' : 'no',
                        secure_the_grand_prize: x.doubles[1].isCheck ? 'yes' : 'no',
                        draw_number: x.bets.join(' '),
                    }
                    drawNumbers.push(...x.bets)
                    double.push(production.double_the_grand_prize)
                    secure.push(production.secure_the_grand_prize)
                    items.push(production)
                });
                let utm = JSON.parse(localStorage.getItem('utm-paramaters'))
                let {utm_campaign, utm_content, utm_medium, utm_source, utm_term} = utm ? utm : {}

                let paramCheckout = {
                    totalAmount,
                    games,
                    utm_campaign,
                    utm_content,
                    utm_medium,
                    utm_source,
                    utm_term
                };

                const balance = await authService.getBalance();

                let totalNoRecurring =
                    parseFloat(paramCheckout.totalAmount) -
                    totalAmountRecurring;
                let totalMore =
                    parseFloat(totalNoRecurring) -
                    parseFloat(balance.data.total.replaceAll(",", "") / this.exchangeRate);
                const hasWeeklyorNextDraw = this.listCheckouts.some(item => item.activeWeekly === true || item.activeNextDraw === true)
                switch (true) {
                    case totalAmountRecurring === 0 && totalMore <= 0:
                        if(hasWeeklyorNextDraw){
                            this.paramCheckout = paramCheckout;
                            this.onSubmitPayment(this.subtotal / EXCHANGE_RATE);
                            break;
                        }else{
                            paramCheckout.totalAmount = this.subtotal / this.exchangeRate
                            this.callApiTransact(paramCheckout)
                            break;
                        }
                    case totalAmountRecurring === 0 &&
                        totalMore > 0 &&
                        this.cardSelect.id !== -1:
                        this.modalShowCvv = true;
                        this.paramCheckout = paramCheckout;
                        this.totalAmountPayment = parseFloat(totalMore);
                        this.isLoadingBtn = false;
                        break;
                    case totalAmountRecurring === 0 &&
                        totalMore > 0 &&
                        this.cardSelect.id === -1:
                        let payType = this.paymentType === 'ccavenue' ? 'CCAvenue' : this.paymentType
                        localStorage.setItem("payment_vendor", payType);
                        this.totalAmountPayment = totalMore;
                        // this.step = 2;
                        this.paramCheckout = paramCheckout;
                        localStorage.setItem(
                            "cart_checkout",
                            JSON.stringify(paramCheckout)
                        );
                        this.isRecurring = false;
                        let totalAmountPayment = {
                            type: 'checkout',
                            totalAmountPayment: this.totalAmountPayment,
                            payment_vendor: payType,
                            quantity: this.$store.state.amoutCart,
                            draw_date: moment(this.listCheckouts[0].date).format("YYYY-MM-DD"),
                            voucher_code: this.voucherCodeTemp || 'N/A',
                            draw_number: drawNumbers.join(', '),
                            double_the_grand_prize: double.join(', '),
                            secure_the_grand_prize: secure.join(', '),
                            items: items,
                            typeSelected: this.paymentSelected,
                            email: this.valueEmail
                        }
                        if (this.paymentType === 'card' || this.paymentType === 'credit' || this.paymentType === 'ccavenue') {
                            if (this.paymentType === 'ccavenue') {
                                localStorage.setItem("totalAmountPayment", JSON.stringify(totalAmountPayment))
                            }
                            this.onSubmitPayment(totalMore);
                        } else if (this.paymentType.includes('stripe') && type !== 'apple-pay' && type !== 'upi') {
                            localStorage.setItem("totalAmountPayment", JSON.stringify(totalAmountPayment))
                            this.$router.push('/payment');
                        } else if(this.paymentType.includes('MBME') && type !== 'upi') {
                            this.paymentMBME(totalAmountPayment)
                        } else if(this.paymentType.includes('stripe') && type === 'apple-pay' && type !== 'upi') {
                            this.purchase('apple-pay')
                        } else if(this.paymentType.includes('upi') && type === 'upi') {
                            localStorage.setItem("methodPayment", "2");
                            localStorage.setItem("totalAmountPayment", JSON.stringify(totalAmountPayment))
                            this.paymentUPI()
                        }
                        else {
                            this.$bvModal.show("modalMethodPayment");
                        }
                        if (!this.paymentType.includes('MBME')) {
                            this.isLoadingBtn = false;
                        }

                        // const res = await PaymentService.purchaseThawani(param);

                        // if (res.status === 200) {
                        //     if (!isStrEmpty(res.data["checkout_url"])) {
                        //         localStorage.setItem("methodPayment", 2);
                        //         window.location.href = res.data["checkout_url"];
                        //     } else {
                        //         alert(res.data.message);
                        //         // this.showToast(res.data.message, "error");
                        //     }
                        // } else {
                        //     alert(res.message);
                        //     // this.showToast(res.message, "error");
                        // }

                        break;
                    case totalAmountRecurring > 0 && this.cardSelect.id === -1:
                        this.totalAmountPayment =
                            totalAmountRecurring +
                            (parseFloat(totalMore) > 0
                                ? parseFloat(totalMore)
                                : 0);
                        this.step = 2;
                        this.paramCheckout = paramCheckout;
                        this.isRecurring = true;
                        break;
                    case totalAmountRecurring > 0 && this.cardSelect.id !== -1:
                        this.totalAmountPayment =
                            totalAmountRecurring +
                            (parseFloat(totalMore) > 0
                                ? parseFloat(totalMore)
                                : 0);
                        this.modalShowCvv = true;
                        this.paramCheckout = paramCheckout;
                        this.isLoadingBtn = false;

                        break;
                    default:
                        break;
                }
                let user = JSON.parse(localStorage.getItem("user_info"))
                let id = user ? parseInt(user.id) : "'N/A'"
                scriptTag = document.createElement('script');
                scriptTag.textContent = `
                    window.dataLayer = window.dataLayer || [];
                    dataLayer.push({
                        'event': 'Checkout',
                        'user_id': ${id},
                        'prqt': ${this.listCheckouts.length},
                        'voucher_code': 'N/A',
                        'currency': '${this.currencyCode}',
                        'amount': ${this.totalAmount},
                        'grand_prize': '${this.price(1)}',
                        'eligible_prize': '${this.price(2)}',
                        'prod_url': '${this.domain + '/img/mobile-screen.bb7c96c2.png'}',
                        'date_draw': '${items[0].draw_date}',
                        'draw_number': '${drawNumbers.join(', ')}',
                        'double_the_grand_prize': '${double.join(', ')}',
                        'secure_the_grand_prize': '${secure.join(', ')}',
                        'items': ${JSON.stringify(items)}
                    });
                `;
                document.head.appendChild(scriptTag);
                let data = await JSON.parse(localStorage.getItem("balance_data"))
                let redeemed = await JSON.parse(localStorage.getItem("gift_redeemed"))
                this.walletReemed = redeemed ? redeemed : 0
                const valueBalance = data ? data.balance.split(',') : '0'.split(',');
                const newValueBalance = valueBalance.join('')
                this.balance = redeemed ? Number(newValueBalance) - redeemed : Number(newValueBalance)
                const weekly = this.listCheckouts.filter((value) => value.activeWeekly)
                const next = this.listCheckouts.filter((value) => value.activeNextDraw)
                if(weekly.length > 0 || next.length > 0) {
                    this.balance = 0
                    this.walletReemed = 0
                } else {
                    this.balance = redeemed ? Number(newValueBalance) - redeemed : Number(newValueBalance)
                    this.walletReemed = redeemed ? redeemed : 0
                }
                let total = this.subtotal - (this.walletReemed + this.balance)
                this.totalAmount = total > 0 ? total : 0   
                this.totalPayment = this.totalAmount / this.exchangeRate  
                if(this.balance < 0) {
                    this.walletReemed = 0
                    this.balance = Number(newValueBalance)
                }
                if(type === 'google-pay') {
                    this.purchase('google-pay')
                }  
                if(type === 'apple-pay') {
                    localStorage.setItem("cart_checkout", JSON.stringify(this.paramCheckout));
                }       
                } catch (error) {
                console.log(error);
            }
        },

        onAddSvv() {
            if (this.card_security_code.length < 3) {
                this.stateCvv = false;
                return false;
            } else {
                this.stateCvv = null;
                this.modalShowCvv = false;
                this.handlePayCard();
            }
        },
        getClassPaymentCheckout() {
            if(this.isLoadingBtn && !this.showFloating) {
                return 'disabled'
            } else if(this.showFloating && !this.isLoadingBtn) {
                return 'floating'
            } else if(this.showFloating && this.isLoadingBtn) {
                return 'floating disabled'
            } else {
                return ''
            }
        },
        handleRedirectBeforePayment(type) {
            if(window.location.toString().includes("app")) {
                redirectDomain('/checkout');
            } else if(type === 'card' && isStrEmpty(this.paymentSelected) && this.totalAmount !== 0 && this.$store.state.isLoggedIn) {
                const element = document.getElementById('payment-method');
                element.style.border = '1px solid red';
                this.messagePaymentSelected = 'Please choose payment method';
            } else if(this.paymentType.includes('MBME') && isStrEmpty(this.valueEmail) && !this.emailExist && this.totalAmount !== 0 && this.$store.state.isLoggedIn) {
                this.messageEmail = 'Please enter email'
            } else if(this.paymentType.includes('MBME') && !this.emailExist && this.messageEmail && this.totalAmount !== 0 && this.$store.state.isLoggedIn) {
                this.messageEmail = 'Incorrect email format'
            } else if(this.paymentSelected === 'upi') {
                if(this.paymentType.includes('upi') && isStrEmpty(this.valueEmail) && !this.emailExist && this.totalAmount !== 0 && this.$store.state.isLoggedIn) {
                    this.messageEmail = 'Please enter email'
                } else if(this.paymentType.includes('upi') && !this.emailExist && this.messageEmail && this.totalAmount !== 0 && this.$store.state.isLoggedIn) {
                    this.messageEmail = 'Incorrect email format'
                } else {
                    this.handlePay('upi');
                }
            } else {
                this.handlePay();
            }
        },

          async onSubmitPayment(totalAmountPayment) {
            try {
                this.isLoadingBtn = true
                let utm = JSON.parse(localStorage.getItem('utm-paramaters'))
                let {utm_campaign, utm_content, utm_medium, utm_source, utm_term} = utm ? utm : {}
                if(this.paymentType === 'credit') {
                    const res = await PaymentService.puschaseNboPayment(totalAmountPayment)
                    Checkout.showPaymentPage()
                    if(res.status === 200) {
                        let data = res.data
                        localStorage.setItem("methodPayment", "2");
                        localStorage.setItem('referencePayment', res.data.reference)
                        Checkout.configure({
                            merchant: data.session.merchant,
                            session: {
                                id: data.session.session.id,
                                version: data.session.session.version
                            },
                            interaction: {
                                merchant: {
                                    name: this.namePayment,
                                    address: {
                                        line1: this.addressPayment,
                                    }
                                }
                            }
                        })
                        this.showPaymentPage()
                    } else {
                        this.isLoadingBtn = false
                        this.$emit('showToast', res.message, "error")
                    }
                } else if (this.paymentType === 'ccavenue') {
                    const hasWeeklyorNextDraw = this.listCheckouts.some(item => item.activeWeekly === true || item.activeNextDraw === true)
                    const payload = {
                        amount: hasWeeklyorNextDraw ? this.subtotal / this.exchangeRate : totalAmountPayment,
                        is_fund_deposit: false,
                        payment_method: 'card',
                        utm_campaign,
                        utm_content,
                        utm_medium,
                        utm_source,
                        utm_term
                    }
                    if(hasWeeklyorNextDraw){
                        payload.is_recurring = 'yes'
                    }else{
                        delete payload.is_recurring;
                    }
                    if(payload.amount * this.exchangeRate < 2) {
                        payload.amount = 2/this.exchangeRate
                    }
                    const res = await PaymentService.puschaseCcavenuePayment(payload)
                    if (res.status === 200) {
                        localStorage.setItem("methodPayment", "2");
                        localStorage.setItem('referencePayment', res.data.reference)
                        let data = this.paramCheckout
                        data.transaction_deposit_id = res.data.transaction_id
                        localStorage.setItem(
                            "cart_checkout",
                            JSON.stringify(data)
                        );
                        // window.location.href = res.data.payment_url
                        if(this.paymentSelected !== 'apple-pay' && this.paymentSelected !== 'google-pay') {
                            this.$router.push({name:'Payment', params:{data: res.data}})
                        }
                    } else {
                        this.isLoadingBtn = false
                        this.$emit('showToast', res.message, "error")
                    }
                } else {
                    let param = {
                        amount: totalAmountPayment
                    };

                    const res = await PaymentService.purchaseThawani(param);
                    if (res.status === 200) {
                        if (!isStrEmpty(res.data["checkout_url"])) {
                            localStorage.setItem("methodPayment", "2");
                            window.location.href = res.data["checkout_url"];
                        } else {
                            this.$emit('showToast', res.message, "error")
                            this.isLoadingBtn = false
                        }
                    } else {
                        this.$emit('showToast', res.message, "error")
                        this.isLoadingBtn = false
                    }
                }
            } catch (error) {
                 this.isLoadingBtn = false
                console.log(error)
            }
        },

        async handlePayCard() {
            this.isLoadingBtn = true;
            let parram = {
                card_id: this.cardSelect.id,
                amount: this.totalAmountPayment,
                card_security_code: this.card_security_code,
            };
            const res = await PaymentService.puscharseByCard(parram);
            if (res.status === 200) {
                let paramCheckout = JSON.stringify(this.paramCheckout);
                if (!isStrEmpty(res.data["3ds_url"])) {
                    localStorage.setItem("methodPayment", 2);
                    localStorage.setItem("cart_checkout", paramCheckout);
                    window.location.href = res.data["3ds_url"];
                } else if (res.data.response_message === "success") {
                    localStorage.setItem("methodPayment", 2);
                    localStorage.setItem("cart_checkout", paramCheckout);
                    await this.$router.push("/payment/successful");
                } else {
                    this.showToast(res.data.response_message, "error");
                }
                this.isLoadingBtn = false;
            } else {
                this.showToast(res.message, "error");
                this.isLoadingBtn = false;
            }
        },

        //clear cart
        async onClearCart() {
            try {
                this.isLoadingButtonConfirm = true;
                const resp = this.$store.state.isLoggedIn
                    ? await authService.removeListCart()
                    : 200;
                if (resp === 200 || resp.status === 200) {
                    const listBlokLocal = JSON.parse(
                        localStorage.getItem("list_block")
                    );
                    const listBlokTmpLocal = JSON.parse(
                        localStorage.getItem("list_block_tmp")
                    );

                    let listBlockRemoveDou = removeDouble(
                        listBlokLocal,
                        listBlokTmpLocal,
                        "id"
                    );

                    listBlockRemoveDou = JSON.stringify(listBlockRemoveDou);

                    this.listCheckouts = [];
                    localStorage.removeItem("list_block_tmp");
                    localStorage.removeItem("total_items");
                    localStorage.setItem("list_block", listBlockRemoveDou);
                    this.$store.dispatch("onChangeAnountCart", 0);

                    this.$bvModal.hide("modal-confirm-remove-block");
                    this.showToast(
                        this.getTranslatedText("Clear Cart Successfully"),
                        "success"
                    );
                    this.isLoadingButtonConfirm = false;
                } else {
                    this.isLoadingButtonConfirm = false;
                }
            } catch (error) {
                console.log(error);
            }
        },

        async handleDeleteInfoItem(id) {
            this.isLoadingButtonConfirm = true;
            let index = this.listCheckouts.findIndex((x) => x.id === id);
            if (index !== -1) {
                let totalTtems = localStorage.getItem("total_items");
                let listBlock = localStorage.getItem("list_block");
                listBlock = !isStrEmpty(listBlock) ? JSON.parse(listBlock) : [];
                let item = this.listCheckouts[index];

                let indexListBlock = listBlock.findIndex(
                    (x) => x.id === item.idBlock
                );

                if (
                    indexListBlock !== -1 &&
                    listBlock[indexListBlock].automatically
                ) {
                    listBlock.splice(indexListBlock, 1);
                } else {
                    if (indexListBlock !== -1 && !isArrayEmpty(listBlock)) {
                        let indexDate = listBlock[
                            indexListBlock
                        ].date.findIndex((x) => x === item.event_id);
                        if (indexDate !== -1) {
                            listBlock[indexListBlock].date.splice(indexDate, 1);
                        }
                    }
                }

                if (isArrayEmpty(listBlock[indexListBlock]?.date)) {
                    listBlock.splice(indexListBlock, 1);
                }
                totalTtems -= 1;

                try {
                    let param = {
                        cart_data: listBlock,
                        items: 1,
                        amount: totalTtems,
                    };

                    const resp = this.$store.state.isLoggedIn
                        ? await authService.saveListCart(param)
                        : 200;

                    if (resp === 200 || resp.status === 200) {
                        this.idIndex = "";

                        let amount = parseFloat(this.subtotal);
                        amount -= parseFloat(this.listCheckouts[index].amountClone);

                        this.listCheckouts.splice(index, 1);

                        this.listCheckouts = this.listCheckouts.map((x, i) => {
                            let item = {
                                ...x,
                                name: `ITEM ${i + 1}`,
                            };
                            return {
                                ...item,
                            };
                        });

                        // if(this.$store.state.isLoggedIn) this.checkShowCard(this.listCheckouts);

                        this.$store.dispatch( "onChangeAnountCart", parseInt(totalTtems));

                        localStorage.setItem("total_items", totalTtems);
                        localStorage.setItem("list_block_tmp",JSON.stringify(listBlock));
                        !isArrayEmpty(listBlock) ? localStorage.setItem( "list_block", JSON.stringify(listBlock)) : localStorage.removeItem("list_block");

                        this.$bvModal.hide("modal-confirm-remove-block");
                        this.showToast(this.getTranslatedText("Delete Successfully"), "success" );

                        this.subtotal = amount;
                        this.isLoadingButtonConfirm = false;
                        let data = await JSON.parse(localStorage.getItem("balance_data"))
                        let redeemed = await JSON.parse(localStorage.getItem("gift_redeemed"))
                        this.walletReemed = redeemed ? redeemed : 0
                        const valueBalance = data ? data.balance.split(',') : '0'.split(',');
                        const newValueBalance = valueBalance.join('')
                        this.balance = redeemed ? Number(newValueBalance) - redeemed : Number(newValueBalance) 
                        const weekly = this.listCheckouts.filter((value) => value.activeWeekly)
                        const next = this.listCheckouts.filter((value) => value.activeNextDraw)
                        if(weekly.length > 0 || next.length > 0) {
                            this.balance = 0
                            this.walletReemed = 0
                        } else {
                            this.balance = redeemed ? Number(newValueBalance) - redeemed : Number(newValueBalance)
                            this.walletReemed = redeemed ? redeemed : 0
                        }
                        let total = this.subtotal - (this.walletReemed + this.balance)
                        this.totalAmount = total > 0 ? total : 0
                        this.totalPayment = this.totalAmount / this.exchangeRate
                        if(this.balance < 0) {
                            this.walletReemed = 0
                            this.balance = Number(newValueBalance)
                        }
                        if (this.totalAmount === 0) {
                            this.paymentSelected = ''
                            this.createAndMountFormElements()
                            this.showFloating = false
                            const checkoutDiv = document.getElementById("express-checkout-element")
                            if(checkoutDiv) {
                                checkoutDiv.style.visibility = 'hidden'
                                checkoutDiv.style.height = '0px';
                            }
                        } else if(this.screenWidth <= 991 && this.totalAmount !== 0 && this.$store.state.isLoggedIn) {
                            this.getLastPaymentMethod()
                        }
                    }
                } catch (error) {
                    this.isLoadingButtonConfirm = false;
                }
            }
        },

        handleEditInfoItem(id) {
            this.$store.dispatch("onChangeIdEditCheckoutCart", id);
            this.$router.push("/numbers");
        },

        onClickConfirmModal(id) {
            this.idIndex = id;
            this.$bvModal.show("modal-confirm-remove-block");
        },

        onCancelRemove() {
            this.$bvModal.hide("modal-confirm-remove-block");
            this.idIndex = "";
        },

        onScroll(e) {
            const currentScrollPosition =
                window.pageYOffset || document.documentElement.scrollTop;
            this.isScroll = currentScrollPosition > 0;
        },

        async onChangeDouble(index, i) {
            if (index !== -1 && i !== -1) {
                this.listCheckouts[index].doubles[i].isCheck =
                    !this.listCheckouts[index].doubles[i].isCheck;

                let item = this.listCheckouts[index];

                let listBlock = JSON.parse(localStorage.getItem("list_block"));

                let indexListBlock = listBlock.findIndex(
                    (x) => x.id === item.idBlock
                );

                listBlock[indexListBlock]["doubles"] =
                    listBlock[indexListBlock]?.doubles || [];

                let indexDou = listBlock[indexListBlock]["doubles"].findIndex(
                    (x) => x.id === item.event_id
                );
                if (item.doubles[i].isCheck) {
                    if (indexDou !== -1) {
                        listBlock[indexListBlock]["doubles"][
                            indexDou
                        ].value.push(item.doubles[i].id);
                    } else {
                        listBlock[indexListBlock]["doubles"].push({
                            id: item.event_id,
                            value: [item.doubles[i].id],
                        });
                    }
                    this.listCheckouts[index].amountDeducted =
                        item.amountDeducted !== -1
                            ? parseFloat(item.amountDeducted) +
                              parseFloat(item.doubles[i].value)
                            : -1;
                    this.listCheckouts[index].amountClone += parseFloat(item.doubles[i].value);
                    this.subtotal += parseFloat(item.doubles[i].value);
                } else {
                    let itemDoubles =
                        listBlock[indexListBlock]["doubles"][indexDou];
                    listBlock[indexListBlock]["doubles"][indexDou].value =
                        itemDoubles.value.filter(
                            (x) => x !== item.doubles[i].id
                        );
                    this.listCheckouts[index].amountDeducted =
                        item.amountDeducted !== -1
                            ? item.amountDeducted -
                              parseFloat(item.doubles[i].value)
                            : -1;
                    this.listCheckouts[index].amountClone -= parseFloat(item.doubles[i].value);
                    this.subtotal -= parseFloat(item.doubles[i].value);
                }

                let listBlockCart = [...listBlock];
                listBlock = JSON.stringify(listBlock);
                localStorage.setItem("list_block", listBlock);
                localStorage.setItem("list_block_tmp", listBlock);

                if (this.$store.state.isLoggedIn) {
                    this.timer && clearTimeout(this.timer);
                    this.timer = setTimeout(() => {
                        this.saveListCart(listBlockCart);
                    }, 1000);
                }
            }
            let data = await JSON.parse(localStorage.getItem("balance_data"))
            let redeemed = await JSON.parse(localStorage.getItem("gift_redeemed"))
            this.walletReemed = redeemed ? redeemed : 0
            const valueBalance = data ? data.balance.split(',') : '0'.split(',');
            const newValueBalance = valueBalance.join('')
            this.balance = redeemed ? Number(newValueBalance) - redeemed : Number(newValueBalance)
            const weekly = this.listCheckouts.filter((value) => value.activeWeekly)
            const next = this.listCheckouts.filter((value) => value.activeNextDraw)
            if(weekly.length > 0 || next.length > 0) {
                this.balance = 0
                this.walletReemed = 0
            } else {
                this.balance = redeemed ? Number(newValueBalance) - redeemed : Number(newValueBalance)
                this.walletReemed = redeemed ? redeemed : 0
            }
            let total = this.subtotal - (this.walletReemed + this.balance)
            this.totalAmount = total > 0 ? total : 0
            this.totalPayment = this.totalAmount / this.exchangeRate
            if(this.balance < 0) {
                this.walletReemed = 0
                this.balance = Number(newValueBalance)
            }
            if (this.totalAmount === 0) {
                this.paymentSelected = ''
                this.createAndMountFormElements()
                this.showFloating = false
                const checkoutDiv = document.getElementById("express-checkout-element")
                if(checkoutDiv) {
                    checkoutDiv.style.visibility = 'hidden'
                    checkoutDiv.style.height = '0px';
                }
            } else if(this.screenWidth <= 991 && this.totalAmount !== 0 && this.$store.state.isLoggedIn) {
                this.getLastPaymentMethod()
            }
        },

        async saveListCart(listBlock) {
            try {
                let totalTtems = localStorage.getItem("total_items");
                let param = {
                    cart_data: listBlock,
                    items: 1,
                    amount: totalTtems,
                };

                await authService.saveListCart(param);
                this.checkShowCard(this.listCheckouts);
            } catch (error) {
                console.log(error);
            }
        },

        renderClass(number) {
            const className =
                number > this.maxiumBlue && number <= this.maxiumGreen
                    ? "ball-blue"
                    : "ball-green";

            return className;
        },

        onChangeCard() {
            this.$refs.bottomChangeCard?.open();
        },

        formatCardNumber() {
            return creditCardMask(this.cardSelect?.card_number || "");
        },

        renderTypeCard(type) {
            switch (true) {
                case type === "Visa":
                    return "visa.png";
                case type === "Mastercard":
                    return "master.png";
                case type === "Amex":
                    return "amex.png";
                default:
                    return "visa.png";
            }
        },

        onClickGoBack() {
            this.step = 1;
            this.isRecurring = false;
        },

        async onSubmitChangeCard() {
            this.isLoadingChangeCard = true;
            try {
                const resp = await cardService.updateDefault(this.cardId);
                if (resp.status === 200) {
                    let index = this.listCards.findIndex(
                        (x) => x.id === this.cardId
                    );
                    if (index !== -1) {
                        let listCart = this.listCards.map((x, i) => ({
                            ...x,
                            is_default: i === index,
                        }));
                        this.cardSelect = this.listCards[index];
                        this.listCards = listCart;
                        this.$refs.bottomChangeCard?.close();
                        this.isLoadingChangeCard = false;
                    }
                }
            } catch (error) {
                console.log(error);
            }
        },

        formatLocalePrice(price) {
            return formatNumberMoney(parseFloat(price));
        },

        //action
        onGoBackPage() {
            this.$router.push("/numbers");
        },

        // format price
        formatNumberWinners(number) {
            let list_games = !isStrEmpty(localStorage.getItem("list_games"))
                ? JSON.parse(localStorage.getItem("list_games"))
                : [];

            let price = !isArrayEmpty(list_games)
                ? parseFloat(list_games[0]?.estimated_jackpot) * number
                : 0;

            return renderLocalePrice(parseInt(price), 0);
        },

        price(number) {
            let list_games = !isStrEmpty(localStorage.getItem("list_games"))
                ? JSON.parse(localStorage.getItem("list_games"))
                : [];

            let price = !isArrayEmpty(list_games)
                ? parseFloat(list_games[0]?.estimated_jackpot) * number
                : 0;

            return price;
        },

        renderTitleTicket(text) {
            let textLowercase = text.toLowerCase();
            let number = textLowercase.split(" ")[1];
            textLowercase = textLowercase.replaceAll(
                "ITEM",
                this.getTranslatedText("ITEM")
            );

            textLowercase = textLowercase.replaceAll(
                number,
                renderLocaleNumber(parseInt(number))
            );
            return textLowercase;
        },

        renderDate(date) {
            // let locale = this.$store.state.isRtl ? "ar" : "en";
            // moment.locale(locale);

            return moment(date).format("DD/MM/YYYY");
        },

        renderNumberLocale(number) {
            return renderLocaleNumber(number);
        },
        coverTimeUAE(time){
            const dateTime = new Date(time);
            const UTC_Time = dateTime.getTime() + (dateTime.getTimezoneOffset() * 60000);
            const UAE_Time = new Date(UTC_Time + (3600000*4));
            return UAE_Time;
        },
        // updateRemainingTime(targetTime){
        //     const dateObject = this.coverTimeUAE(targetTime).getTime();
        //     const currentTime = this.coverTimeUAE(new Date()).getTime();
        //     const timeDifference = new Date(dateObject).getTime() - currentTime;
        //     if(timeDifference <= 0){
        //         return 'Time out';
        //     }
        //     const oneDay = 24 * 60 * 60 * 1000;
        //     const oneHour = 60 * 60 * 1000;
        //     const oneMinute = 60 * 1000;

        //     if (timeDifference >= oneDay) {
        //         return `${Math.floor(timeDifference / oneDay)} ${this.getTranslatedText('Days Remaining')}`;
        //     } else if (timeDifference >= oneHour) {
        //         return `${Math.floor(timeDifference / oneHour)} ${this.getTranslatedText('Hours Remaining')}`;
        //     } else if (timeDifference >= oneMinute) {
        //         return `${Math.floor(timeDifference / oneMinute)} ${this.getTranslatedText('Minutes Remaining')}`;
        //     } else {
        //         return `${Math.floor(timeDifference / 1000)} ${this.getTranslatedText('Seconds Remaining')}`;
        //     }
        // },
        async handleValidateVoucher(){
            this.isLoadingBtnValidate = true;
             try {
                VoucherService.addVoucherCode({
                    code: this.voucherCode
                })
                .then(res => {
                    if(res.status === 200) {
                        // this.showToast(this.getTranslatedText(res.data.message), 'success');
                        this.messageVoucher = {
                            isSuccess: true,
                            message: res.data.message
                        }
                        setTimeout(() => {
                            this.messageVoucher = {
                                isSuccess: true,
                                message: ''
                            }
                        }, 1500);
                        this.$store.dispatch("setBalance");
                        this.voucherCodeTemp = this.voucherCode
                        this.voucherCode = ''
                        this.walletReemed = this.walletReemed + res.data.amount
                        localStorage.setItem('gift_redeemed', this.walletReemed)
                        let total = this.subtotal - (this.walletReemed + this.balance)
                        this.totalAmount = total > 0 ? total : 0
                        this.totalPayment = this.totalAmount / this.exchangeRate
                        this.isLoadingBtnValidate = false;
                        if (this.totalAmount === 0) {
                            this.paymentSelected = ''
                            this.createAndMountFormElements()
                            this.showFloating = false
                            const checkoutDiv = document.getElementById("express-checkout-element")
                            if(checkoutDiv) {
                                checkoutDiv.style.visibility = 'hidden'
                                checkoutDiv.style.height = '0px';
                            }
                        } else if(this.screenWidth <= 991 && this.totalAmount !== 0 && this.$store.state.isLoggedIn) {
                            this.getLastPaymentMethod()
                        }
                    } else {
                        let message = "";
                        if (!isStrEmpty(res.data?.attempt_type) && !isStrEmpty(res.data?.banned_until)) {
                            let dateGST = this.convertGSTtime(res.data.banned_until, true)
                            if(res.data.attempt_type === 1) {
                                message = this.getTranslatedText(
                                    'User Ban Voucher in 10 minutes. Please use voucher after [date] gst', { date: dateGST })
                            } else {
                                message = this.getTranslatedText(
                                    'User Ban Voucher in 1 day. Please use voucher after [date] gst', { date: dateGST })     
                            }
                            // this.showToast(message, "error")
                            this.messageVoucher = {
                                isSuccess: false,
                                message
                            }
                        } else {
                            !isArrayEmpty(res.errors)
                                ? res.errors.forEach((element) => {
                                    message += element.message;
                            })
                            : (message = res.message);
                            // this.showToast(this.getTranslatedText(message), 'error');
                            this.messageVoucher = {
                                isSuccess: false,
                                message
                            }
                        }
                        this.isLoadingBtnValidate = false;
                    }
                })
            } catch (error) {
                this.isLoadingBtnValidate = false;
                console.log(error);
            }
        },
        updatePaymentMethod(event) {
            this.paymentSelected = event
            if(this.paymentSelected) {
                this.createAndMountFormElements(event)
                const element = document.getElementById('payment-method');
                element.style.border = 'none';
                this.messagePaymentSelected = ''
                if(event === 'apple-pay') {
                    const el = document.getElementById('payment-method')
                    if(el && window.getComputedStyle(el).display === 'none') {
                        const element = document.getElementById('payment-method');
                        element.style.border = '1px solid red';
                        this.messagePaymentSelected = 'This Payment Method Is Not Available on your browser';
                    }
                }
                if(event === 'google-pay' && this.totalAmount < 2) {
                    this.showToast('Not enough amount in your wallet. You must charge at least 2 AED by your card', "error");
                }
                if(event === 'apple-pay' && this.totalAmount < 2 && this.paymentType.includes('stripe')) {
                    this.showToast('Not enough amount in your wallet. You must charge at least 2 AED by your card', "error");
                }
            }
        },
        async getLastPaymentMethod() {
            try {
                const res = await PaymentService.getLastPaymentMethod()
                if (res.status === 200) {
                    this.paymentSelected = res.data.last_payment_method
                    if(res.data.last_payment_method) {
                        this.showFloating = true
                        const el = document.getElementById('payment-method')
                        if(res.data.last_payment_method === 'apple-pay' && this.totalAmount < 2 && el && window.getComputedStyle(el).display !== 'none') {
                            this.showToast('Not enough amount in your wallet. You must charge at least 2 AED by your card', "error");
                        }
                        if(this.paymentType.includes('stripe') && res.data.last_payment_method === 'google-pay') {
                            this.paymentSelected = 'card'
                        } else {
                            this.paymentSelected = res.data.last_payment_method
                        }
                        if (this.paymentType.includes('upi') && res.data.last_payment_method === 'upi') {
                            this.paymentSelected = 'upi'
                        } else {
                            this.paymentSelected = 'card'
                        }
                        if(this.totalAmount !== 0) {
                            this.createAndMountFormElements(this.paymentSelected)
                        } else {
                            this.createAndMountFormElements()
                        }
                    } else {
                        this.showFloating = false
                    }
                } else {
                    this.paymentSelected = ''
                    this.showFloating = false
                }
            } catch (error) {
                this.paymentSelected = ''
                this.showFloating = false
                console.log(error)
            }
        },
        toggleItemActive(index, type){
            let data = JSON.parse(localStorage.getItem("balance_data"))
            let redeemed = JSON.parse(localStorage.getItem("gift_redeemed"))
            let checkRedeemd = redeemed ? redeemed : 0
            const valueBalance = data ? data.balance.split(',') : '0'.split(',');
            let newValueBalance = valueBalance.join('')
            this.listCheckouts = this.listCheckouts.map((item, i) => {
                if(i === index){
                    if (type === 'weekly') {
                        item.activeWeekly = !item.activeWeekly
                        item.activeNextDraw = false
                    } else if (type === 'nextDraw') {
                        item.activeNextDraw = !item.activeNextDraw
                        item.activeWeekly = false
                    } else {
                        item.activeWeekly = false
                        item.activeNextDraw = false
                    }
                }
                return item
            })
            const weekly = this.listCheckouts.filter((value) => value.activeWeekly)
            const next = this.listCheckouts.filter((value) => value.activeNextDraw)
            if(weekly.length > 0 || next.length > 0) {
                this.balance = 0
                this.walletReemed = 0
            } else {
                this.balance = Number(newValueBalance) - checkRedeemd
                this.walletReemed = checkRedeemd
            }
            let total = this.subtotal - (this.walletReemed + this.balance)
            this.totalAmount = total > 0 ? total : 0  
            this.totalPayment = this.totalAmount / this.exchangeRate       
            if(this.balance < 0) {
                this.walletReemed = 0
                this.balance = Number(newValueBalance)
            }
        },
        async getUserData(type) {
            try {
                const res = await UserService.getDataUser();
                this.emailExist = res.email 
                if(type !== 'MBME' && res.calling_code === 91 && this.paymentType.includes('upi')) {
                    this.optionsPayment.push({
                        item: 'upi', name: 'UPI'
                    })
                }
                this.isLoadingBtn = false;
            } catch (error) {
                console.log(error);
            }
        },
        // MBME
        createFormDataPaymentMBME(data) {
            const form = document.createElement('form');
            form.method = 'post';
            form.action = data.mbme_request_url;
            form.name = 'redirect';

            // Create hidden input for uid
            const uid = document.createElement('input');
            uid.type = 'hidden';
            uid.name = 'uid';
            uid.value = data.fields.uid;
            form.appendChild(uid);

            // Create hidden input for oid
            const oid = document.createElement('input');
            oid.type = 'hidden';
            oid.name = 'oid';
            oid.value = data.fields.oid;
            form.appendChild(oid);

            // Create hidden input for timestamp
            const timestamp = document.createElement('input');
            timestamp.type = 'hidden';
            timestamp.name = 'timestamp';
            timestamp.value = data.fields.timestamp;
            form.appendChild(timestamp);

            // Create hidden input for timestamp
            const request_method = document.createElement('input');
            request_method.type = 'hidden';
            request_method.name = 'request_method';
            request_method.value = data.fields.request_method;
            form.appendChild(request_method);

            // Create hidden input for name
            const name = document.createElement('input');
            name.type = 'hidden';
            name.name = 'customer_info[name]';
            name.value = data.fields.customer_info.name;
            form.appendChild(name);

            // Create hidden input for email
            const email = document.createElement('input');
            email.type = 'hidden';
            email.name = 'customer_info[email]';
            email.value = data.fields.customer_info.email;
            form.appendChild(email);

            // Create hidden input for mobile_country_code
            const mobile_country_code = document.createElement('input');
            mobile_country_code.type = 'hidden';
            mobile_country_code.name = 'customer_info[mobile_country_code]';
            mobile_country_code.value = data.fields.customer_info.mobile_country_code;
            form.appendChild(mobile_country_code);

            // Create hidden input for mobile_number
            const mobile_number = document.createElement('input');
            mobile_number.type = 'hidden';
            mobile_number.name = 'customer_info[mobile_number]';
            mobile_number.value = data.fields.customer_info.mobile_number;
            form.appendChild(mobile_number);

            // Create hidden input for amount
            const amount = document.createElement('input');
            amount.type = 'hidden';
            amount.name = 'transaction_info[amount]';
            amount.value = data.fields.transaction_info.amount;
            form.appendChild(amount);

            // Create hidden input for currency
            const currency = document.createElement('input');
            currency.type = 'hidden';
            currency.name = 'transaction_info[currency]';
            currency.value = data.fields.transaction_info.currency;
            form.appendChild(currency);

            // Create hidden input for payment_method_id
            const payment_method_id = document.createElement('input');
            payment_method_id.type = 'hidden';
            payment_method_id.name = 'payment_info[payment_method_id]';
            payment_method_id.value = data.fields.payment_info.payment_method_id;
            form.appendChild(payment_method_id);

            // Create hidden input for token
            const token = document.createElement('input');
            token.type = 'hidden';
            token.name = 'payment_info[token]';
            token.value = data.fields.payment_info.token;
            form.appendChild(token);

            // Create hidden input for save_card
            const save_card = document.createElement('input');
            save_card.type = 'hidden';
            save_card.name = 'payment_info[save_card]';
            save_card.value = data.fields.payment_info.save_card;
            form.appendChild(save_card);

            // Create hidden input for reference_number
            const reference_number = document.createElement('input');
            reference_number.type = 'hidden';
            reference_number.name = 'client_info[reference_number]';
            reference_number.value = data.fields.client_info.reference_number;
            form.appendChild(reference_number);

            // Create hidden input for secure_sign
            const secure_sign = document.createElement('input');
            secure_sign.type = 'hidden';
            secure_sign.name = 'secure_sign';
            secure_sign.value = data.fields.secure_sign;
            form.appendChild(secure_sign);

            // Append form to body and submit
            document.body.appendChild(form);
            form.submit();
        },
        async paymentMBME(value) {
            this.isLoadingBtn = true;
            const param = {
                amount: parseFloat(this.formatLocalePrice(this.totalAmountPayment)),
                payment_method: "card",
                email: this.valueEmail
            }
            try {
                const resp = await PaymentService.paymentMBME(param);
                if (resp.status === 200) {
                    localStorage.setItem("methodPayment", "2");
                    const param = {
                        paymentType: this.paymentType,
                        message: 'Your payment has failed due to incorrect details. Check your details and try again',
                        order_id: resp.data.reference,
                        amount: value.totalAmountPayment * this.exchangeRate || 'N/A',
                        quantity: value.quantity || 'N/A',
                        voucher_code: value.voucher_code || 'N/A',
                        draw_date: value.draw_date || 'N/A',
                        currency: this.currencyCode,
                        payment_vendor: value.payment_vendor || 'N/A',
                        type: value.type,
                        draw_number: value.draw_number,
                        double_the_grand_prize: value.double_the_grand_prize,
                        secure_the_grand_prize: value.secure_the_grand_prize,
                        items: value.items
                    }
                    localStorage.setItem("dataPay", JSON.stringify(param))
                    this.isLoadingBtn = false;
                    this.getUserData('MBME')
                    this.createFormDataPaymentMBME(resp.data)
                } else {
                    this.isLoadingBtn = false;
                    let message = ''
                    !isArrayEmpty(resp.errors)
                        ? resp.errors.forEach((element) => {
                            message += element.message;
                    })
                    : (message = resp.message);
                    this.showToast(this.getTranslatedText(message), "error")
                }
            }
            catch (error) {
                console.log(error)
            }
        },
        // UPI
        async paymentUPI() {
            this.isLoadingBtn = true;
            try {
                const data = {
                    amount: parseFloat(this.formatLocalePrice(this.totalAmountPayment)),
                    payment_method: "upi",
                    email: this.valueEmail
                }
                const resp = await PaymentService.purchasePaymentUPI(data);
                if(resp.status === 200 && resp.data.status === 'success') {
                    localStorage.setItem("methodPayment", "2");
                    const cut_url = resp.data.payment_url.slice(3)
                    const cut_other = resp.data.payment_url.slice(5)
                    const param = {
                        ...resp.data,
                        cut_url,
                        cut_other
                    }
                    localStorage.setItem("dataSourceUPI", JSON.stringify(param))
                    this.isLoadingBtn = false;
                    this.$router.push('/payment');
                } else {
                    let message = "";
                    !isArrayEmpty(resp.errors)
                        ? resp.errors.forEach((element) => {
                            message += element.message;
                        })
                        : (message = resp.message);

                    this.showToast(message, "error");
                    this.isLoadingBtn = false;
                }
            } catch (err) {
                this.isLoadingBtn = false;
                console.log(err, "err");
            }
        },
    },
};
</script>

<style scoped lang="scss" src="@/assets/scss/mobile/checkout/_checkout.scss" />
<style scoped lang="scss" src="@/assets/scss/mobile/payment/_payment.scss"/>
