<template>
    <div class="main">
        <BannerCustom title=""/>
        <div class="contact-us">
            <div class="contact-us__container">
                <div class="left">
                    <div class="head">
                        <div class="contact-us__head">
                            <h1 class="contact-us__head__title">
                                {{ getTranslatedText("we’d love to hear from you!") }}
                            </h1>
                            <div class="contact-us__head__des">
                                {{ getTranslatedText("don’t hesitate to connect with us…") }}
                            </div>
                        </div>
                    </div>
                    <div class="contact-us__content">
                        <div class="contact-us__content-left">
                            <div v-if="messageText" class="noti-message">{{ getTranslatedText(messageText) }}</div>
                            <FormCommon
                                :formItem="formItem"
                                :dataForm="dataForm"
                                @on-bur-input="onBurInputFormData"
                                @update-form-data="updateFormData"
                                @onSubmitForm="onSubmitForm"
                                classContainer="contact-us__content-left__form"
                            >
                                <b-input-group-prepend
                                    slot="propend-phone-number"
                                    class="phone-code"
                                >
                                    <button
                                        class="show-dropdown"
                                        type="button"
                                        @click="showDropDow"
                                    >
                                        <div class="flag-group">
                                            <img loading="lazy"
                                                class="user-avatar"
                                                :src="
                                                require(`@/assets/images/flags/${dataForm.flag}`)
                                            "
                                                :alt="dataForm.flag"
                                            />
                                        </div>
                                        {{ dataForm.calling_code }}
                                    </button>

                                    <b-dropdown ref="select_phone_code">
                                        <b-form-input
                                            v-model="textSearchPhoneNumber"
                                            :placeholder="getTranslatedText('search')"
                                            @input="onChangeFilterPhoneNumbers"
                                        ></b-form-input>
                                        <b-dropdown-item
                                            @click="selectPhoneCode(item)"
                                            v-for="(item, index) in phoneNumbersFilter"
                                            :key="index"
                                        >
                                            <div>
                                                <div class="flag-group">
                                                    <img loading="lazy"
                                                        class="user-avatar"
                                                        :src="
                                                        require(`@/assets/images/flags/${item.flag}`)
                                                    "
                                                        :alt="item.flag"
                                                    />
                                                </div>
                                                <span class="name-country">{{
                                                        item.name
                                                    }}</span>
                                                ({{ item.number }})
                                            </div>
                                        </b-dropdown-item>
                                    </b-dropdown>
                                    <img loading="lazy"
                                        class="dropdown-phone-icon"
                                        src="@/assets/images/personal-detail/dropdown-black.png"
                                    />
                                </b-input-group-prepend>
                            </FormCommon>
                        </div>
                            <div class="content-last">
                                <b-button @click="onSubmitForm" class="contact-us__content-left__submit">
                                    {{ getTranslatedText("Send Message") }}
                                </b-button>
                            </div>
                    </div>
                </div>
                <div class="right">
                    <div class="contact-us__content-right">
                        <div class="contact-us__content-right__socials">
                            <div class="contact-us__content-right__socials__title">
                                {{ getTranslatedText("stay connected with us") }}
                            </div>
                        </div>
                        <div class="contact-us__content-right__socials__content">
                            <a
                                :href="youtubeUrl"
                                target="_blank"
                            >
                                <img loading="lazy" src="@/assets/images/static-pages/contact-us/youtube-icon.svg" />
                            </a>
                            <a
                                :href="instagramUrl"
                                target="_blank"
                            >
                                <img loading="lazy" src="@/assets/images/static-pages/contact-us/instagram.svg" />
                            </a>
                            <a
                                :href="facebookUrl"
                                target="_blank"
                            >
                                <img loading="lazy" src="@/assets/images/static-pages/contact-us/facebook.svg" />
                            </a>
                            <a
                                :href="twitterUrl"
                                target="_blank"
                            >
                                <img loading="lazy" src="@/assets/images/static-pages/contact-us/twitter.svg" />
                            </a>
                            <a
                                v-if="isHideWhatsapp === 'no'"
                                :href="whatsappUrl"
                                target="_blank"
                            >
                                <img loading="lazy" src="@/assets/images/static-pages/contact-us/whatsapp.svg" />
                            </a>
                            <a
                                :href="tiktokUrl"
                                target="_blank"
                            >
                                <img loading="lazy" src="@/assets/images/static-pages/contact-us/tiktok.svg" />
                            </a>
                            <a
                                :href="linkedinUrl"
                                target="_blank"
                            >
                                <img loading="lazy" src="@/assets/images/static-pages/contact-us/linkedin.svg" />
                            </a>
                            <a
                                :href="snapchatUrl"
                                target="_blank"
                            >
                                <img loading="lazy" src="@/assets/images/static-pages/contact-us/snapchat.svg" />
                            </a>
                        </div>
                    </div>
                    <div class="info-contact">
                        <a href="tel:+800 645 54667" class="info-contact__content toll-free">
                            <img loading="lazy" src="@/assets/images/static-pages/contact-us/phone-contact.svg" />
                            <div class="info-contact__text t-transform-cap">
                                <div class="title">{{ getTranslatedText('Toll Free') }}</div>
                                <div class="sub-title">{{ getTranslatedText('800-Millions (800 645 54667)') }}</div>
                            </div>
                        </a>
                        <div class="line"/>
                        <a href="mailto:support@omillionaire.com" class="info-contact__content e-address">
                            <img loading="lazy" src="@/assets/images/static-pages/contact-us/email-contact.svg" />
                            <div class="info-contact__text t-transform-cap">
                                <div class="title">{{ getTranslatedText('Email Address') }}</div>
                                <div class="sub-title text-email">{{ getTranslatedText('support@omillionaire.com') }}</div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
            <Toast :title="toastData.message" :type="toastData.type" />
        </div>
    </div>
</template>
<script>
import LanguageMixin from "@/mixins/language/language-mixin";
import ContactService from "@/services/contact.service";
import Toast from "@/components/common/toast/Toast.vue";
import UserService from "@/services/user.service";
import FormCommon from "@/components/common/form";
import {phoneNumbers} from "@/resources/phoneNumber";
import {filterFullTextSearch, isStrEmpty, validateEmail} from "@/components/common/utils";
import { isPossiblePhoneNumber, isValidPhoneNumber } from "libphonenumber-js";
import parseMax from 'libphonenumber-js/max'
import defaultCountryCodeMixin from "@/mixins/default-country-code/default-country-code-mixin";
import BannerCustom from "@/components/common/banner";

export default {
    mixins: [LanguageMixin, defaultCountryCodeMixin],
    props: {
        totalAmount: {
            type: Number,
            default: 0,
        },
        paramCheckout: {
            type: Object,
            default: () => {
                return {};
            },
        },
        isRecurring: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        Toast,
        FormCommon,
        BannerCustom
    },
    // metaInfo() {
    //     return {
    //         title: "Contact O! Millionaire: Play, Plant & Win Your Best Life",
    //         meta: [
    //             {
    //                 vmid: "description",
    //                 name: "description",
    //                 content: "Reach O! Millionaire 24/7 at 800 Millions (645 54667). Your best life starts here—Play, Plant & Win with us!"
    //             }
    //         ]
    //     };
    // },
    mounted() {
        if(this.$store.state.isLoggedIn) {
            this.getUserData();
        }
        this.getQuestions();
        this.handleDefaultCountry(this.dataForm);
    },
    data() {
        return {
            emailValidate: '',
            phoneValidate: '',
            textSearchPhoneNumber: "",
            phoneNumbersFilter: phoneNumbers,
            messageText: "",
            youtubeUrl: process.env.VUE_APP_YOUTUBE_URL,
            instagramUrl: process.env.VUE_APP_INSTAGRAM_URL,
            facebookUrl: process.env.VUE_APP_FACEBOOK_URL,
            twitterUrl: process.env.VUE_APP_TWITTER_URL,
            whatsappUrl: process.env.VUE_APP_WHATSAPP_URL,
            tiktokUrl: process.env.VUE_APP_TIKTOK_URL,
            linkedinUrl: process.env.VUE_APP_LINKEDIN_URL,
            snapchatUrl: process.env.VUE_APP_SNAPCHAT_URL,
            isHideWhatsapp: process.env.VUE_APP_HIDE_ALL_WHATSAPP,
            dataForm: {
                calling_code: "+968",
                code: "+968",
                flag: "Flag_of_Oman.svg",
                nameCountry: "Oman",
                question_id: 1,
                name: '',
                phone_number: '',
                email: '',
                content: ''
            },
            dataSynerise: {
                question_id: 1,
                name: '',
                phone_number: '',
                email: '',
                content: ''
            },
            formItem: [
                {
                    type: "select",
                    label: "What can we help you with?",
                    name: "question_id",
                    required: true,
                    hideIconRq: true,
                    for: "input-list-help",
                    id: "input-list-help",
                    idGroup: "input-group-list-help",
                    options: [],
                },
                {
                    type: "input",
                    typeInput: "text",
                    placeholder: "Example: John Cruz",
                    required: true,
                    hideIconRq: true,
                    name: "name",
                    label: "Name",
                    for: "input-name",
                    id: "input-name",
                    idFormGroup: "contact-us-name",
                    state: null,
                    textInvalid: "Please enter the name least 3 characters",
                },
                {
                    type: "input",
                    typeInput: "email",
                    placeholder: "Example: john@example.com",
                    name: "email",
                    required: true,
                    hideIconRq: true,
                    label: "Email address",
                    for: "input-email",
                    id: "input-email",
                    idFormGroup: "register-email",
                    state: null,
                    textInvalid: "please enter email",
                },
                {
                    type: "input-group",
                    typeInput: "number",
                    required: true,
                    hideIconRq: true,
                    placeholder: "Example: XXXX XXXX",
                    name: "phone_number",
                    label: "Mobile Number",
                    for: "input-phone",
                    id: "contact-us-phone",
                    prepend: "code",
                    nameSlotInputPrepend: "propend-phone-number",
                    state: null,
                    textInvalid: "please enter phone number",
                },
                {
                    type: "input-area",
                    typeInput: "text",
                    placeholder: "Please write your queries on the space provided",
                    name: "content",
                    required: true,
                    hideIconRq: true,
                    label: "Message",
                    for: "input-details",
                    id: "input-details",
                    idFormGroup: "contact-us-details",
                    state: null,
                    textInvalid: "Please enter the details least 3 characters",
                },
            ],
            isLoadingButton: false,
            toastData: {
                message: "",
                type: "success",
            },
            isVerifyRecaptcha: false,
            showErrorRequiredCaptcha: false,
            email: '',
            message: '',
        };
    },
    methods: {
        handleValidatePhoneNumber(phone) {
            const phoneCallingCode = `${this.dataForm.code}${phone}`
            const isPossiblePhone = isPossiblePhoneNumber(phoneCallingCode) === true;
            const typePhone = ['MOBILE', 'FIXED_LINE_OR_MOBILE']
            const isValidType = typePhone.includes(parseMax(phoneCallingCode)?.getType());
            const isValidNumber = isValidPhoneNumber(phoneCallingCode) === true
            this.formItem[3].state = isPossiblePhone && isValidNumber && isValidType;
            if (!this.formItem[3].state && phone) {
                this.formItem[3].textInvalid = this.getTranslatedText(
                    "incorrect phone format"
                );
            } else {
                this.formItem[3].textInvalid = "please enter mobile number";
            }
        },
        showDropDow() {
            const dropdown = this.$refs.select_phone_code;
            dropdown.show();
        },
        // change phone code
        selectPhoneCode(item) {
            if (
                this.dataForm.calling_code !== item.number &&
                !isStrEmpty(this.dataForm.phone_number)
            ) {
                let event = {
                    target: {
                        value: this.dataForm.phone_number,
                        name: "phone_number",
                    },
                };

                let phoneCode = item.number;

                this.onBurInputFormData(event, phoneCode);
            }
            this.dataForm.calling_code = item.number;
            this.dataForm.code = item.number;
            this.dataForm.flag = item.flag;
            this.dataForm.nameCountry = item.name.replace(
                /^(.{5}[^\s]*).*/,
                "$1"
            );
            this.textSearchPhoneNumber = "";
            this.phoneNumbersFilter = phoneNumbers;
            this.handleValidatePhoneNumber(this.dataForm.phone_number);
        },
        getQuestions() {
            const locale = localStorage.getItem("locale");
            ContactService.questionsContactUs(locale ? locale : 'en').then(res=> {
                this.formItem[0].options=res.data;
                this.dataForm.question_id = this.formItem[0].options[0].value;
            })
        },
        onChangeFilterPhoneNumbers(value) {
            let phoneNumber = [...phoneNumbers];
            if (!isStrEmpty(value)) {
                this.phoneNumbersFilter = filterFullTextSearch(
                    phoneNumber,
                    value,
                    "name",
                    "number"
                );
            } else {
                this.phoneNumbersFilter = phoneNumber;
            }
        },
        async onBurInputFormData(event, phoneCode, ) {
            const { name, value } = event.target;
            let { calling_code, phone_number, email } = this.dataForm;

            if (name === "email" && isStrEmpty(value)) {
                this.dataForm.email = "";
            }

            if(isStrEmpty(phone_number) && this.formItem[3].state !== null) {
                this.formItem[3].state = null;
            }

            if(name === "email" && !isStrEmpty(value)) {
                if(!validateEmail(value)) {
                    this.formItem[2].state = false;
                    this.formItem[2].textInvalid = this.getTranslatedText(
                        "incorrect email format"
                    );
                } else {
                    this.formItem[2].state = null;
                }
                this.dataForm.email = value;
            }

            switch (true) {
                // check Incorrect email format
                // case name === "email" &&
                // !isStrEmpty(value) &&
                // !validateEmail(value):
                //     this.formItem[3].state = false;
                //     this.formItem[3].textInvalid = this.getTranslatedText(
                //         "correct email format"
                //     );
                //     this.dataForm.email = value;
                //     break;
                case (name === "phone_number" && value !== phone_number && value === ""):
                    this.dataForm.phone_number = ''
                    this.formItem[3].state = null;
                default:
                    break;
            }
        },
        updateFormData(item) {
            let { value, name } = item;

            if(name === "question_id") {
                this.dataForm.question_id = value;
                if(this.dataForm.question_id.length > 0) {
                    this.formItem[0].state = null;
                } else {
                    this.formItem[0].state = false;
                }
            }

            if(name === "email") {
                this.emailValidate = value
                this.dataForm.email = value;
            }

            if(name === "phone_number") {
                this.phoneValidate = value;
                this.handleValidatePhoneNumber(value);
                this.dataForm.phone_number = value;
            }
            if(name === "name") {
                if(this.formItem[1].state === false && value.length > 2) {
                    this.formItem[1].state = null;
                    this.dataForm.name = value;
                } else {
                    this.dataForm.name = value;
                }
            }

            if(name === "content") {
                if(this.formItem[4].state === false && value.length > 2) {
                    this.formItem[4].state = null;
                    this.dataForm.content = value;
                } else {
                    this.dataForm.content = value;
                }
            }
        },
        async onSubmitForm() {
            try {
                this.isLoadingButton = true
                let { phone_number, name, content, email, question_id  } = this.dataForm;
                if (question_id.length < 1) {
                    this.formItem[0].state = false
                    let scrollDiv = document.getElementById("input-list-help").offsetTop;
                    window.scrollTo({ top: scrollDiv, behavior: 'smooth'});
                    this.isLoadingButton = false
                    return false;
                }

                if (name.length < 3 || this.formItem[1].state === false) {
                    this.formItem[1].state = false
                    let scrollDiv = document.getElementById("input-name").offsetTop;
                    window.scrollTo({ top: scrollDiv, behavior: 'smooth'});
                    this.formItem[1].textInvalid = 'Please enter the name least 3 characters';
                    this.isLoadingButton = false
                    return false;
                }

                const letters = /[.,?'"/!;:{}\-—`()[\]]/
                if (!isStrEmpty(name) && letters.test(name)) {
                    this.formItem[1].state = false;
                    let scrollDiv =
                        document.getElementById("input-name").offsetTop;
                        window.scrollTo({ top: scrollDiv, behavior: "smooth" });
                    this.formItem[1].textInvalid = 'Please do not enter punctuation marks';
                    return false;
                }

                if (phone_number.length < 1 || this.formItem[3].state === false) {
                    this.formItem[3].state = false
                    let scrollDiv = document.getElementById("contact-us-phone").offsetTop;
                    window.scrollTo({ top: scrollDiv, behavior: 'smooth'});
                    this.isLoadingButton = false
                    return false;
                }

                if (email.length < 1 || this.formItem[3].state === false) {
                    this.formItem[2].state = false
                    let scrollDiv = document.getElementById("input-email").offsetTop;
                    window.scrollTo({ top: scrollDiv, behavior: 'smooth'});
                    this.isLoadingButton = false
                    return false;
                }

                if (content.length < 3 || this.formItem[4].state === false) {
                    this.formItem[4].state = false
                    let scrollDiv = document.getElementById("input-details").offsetTop;
                    window.scrollTo({ top: scrollDiv, behavior: 'smooth'});
                    this.isLoadingButton = false
                    return false;
                }
                if(!isStrEmpty(this.phoneValidate)) {
                    this.handleValidatePhoneNumber(this.phoneValidate);
                }
                ContactService.contactUs({
                    locale: localStorage.getItem("locale"),
                    question_id: this.dataForm.question_id,
                    name: this.dataForm.name,
                    email: this.dataForm.email,
                    phone_number: `${this.dataForm.calling_code}${this.dataForm.phone_number}`,
                    content: this.dataForm.content,
                }).then(res=> {
                    if(res.status === 200) {
                        this.messageText = res.message
                        this.dataForm.name= "";
                        this.dataForm.phone_number= "";
                        this.formItem[2].state = null;
                        this.dataForm.email= "";
                        this.dataForm.content= "";
                    } else {
                        this.messageText = res.message
                    }
                    setTimeout(() => {
                        this.messageText = ''
                    }, 3000);
                })
            } catch (error) {
                console.log(error)
                this.isLoadingButton = false
            }
        },
        getUserData() {
            UserService.getDataUser().then((res) => {
                if (res) {
                    this.email = res.email;
                }
            });
        },
        verifyMethod(res) {
            if(res) {
                this.isVerifyRecaptcha = true;
                this.showErrorRequiredCaptcha = false;
            }
        },
        expiredMethod() {
            this.isVerifyRecaptcha = false;
        },
        errorMethod() {
            this.isVerifyRecaptcha = false;
            this.showErrorRequiredCaptcha = false;
        },
        submit() {
            if(this.isVerifyRecaptcha === true) {
                ContactService.contactUs({
                    locale: localStorage.getItem("locale"),
                    email: this.email,
                    content: this.message
                }).then(res=> {
                    if(res.status === 200) {
                        this.showToast(
                            this.getTranslatedText(res.message),
                            "success"
                        );
                        this.email = "";
                        this.message = "";
                    } else {
                        this.showToast(
                            this.getTranslatedText(res.message),
                            "error"
                        );
                    }
                })
            } else {
                this.showErrorRequiredCaptcha = true;
            }
        },
        showToast(message, type) {
            this.toastData.message = message;
            this.toastData.type = type;
            this.$bvToast.show("my-toast");
        },
        onVerify(response) {
            if (response) this.robot = true;
        },
    },
};
</script>

<style
    scoped
    lang="scss"
    src="@/assets/scss/mobile/static-pages/contact-us.scss"
/>
