import BaseService from "@/services/base.service";

const API_URL = process.env.VUE_APP_API_URL;

interface PuscharsePaypoundInterFace {
    card_number: string;
    expiry_month: string;
    expiry_year: string;
    card_security_code: number;
    amount: string;
    address: string;
    city: string;
    state: string;
    zipcode: string;
    country_code: string;
}
interface PuscharseInterFace {
    card_number: string;
    expiry_date: string;
    card_holder_name: string;
    card_security_code: number;
    amount: string;
    remember_me: string;
}

interface PuscharseCardInterFace {
    card_id: string;
    amount: string;
    card_security_code: string;
}

interface PurchaseThawaniInterFace {
    amount: string;
}

interface CcavenuePaymentInterface {
    amount: string;
    is_fund_deposit?: boolean;
    is_recurring?: string;
}
type PurchasePayload = CcavenuePaymentInterface | string;

class PaymentService extends BaseService {
    getGateway() {
        return this.get(
            API_URL + `/api/get-gateway`,
        ).then(({data}) => data);
    }

    purchaseThawani(payload: PurchaseThawaniInterFace) {
        return this.post(
            API_URL + `/api/nbo-debit-card-payment/purchase`,
            payload
        ).then(({ data }) => data);
    }

    purchasePaypound(payload: PuscharsePaypoundInterFace) {
        return this.post(
            API_URL + `/api/paypound-payment/purchase`,
            payload
        ).then(({ data }) => data);
    }

    puscharseByCard(payload: PuscharseCardInterFace) {
        return this.post(
            API_URL + `/api/aws-payment/puschase-by-card`,
            payload
        ).then(({ data }) => data);
    }

    puschase(payload: PuscharseInterFace) {
        return this.post(API_URL + `/api/aws-payment/puschase`, payload).then(
            ({ data }) => data
        );
    }

    puschaseNboPayment(amount: String) {
        return this.post(API_URL + `/api/nbo-payment/purchase`, {amount}).then(
            ({ data }) => data
        );
    }

    puschaseCcavenuePayment(payload: PurchasePayload) {
        if(typeof payload !== 'object') {
            const amount = payload
            payload = {amount:amount}
        }
        return this.post(API_URL + `/api/ccavenue-payment/purchase`, payload).then(
            ({ data }) => data
        );
    }

    resultNboPayment(reference: String, status: String) {
        return this.post(API_URL + `/api/nbo-payment/result`, {reference, status}).then(
            ({ data }) => data
        );
    }

    callBackStatusPayment(reference: String) {
        return this.post(API_URL + `/api/callback/status`, {reference}).then(
            ({ data }) => data
        );
    }

    purchaseStripe(payload) {
        return this.post(API_URL + "/api/stripe/purchase", payload).then(
            (response) => {
                return response.data;
            }
        );
    }

    createCertificate(code) {
        return this.post(API_URL + "/api/common/is-certificate-generation-completed", {code}).then(
            (response) => {
                return response.data;
            }
        );
    }

    callBackStripe(payment_intent: string) {
        return this.get(
            API_URL + `/stripe-payment/callback/result?payment_intent=${payment_intent}`,
            ""
        ).then(({ data }) => data);
    }
    
    validateMerchanApplePay(validationURL) {
        return this.post(API_URL + "/api/ccavenue-payment/validate-merchan-apple-pay", {validationURL}).then(
            (response) => {
                return response.data;
            }
        );
    }

    processPaymentApplePay(data) {
        return this.post(API_URL + "/api/ccavenue-payment/process-payment-apple-pay", data).then(
            (response) => {
                return response.data;
            }
        );
    }

    getLastPaymentMethod() {
        return this.get(API_URL + `/api/user/get-last-payment-method`).then(({data}) => data);
    }

    paymentMBME(data) {
        return this.post(API_URL + "/api/mbme/purchase", data).then(
            (response) => {
                return response.data;
            }
        );
    }

    purchasePaymentUPI(data) {
        return this.post(API_URL + "/api/upi/purchase", data).then(
            (response) => {
                return response.data;
            }
        );
    }

    checkPendingUPI(data) {
        return this.post(API_URL + "/api/upi/check-pending", data).then(
            (response) => {
                return response.data;
            }
        );
    }
}

export default new PaymentService();
