<template>
    <div class="section-ticket">
        <div class="loading-full-page" v-if="isLoadingPage">
            <b-card>
                <b-skeleton animation="wave" width="85%"></b-skeleton>
                <b-skeleton animation="wave" width="55%"></b-skeleton>
                <b-skeleton animation="wave" width="70%"></b-skeleton>
            </b-card>
        </div>
        <template v-else>
            <DrawsType/>
            <div class="common-item">
                <img class="arrow-btn" v-if="isShowArrow" @click="onScrollItem()" loading="lazy" src="@/assets/images/tickets/arrow.svg" />
                <div @scroll="onScrollItems()" class="section-ticket__contents" id="list-item" :class="{'center': listBlockTickets.length === 1}">
                    <div
                        v-for="(item, i) in listBlockTickets"
                        :key="i"
                        :id="`quick-pick-${item.id}`"
                        class="section-ticket__contents__item"
                    >
                        <QuickPick
                            :title="`${
                                item.name || `${getTranslatedText('item')} ${i}`
                            }`"
                            :showTextSelected="false"
                            :lengthBLock="listBlockTickets.length"
                            :listNumber="listNumber"
                            :numbersChecked="item.numbersChecked"
                            :minimumBalls="minimum"
                            :class-selected-numbers="'quickPickTicketDesktop'"
                            :class-container="'ticketContainer'"
                            :indexTicket="i"
                            :action="true"
                            :dataAction="dataAction"
                            :showBtnRandom="false"
                            :showDate="true"
                            :isShowBgr="true"
                            :showIconRemove="true"
                            :maxiumBlue="maxiumBlue"
                            :maxiumGreen="maxiumGreen"
                            :isError="item.isError"
                            :showLine="true"
                            classQuickPick="full-action"
                            @handleRandomNumbers="handleRandomNumbers"
                            @handlePickNumber="handlePickNumber"
                            @onClickActionTicket="onClickActionTicket"
                            @onRemoveTicket="onClickConfirmModal"
                        >
                        </QuickPick>
                    </div>
                    <div class="exp">
                    <QuickPick
                            :title="`${getTranslatedText('item')} ${listBlockTickets.length + 1}`"
                            :showTextSelected="false"
                            :lengthBLock="listBlockTickets.length"
                            :listNumber="listNumber"
                            :minimumBalls="minimum"
                            :class-selected-numbers="'quickPickTicketDesktop'"
                            :class-container="'ticketContainer'"
                            :action="true"
                            :dataAction="dataAction"
                            :showBtnRandom="false"
                            :showDate="true"
                            :isShowBgr="true"
                            :showIconRemove="true"
                            :showExpItem="true"
                            :showLine="true"
                            classQuickPick="full-action"
                        >
                        </QuickPick>
                        <img class="plus-btn" @click="onAddTickets()" loading="lazy" src="@/assets/images/tickets/plus-btn.svg" />
                        <div class="disable-item"></div>
                        <div class="add-item-action">
                            <b-button
                                class="select-quantity__action-select-number-btn plus"
                                @click="onAddTickets()"
                            >
                                <div class="description">
                                    <img loading="lazy" src="@/assets/images/tickets/circle-minus.svg" />
                                    {{ getTranslatedText("Add Item for AED 25") }}
                                </div>
                            </b-button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="section-ticket__action__btn show-btn-mobile" id="action-personal">
                <ButtonCustom
                    classBtn="section-ticket__action__btn-quick-pick"
                    typeBtn="primary"
                    type="primary"
                    text="RANDOMIZE"
                    icon="shuffle-btn.png"
                    :isDisabled="isLoadingCheckout"
                    @onClickButton="handleQuickPickAll"
                />
                <ButtonCustom
                    classBtn="section-ticket__action__btn-back"
                    typeBtn="primary"
                    type="primary"
                    text="back"
                    :isDisabled="isLoadingCheckout"
                    @onClickButton="onGoBack"
                />
            </div>
            <div
                id="action-numbers"
                class="section-ticket__action scroll-top"
                :class="{
                    'header-action_rlt': $store.state.isRtl,
                }"
            >
                <div class="section-ticket__action__btn-left">
                    <ButtonCustom
                        classBtn="section-ticket__action__btn-back show-desktop"
                        typeBtn="primary"
                        type="primary"
                        text="back"
                        :isDisabled="isLoadingCheckout"
                        @onClickButton="onGoBack"
                    />
                    <ButtonCustom
                            classBtn="section-ticket__action__btn-quick-pick"
                            typeBtn="primary"
                            type="primary"
                            text="RANDOMIZE"
                            icon="shuffle-btn.png"
                            :isDisabled="isLoadingCheckout"
                            @onClickButton="handleQuickPickAll"
                        />
                </div>
                <div class="section-ticket__action__tickets">
                    <div class="section-ticket__action__tickets-quantity">
                        <span
                            class="section-ticket__action__tickets-quantity-label show-desktop"
                            >{{ getTranslatedText("select quantity") }}</span
                        >
                        <SelectQuantity @updateBlock="updateBlock" :isDisabelButton="isLoadingCheckout"/>
                    </div>
                    <ButtonCustom
                        classBtn="section-ticket__action__add-cart show-desktop"
                        classOption="btn-radius"
                        typeBtn="primary"
                        type="primary"
                        text="add to cart"
                        :isLoading="isLoadingCheckout"
                        :isHiddenValue="isLoadingCheckout"
                        @onClickButton="onClickCheckOut"
                    />
                    <div class="section-ticket__action__box-action show-mobile">
                        <ButtonCustom
                            classBtn="section-ticket__action__btn-back"
                            classOption="btn-radius color-red"
                            typeBtn="primary"
                            type="primary"
                            text="back"
                            iconLeft="arrow-left-white.png"
                            :isDisabled="isLoadingCheckout"
                            @onClickButton="onGoBack"
                        />
                        <ButtonCustom
                            classBtn="section-ticket__action__add-cart"
                            classOption="btn-radius"
                            typeBtn="primary"
                            type="primary"
                            text="continue"
                            icon="arrow-right-white.png"
                            :isHiddenValue="isLoadingCheckout"
                            :isLoading="isLoadingCheckout"
                            @onClickButton="onClickCheckOut"
                        />
                    </div>
                </div>
            </div>
        </template>

        <!-- select date -->
        <vue-bottom-sheet height="100%" ref="bottomSelectDate">
            <BottomSheet
                :title="getTranslatedText('select dates')"
                :description="
                    getTranslatedText(
                        'you can select multiple dates you wish to draw this entry'
                    )
                "
                classBtn="btn-success"
                type="primary"
                text="Done"
                icon="arrow-right.png"
                classBody="bottom_select_date"
                @onClickSheet="onFinishSelectDate"
            >
                <template slot="body-sheet" v-if="indexTicket !== -1">
                    <FormCommon
                        classContainer="form-select-date"
                        :formItem="formItemSelectDate"
                        :dataForm="listBlockTickets[indexTicket]"
                        @update-form-data="updateFormData"
                    />
                    <h1
                        class="line-select-date"
                        v-if="ENV_ENABLE_RECURRING !== 'no'"
                    >
                        {{ getTranslatedText("or") }}
                    </h1>
                    <div
                        class="select-date"
                        v-if="ENV_ENABLE_RECURRING !== 'no'"
                    >
                        <b-form-checkbox
                            id="check-automatically"
                            class="t-transform-cap check-automatically__custom"
                            @change="(value) => onChangeSelectAuto(value, true)"
                            v-model="
                                // eslint-disable-next-line vue/valid-v-model
                                listBlockTickets[indexTicket].automatically
                            "
                            name="automatically"
                        >
                            {{
                                getTranslatedText(
                                    "automatically enter weekly draw"
                                )
                            }}
                        </b-form-checkbox>
                    </div>
                </template>
            </BottomSheet>
        </vue-bottom-sheet>

        <!-- select Favorite -->
        <vue-bottom-sheet max-height="90%" ref="bottomFavorite">
            <BottomSheet
                :title="getTranslatedText('mark as favorite')"
                :description="
                    getTranslatedText('Give your favorite number a name')
                "
                :isLoadingButton="isLoadingBtn"
                :isHiddenBtn="true"
                classBody="bottom_favorite"
            >
                <template slot="body-sheet">
                    <div class="bottom-favorite">
                        <div
                            class="box-number-favorite t-transform-cap"
                            :style="cssPropsFavourite"
                        >
                            <h2 class="title-number">
                                {{ getTranslatedText("selected numbers") }}
                            </h2>
                            <div class="box-number-favorite__box-balls">
                                <div class="list-number-favorite">
                                    <div
                                        v-for="(
                                            item, index
                                        ) in dataFavorite.number"
                                        :class="`item-number-favorite ball-blue ${renderClass(
                                            item
                                        )}`"
                                        :key="index"
                                    >
                                        <span
                                            ><a>{{
                                                renderNumber(item)
                                            }}</a></span
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>
                        <FormCommon
                            :formItem="dataFavorite.formItem"
                            :dataForm="dataFavorite"
                            @update-form-data="updateDataFavorite"
                        />
                        <div class="box-number-favorite__box-button">
                            <ButtonCustom
                                typeBtn="button"
                                type="default"
                                classBtn="button-favorite cancel"
                                text="cancel"
                                @onClickButton="onCancelFavorite"
                            />
                            <ButtonCustom
                                typeBtn="button"
                                type="primary"
                                classBtn="button-favorite add"
                                :isLoading="isLoadingBtn"
                                text="add"
                                @onClickButton="onFinishFavorite"
                            />
                        </div>
                    </div>
                </template>
            </BottomSheet>
        </vue-bottom-sheet>
        <!-- select Number -->
        <vue-bottom-sheet max-height="90%" ref="bottomFavoriteNumber">
            <BottomSheet
                :title="getTranslatedText('pick your Favorite Numbers')"
                :description="
                    getTranslatedText(
                        'Here are your favorite numbers. Choose one for quick selection.'
                    )
                "
                :isHiddenBtn="true"
                classBody="no-padding bottom_favorite_number"
            >
                <template slot="body-sheet">
                    <div class="bottom-favorite-number">
                        <div class="box-number-favorite">
                            <b-form-group>
                                <b-form-radio-group
                                    v-model="selectedFavoriteNumber"
                                    stacked
                                >
                                    <div
                                        v-for="(
                                            item, index
                                        ) in dataFavoriteNumber"
                                        :key="index"
                                    >
                                        <div
                                            class="item-number-favorite"
                                            :key="index"
                                        >
                                            <b-form-radio
                                                class="radio-custom"
                                                :value="item.value"
                                                size="lg"
                                            >
                                                <div class="box-number">
                                                    <p class="name-number">
                                                        {{ item.name }}
                                                    </p>
                                                    <div class="list-number">
                                                        <div
                                                            :class="`item-number ${renderClass(
                                                                value
                                                            )}`"
                                                            v-for="(
                                                                value, i
                                                            ) in item.numbers"
                                                            :key="i"
                                                        >
                                                            <span>{{
                                                                renderNumber(
                                                                    value
                                                                )
                                                            }}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </b-form-radio>
                                        </div>
                                    </div>
                                </b-form-radio-group>
                            </b-form-group>
                        </div>
                        <div class="bottom-favorite-number__box-button">
                            <ButtonCustom
                                typeBtn="button"
                                type="default"
                                classBtn="button-favorite cancel"
                                text="cancel"
                                @onClickButton="onCancelFavoriteNumber"
                            />
                            <ButtonCustom
                                typeBtn="button"
                                type="primary"
                                classBtn="button-favorite use-number"
                                :isLoading="isLoadingBtn"
                                text="use number"
                                @onClickButton="onFinishFavoriteNumber"
                            />
                        </div>
                    </div>
                </template>
            </BottomSheet>
        </vue-bottom-sheet>

        <!-- modal notification register -->
        <!-- <b-modal
            id="modal-register-success"
            centered
            v-model="modalShow"
            :no-close-on-backdrop="false"
            :hide-header="true"
            :hide-footer="true"
            content-class="modal-no-padding"
            @hide="clickOutSideModal"
        >
            <div class="modal-confirm-register">
                <img loading="lazy"
                    src="@/assets/images/tickets/background-modal.png"
                    alt="background-modal"
                />
                <div class="body-modal">
                    <h1 class="title t-transform-cap">
                        {{ getTranslatedText("woohoo!") }}
                    </h1>
                    <p class="description t-transform-cap">
                        {{ getTranslatedText("account created successfully") }}
                    </p>
                    <ButtonCustom
                        classBtn="register-btn-success"
                        type="primary"
                        text="buy your green certificate"
                        @onClickButton="onHideModal"
                    />
                </div>
            </div>
        </b-modal> -->

        <!-- error limit -->
        <b-modal
            id="modal-error-limit"
            centered
            v-model="modalShowLimit"
            :no-close-on-backdrop="true"
            :hide-header="true"
            :hide-footer="true"
        >
            <div class="show-error-limit">
                <img loading="lazy" src="@/assets/images/tickets/error.png" alt="error" />
                <div class="body-modal">
                    <p class="description t-transform-cap">
                        {{
                            getTranslatedText(
                                "you have exceeded the maximum entries of"
                            )
                        }}
                        <span>100 {{ getTranslatedText("per week ") }}</span>
                    </p>
                    <ButtonCustom
                        classBtn="register-btn-success"
                        type="default"
                        text="cancel"
                        @onClickButton="onHideModalLimit"
                    />
                </div>
            </div>
        </b-modal>
        <ConfirmModal
            id="modal-confirm-remove-ticket"
            :title="getTranslatedText('delete ticket?')"
            :description="
                getTranslatedText(
                    'once the ticket is deleted cannot be recovered. Do you want to delete it?'
                )
            "
            :textBtnCancel="getTranslatedText('cancel')"
            :textBtnDelete="getTranslatedText('delete')"
            :isLoadingButton="isLoadingButtonConfirm"
            :showBtnCancel="true"
            @onCancelModal="onCancelRemove"
            @onDelete="onRemoveTicket"
        />
        <Toast :title="toastData.message" :type="toastData.type" />
        <v-tour
            name="myTour"
            v-click-outside="clickOutSideVTour"
            :steps="steps"
            :options="myOptions"
        >
            <template slot-scope="tour">
                <transition name="fade">
                    <v-step
                        v-if="tour.steps[tour.currentStep]"
                        :key="tour.currentStep"
                        :step="tour.steps[tour.currentStep]"
                        :previous-step="tour.previousStep"
                        :next-step="tour.nextStep"
                        :stop="tour.stop"
                        :skip="tour.skip"
                        :is-first="tour.isFirst"
                        :is-last="tour.isLast"
                        :labels="tour.labels"
                    >
                    </v-step>
                </transition>
            </template>
        </v-tour>
    </div>
</template>
<script>
import Toast from "@/components/common/toast/Toast.vue";
import gameService from "@/services/game.service";
import authService from "@/services/auth.service";
import languageMixin from "@/mixins/language/language-mixin";
import QuickPickMixin from "@/mixins/quick-pick/quick-pick-mixin";
import QuickPick from "@/components/common/quick-pick/QuickPick.vue";
import ButtonCustom from "@/components/common/button";
import BottomSheet from "@/components/common/bottom-sheet";
import FormCommon from "@/components/common/form";
import SelectQuantity from "@/components/common/select-quantity/SelectQuantityRegister";
import ConfirmModal from "@/components/common/confirm";
import DrawsType from "@/components/common/draws-type";
import moment from 'moment';

import {
  idRandom,
  isArrayEmpty,
    isStrEmpty,
    redirectPathApp,
    removeDouble,
    renderLocaleNumber,
    renderLocalePrice,
} from "@/components/common/utils";
import { getUserInfo } from "@/services/axios";

import EventBus from '@/components/common/EventBus'

const VUE_APP_MAXIUM_BALL = process.env.VUE_APP_MAXIUM_BALL;
const ENABLE_RECURRING = process.env.VUE_APP_ENABLE_RECURRING;
const CURRENCY_CODE = process.env.VUE_APP_CURRENCY_CODE;
const EXCHANGE_RATE = process.env.VUE_APP_EXCHANGE_RATE;
const VUE_APP_APP_DOMAIN = process.env.VUE_APP_APP_DOMAIN;
const IS_SHOW_CONSENT = process.env.VUE_APP_SHOW_CONSENT;

let scriptTag, scriptTag1
export default {
    mixins: [QuickPickMixin, languageMixin],
    components: {
        QuickPick,
        ButtonCustom,
        BottomSheet,
        FormCommon,
        SelectQuantity,
        Toast,
        ConfirmModal,
        DrawsType
    },
    data() {
        return {
            toastData: {
                message: "",
                type: "success",
            },
            ENV_ENABLE_RECURRING: ENABLE_RECURRING,
            currencyCode: CURRENCY_CODE,
            exchangeRate: EXCHANGE_RATE,
            domain: VUE_APP_APP_DOMAIN,
            isShowArrow: false,
            isShowConsent: IS_SHOW_CONSENT,
            myOptions: {
                useKeyboardNavigation: true,
                labels: {
                    buttonSkip: this.getTranslatedText("Skip"),
                    buttonPrevious: this.getTranslatedText("Pre"),
                    buttonNext: this.getTranslatedText("Next"),
                    buttonStop: this.getTranslatedText("Finish"),
                },
                highlight: false,
            },
            steps: [],
            cssPropsFavourite: {
                backgroundImage: `url(${require("@/assets/images/tickets/background-favourite.png")})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
            },
            isLoadingCheckout: false,
            isLoadingQuantity: false,
            isScroll: false,
            modalShow: false,
            isLanguageAr: false,
            modalShowLimit: false,
            isScrollElement: true,
            isLoadingButtonConfirm: false,
            listBlockCheckLimt: [],
            dataAction: [
                {
                    id: 1,
                    icon: "heart.png",
                },
                {
                    id: 3,
                    icon: "shuffle-random.png",
                },
                {
                    id: 2,
                    icon: "line-heart.png",
                },
            ],
            dataForm: {
                dataCheckDate: [],
            },
            timer: null,
            formItem: [
                {
                    type: "input",
                    typeInput: "text",
                    placeholder: "Name...",
                    name: "name",
                    required: false,
                    state: null,
                    textInvalid: "Please enter name",
                    label: "Enter Name",
                    for: "input-Name",
                    id: "input-Name",
                },
            ],
            isGoBackCheckout: false,
            isLoadingScroll: false,
            isWeeklyDefault: false
        };
    },
    mounted() {
        const parameters = this.$route.query;
        if(parameters?.weekly === 'true') {
            this.isWeeklyDefault = true
            this.$router.replace({'query': null});
        }
        this.checkDevice();
        window.addEventListener("scroll", this.onScroll);
        this.$root.$on("bv::dropdown::show", (bvEvent) => {
            if (bvEvent.target.closest(".section-header")) {
                this.isShow = false;
                this.isShowMenu = true;
            }
        });
        this.$root.$on("bv::dropdown::hide", (bvEvent) => {
            if (bvEvent.target.closest(".section-header")) {
                this.isShowMenu = false;
            }
        });
        if(!this.isLoadingPage) {
        const element = document.getElementById("list-item");

        element.onscroll ("scroll", (event) => {
        setTimeout(() => {
            // output.innerHTML = "Waiting on scroll events...";
        }, 1000);
        });}
        
    },
    updated() {
        if(!this.isLoadingPage) {
            let elm1 = document.getElementById("list-item")
            let elm2 = document.getElementById("action-numbers")
            let elm3 = document.getElementById("action-personal")
            if(window.screen.width <= 991 && window.screen.width > 500) {
                if(!localStorage.getItem('cookieConsent') && this.isShowConsent=== 'yes') {
                    elm1.style.marginBottom = "5px"
                    elm2.style.bottom = "140px"
                    elm3.style.marginBottom = "270px"
                } else {
                    elm1.style.marginBottom = "5px"
                    elm2.style.bottom = "0"
                    elm3.style.marginBottom = "125px"
                }
            } else if(window.screen.width <= 500) {
                if(!localStorage.getItem('cookieConsent') && this.isShowConsent=== 'yes') {
                    elm1.style.marginBottom = "5px"
                    elm2.style.bottom = "210px"
                    elm3.style.marginBottom = "315px"
                } else {
                    elm1.style.marginBottom = "5px"
                    elm2.style.bottom = "0"
                    elm3.style.marginBottom = "125px"
                }
            } else {
                if(!localStorage.getItem('cookieConsent') && this.isShowConsent=== 'yes') {
                    elm1.style.marginBottom = "240px"
                    elm2.style.bottom = "120px"
                } else {
                    elm1.style.marginBottom = "150px"
                    elm2.style.bottom = "0"
                }
            }
        }
        if (!isStrEmpty(this.$store.state.idEditCart && this.isScrollElement)) {
            this.onScrollTickets(this.$store.state.idEditCart);
            this.isGoBackCheckout = true
        }
    },
    beforeDestroy() {
        window.removeEventListener("scroll", this.onScroll);
    },
    created() {
        if(this.$route.query && this.$route.query.utm_source === 'omillioireapp') {
            let userInfo = getUserInfo();
            userInfo = !isStrEmpty(userInfo) ? JSON.parse(userInfo) : null;
            scriptTag1 = document.createElement('script');
            scriptTag1.textContent = `
                window.dataLayer = window.dataLayer || [];
                dataLayer.push({
                    'event': 'sign_in',
                    'login_date': '${this.$route.query.login_date || "N/A"}',
                    'method': '${this.$route.query.method || "N/A"}',
                    'user_id': ${userInfo.id},
                    'calling_code': '${userInfo.calling_code || "N/A"}',
                    'mobile': '+${userInfo.phone || "N/A"}',
                    'email': '${userInfo.email || "NA"}'
                });
            `;
            document.head.appendChild(scriptTag1);
        }
        if (localStorage.getItem("isRegister")) {
            let number_index = localStorage.getItem("number_index");
            this.listBlockTickets[0].numbersChecked = !isStrEmpty(number_index)
                ? JSON.parse(number_index)
                : [];
            this.onShowModal();
            localStorage.removeItem("isRegister");
            localStorage.removeItem("number_index");
        }
    },
    // metaInfo() {
    //     return {
    //         title: "Match Your 7 Lucky Numbers for O! Millionaire's AED 200 million Raffle Draw",
    //         meta: [
    //             {
    //                 vmid: "description",
    //                 name: "description",
    //                 content: 'Get a Green Certificate with OMillionaire and boost your winnings to AED 200 million. Grand Prize increases by AED 500,000 weekly!'
    //             }
    //         ]
    //     };
    // },
    methods: {
        onScrollItems() {
            const scroller = document.querySelector("#list-item");
            if(scroller.scrollLeft > 0) {
                this.isShowArrow = true
            } else {
                this.isShowArrow = false
            }
        },
        onScrollItem() {
            const scroller = document.querySelector("#list-item");
            scroller.scrollLeft -= 120
        },
        clickOutSideVTour() {
            if (this.modalShow === false) {
                this.$tours["myTour"].stop();
            }
        },
        showToast(message, type) {
            this.toastData.message = message && this.getTranslatedText(message);
            this.toastData.type = type;
            this.$bvToast.show("my-toast");
        },
        checkDevice() {
            let locale = localStorage.getItem("locale");
            if (locale === "ar") {
                this.isLanguageAr = true;
                if (
                    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                        navigator.userAgent
                    )
                ) {
                    this.steps = [
                        {
                            target: ".section-ticket__contents__item",
                            header: {
                                title: this.getTranslatedText("Your Entry"),
                            },
                            content: `${this.getTranslatedText(
                                "Your first entry has been prefilled with the numbers that you have already selected. you can choose to change your selection here."
                            )} <div class="section-tour__numbers">1/8</div> <div class="section-tour__button section-tour__button-pre__arabic">${this.getTranslatedText(
                                "Pre"
                            )}</div>`,
                            params: {
                                placement: "top",
                                enableScrolling: false,
                            },
                        },
                        {
                            target: ".select-quantity__select__numbers__plus",
                            header: {
                                title: this.getTranslatedText("Add Entries"),
                            },
                            content: `${this.getTranslatedText(
                                "You can add more entries"
                            )} <div class="section-tour__numbers">2/8</div>`,
                            params: {
                                placement: "top",
                                enableScrolling: false,
                            },
                        },
                        {
                            target: ".select-quantity__select__numbers__minus",
                            header: {
                                title: this.getTranslatedText("Delete Entries"),
                            },
                            content: `${this.getTranslatedText(
                                "You can delete entries"
                            )} <div class="section-tour__numbers">3/8</div>`,
                            params: {
                                placement: "top",
                                enableScrolling: false,
                            },
                        },
                        {
                            target: ".price",
                            header: {
                                title: this.getTranslatedText(
                                    "Cost of entries"
                                ),
                            },
                            content: `${this.getTranslatedText(
                                "This will indicate the total amount based on the number of entries selected"
                            )} <div class="section-tour__numbers">4/8</div>`,
                            params: {
                                placement: "top",
                                enableScrolling: false,
                            },
                        },
                        {
                            target: ".select-quantity__buttons__quick-pick",
                            header: {
                                title: this.getTranslatedText("Quick Pick"),
                            },
                            content: `${this.getTranslatedText(
                                "Use this to randomly select your 7 numbers for all of your entries"
                            )} <div class="section-tour__numbers">5/8</div>`,
                            params: {
                                placement: "top",
                                enableScrolling: false,
                            },
                        },
                        {
                            target: ".quick-pick__item-favorite",
                            header: {
                                title: this.getTranslatedText("Favorite"),
                            },
                            content: `${this.getTranslatedText(
                                "Use this to add your selected numbers to your favorite list"
                            )} <div class="section-tour__numbers">6/8</div>`,
                            params: {
                                placement: "top",
                                enableScrolling: false,
                            },
                        },
                        {
                            target: ".quick-pick__item-favorite-list",
                            header: {
                                title: this.getTranslatedText("Favorite List"),
                            },
                            content: `${this.getTranslatedText(
                                "You can select your numbers from a list of preset favorite numbers"
                            )} <div class="section-tour__numbers">7/8</div>`,
                            params: {
                                placement: "top",
                                enableScrolling: false,
                            },
                        },
                        {
                            target: ".quick-pick__item-quick-pick",
                            header: {
                                title: this.getTranslatedText(
                                    "Random Selection"
                                ),
                            },
                            content: `${this.getTranslatedText(
                                "Select your 7 numbers randomly for this entry"
                            )} <div class="section-tour__numbers">8/8</div> <div class="section-tour__button section-tour__button-next__arabic">${this.getTranslatedText(
                                "Next"
                            )}</div>`,
                            params: {
                                placement: "top",
                                enableScrolling: false,
                            },
                        },
                    ];
                } else {
                    this.steps = [
                        {
                            target: ".section-ticket__contents__item",
                            header: {
                                title: this.getTranslatedText("Your Entry"),
                            },
                            content: `${this.getTranslatedText(
                                "Your first entry has been prefilled with the numbers that you have already selected. you can choose to change your selection here."
                            )} <div class="section-tour__numbers">1/8</div> <div class="section-tour__button section-tour__button-pre__arabic">${this.getTranslatedText(
                                "Pre"
                            )}</div>`,
                            params: {
                                placement: "right",
                                enableScrolling: false,
                            },
                        },
                        {
                            target: ".select-quantity__desktop__select__numbers__plus",
                            header: {
                                title: this.getTranslatedText("Add Entries"),
                            },
                            content: `${this.getTranslatedText(
                                "You can add more entries"
                            )} <div class="section-tour__numbers">2/8</div>`,
                            params: {
                                placement: "top",
                                enableScrolling: false,
                            },
                        },
                        {
                            target: ".select-quantity__desktop__select__numbers__minus",
                            header: {
                                title: this.getTranslatedText("Delete Entries"),
                            },
                            content: `${this.getTranslatedText(
                                "You can delete entries"
                            )} <div class="section-tour__numbers">3/8</div>`,
                            params: {
                                placement: "top",
                                enableScrolling: false,
                            },
                        },
                        {
                            target: ".price",
                            header: {
                                title: this.getTranslatedText(
                                    "Cost of entries"
                                ),
                            },
                            content: `${this.getTranslatedText(
                                "This will indicate the total amount based on the number of entries selected"
                            )} <div class="section-tour__numbers">4/8</div>`,
                            params: {
                                placement: "top",
                                enableScrolling: false,
                            },
                        },
                        {
                            target: ".select-quantity__desktop__buttons__quick-pick",
                            header: {
                                title: this.getTranslatedText("Quick Pick"),
                            },
                            content: `${this.getTranslatedText(
                                "Use this to randomly select your 7 numbers for all of your entries"
                            )} <div class="section-tour__numbers">5/8</div>`,
                            params: {
                                placement: "top",
                                enableScrolling: false,
                            },
                        },
                        {
                            target: ".quick-pick__item-favorite",
                            header: {
                                title: this.getTranslatedText("Favorite"),
                            },
                            content: `${this.getTranslatedText(
                                "Use this to add your selected numbers to your favorite list"
                            )} <div class="section-tour__numbers">6/8</div>`,
                            params: {
                                placement: "top",
                                enableScrolling: false,
                            },
                        },
                        {
                            target: ".quick-pick__item-favorite-list",
                            header: {
                                title: this.getTranslatedText("Favorite List"),
                            },
                            content: `${this.getTranslatedText(
                                "You can select your numbers from a list of preset favorite numbers"
                            )} <div class="section-tour__numbers">7/8</div>`,
                            params: {
                                placement: "top",
                                enableScrolling: false,
                            },
                        },
                        {
                            target: ".quick-pick__item-quick-pick",
                            header: {
                                title: this.getTranslatedText(
                                    "Random Selection"
                                ),
                            },
                            content: `${this.getTranslatedText(
                                "Select your 7 numbers randomly for this entry"
                            )} <div class="section-tour__numbers">8/8</div> <div class="section-tour__button section-tour__button-next__arabic">${this.getTranslatedText(
                                "Next"
                            )}</div>`,
                            params: {
                                placement: "top",
                                enableScrolling: false,
                            },
                        },
                    ];
                }
            } else {
                this.isLanguageAr = false;
                if (
                    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                        navigator.userAgent
                    )
                ) {
                    this.steps = [
                        {
                            target: ".section-ticket__contents__item",
                            header: {
                                title: this.getTranslatedText("Your Entry"),
                            },
                            content: `${this.getTranslatedText(
                                "Your first entry has been prefilled with the numbers that you have already selected. you can choose to change your selection here."
                            )} <div class="section-tour__numbers">1/8</div> <div class="section-tour__button-pre section-tour__button">${this.getTranslatedText(
                                "Pre"
                            )}</div>`,
                            params: {
                                placement: "top",
                                enableScrolling: false,
                            },
                        },
                        {
                            target: ".select-quantity__select__numbers__plus",
                            header: {
                                title: this.getTranslatedText("Add Entries"),
                            },
                            content: `${this.getTranslatedText(
                                "You can add more entries"
                            )} <div class="section-tour__numbers">2/8</div>`,
                            params: {
                                placement: "top",
                                enableScrolling: false,
                            },
                        },
                        {
                            target: ".select-quantity__select__numbers__minus",
                            header: {
                                title: this.getTranslatedText("Delete Entries"),
                            },
                            content: `${this.getTranslatedText(
                                "You can delete entries"
                            )} <div class="section-tour__numbers">3/8</div>`,
                            params: {
                                placement: "top",
                                enableScrolling: false,
                            },
                        },
                        {
                            target: ".price",
                            header: {
                                title: this.getTranslatedText(
                                    "Cost of entries"
                                ),
                            },
                            content: `${this.getTranslatedText(
                                "This will indicate the total amount based on the number of entries selected"
                            )} <div class="section-tour__numbers">4/8</div>`,
                            params: {
                                placement: "top",
                                enableScrolling: false,
                            },
                        },
                        {
                            target: ".select-quantity__buttons__quick-pick",
                            header: {
                                title: this.getTranslatedText("Quick Pick"),
                            },
                            content: `${this.getTranslatedText(
                                "Use this to randomly select your 7 numbers for all of your entries"
                            )} <div class="section-tour__numbers">5/8</div>`,
                            params: {
                                placement: "top",
                                enableScrolling: false,
                            },
                        },
                        {
                            target: ".quick-pick__item-favorite",
                            header: {
                                title: this.getTranslatedText("Favorite"),
                            },
                            content: `${this.getTranslatedText(
                                "Use this to add your selected numbers to your favorite list"
                            )} <div class="section-tour__numbers">6/8</div>`,
                            params: {
                                placement: "top",
                                enableScrolling: false,
                            },
                        },
                        {
                            target: ".quick-pick__item-favorite-list",
                            header: {
                                title: this.getTranslatedText("Favorite List"),
                            },
                            content: `${this.getTranslatedText(
                                "You can select your numbers from a list of preset favorite numbers"
                            )} <div class="section-tour__numbers">7/8</div>`,
                            params: {
                                placement: "top",
                                enableScrolling: false,
                            },
                        },
                        {
                            target: ".quick-pick__item-quick-pick",
                            header: {
                                title: this.getTranslatedText(
                                    "Random Selection"
                                ),
                            },
                            content: `${this.getTranslatedText(
                                "Select your 7 numbers randomly for this entry"
                            )} <div class="section-tour__numbers">8/8</div> <div class="section-tour__button-next section-tour__button">${this.getTranslatedText(
                                "Next"
                            )}</div>`,
                            params: {
                                placement: "top",
                                enableScrolling: false,
                            },
                        },
                    ];
                } else {
                    this.steps = [
                        {
                            target: ".section-ticket__contents__item",
                            header: {
                                title: this.getTranslatedText("Your Entry"),
                            },
                            content: `${this.getTranslatedText(
                                "Your first entry has been prefilled with the numbers that you have already selected. you can choose to change your selection here."
                            )} <div class="section-tour__numbers">1/8</div> <div class="section-tour__button-pre section-tour__button">${this.getTranslatedText(
                                "Pre"
                            )}</div>`,
                            params: {
                                placement: "right",
                                enableScrolling: false,
                            },
                        },
                        {
                            target: ".select-quantity__desktop__select__numbers__plus",
                            header: {
                                title: this.getTranslatedText("Add Entries"),
                            },
                            content: `${this.getTranslatedText(
                                "You can add more entries"
                            )} <div class="section-tour__numbers">2/8</div>`,
                            params: {
                                placement: "top",
                                enableScrolling: false,
                            },
                        },
                        {
                            target: ".select-quantity__desktop__select__numbers__minus",
                            header: {
                                title: this.getTranslatedText("Delete Entries"),
                            },
                            content: `${this.getTranslatedText(
                                "You can delete entries"
                            )} <div class="section-tour__numbers">3/8</div>`,
                            params: {
                                placement: "top",
                                enableScrolling: false,
                            },
                        },
                        {
                            target: ".price",
                            header: {
                                title: this.getTranslatedText(
                                    "Cost of entries"
                                ),
                            },
                            content: `${this.getTranslatedText(
                                "This will indicate the total amount based on the number of entries selected"
                            )} <div class="section-tour__numbers">4/8</div>`,
                            params: {
                                placement: "top",
                                enableScrolling: false,
                            },
                        },
                        {
                            target: ".select-quantity__desktop__buttons__quick-pick",
                            header: {
                                title: this.getTranslatedText("Quick Pick"),
                            },
                            content: `${this.getTranslatedText(
                                "Use this to randomly select your 7 numbers for all of your entries"
                            )} <div class="section-tour__numbers">5/8</div>`,
                            params: {
                                placement: "top",
                                enableScrolling: false,
                            },
                        },
                        {
                            target: ".quick-pick__item-favorite",
                            header: {
                                title: this.getTranslatedText("Favorite"),
                            },
                            content: `${this.getTranslatedText(
                                "Use this to add your selected numbers to your favorite list"
                            )} <div class="section-tour__numbers">6/8</div>`,
                            params: {
                                placement: "top",
                                enableScrolling: false,
                            },
                        },
                        {
                            target: ".quick-pick__item-favorite-list",
                            header: {
                                title: this.getTranslatedText("Favorite List"),
                            },
                            content: `${this.getTranslatedText(
                                "You can select your numbers from a list of preset favorite numbers"
                            )} <div class="section-tour__numbers">7/8</div>`,
                            params: {
                                placement: "top",
                                enableScrolling: false,
                            },
                        },
                        {
                            target: ".quick-pick__item-quick-pick",
                            header: {
                                title: this.getTranslatedText(
                                    "Random Selection"
                                ),
                            },
                            content: `${this.getTranslatedText(
                                "Select your 7 numbers randomly for this entry"
                            )} <div class="section-tour__numbers">8/8</div> <div class="section-tour__button-next section-tour__button">${this.getTranslatedText(
                                "Next"
                            )}</div>`,
                            params: {
                                placement: "top",
                                enableScrolling: false,
                            },
                        },
                    ];
                }
            }
        },
        onScrollTickets(id, isPlus) {
            let ticketsLength = this.listBlockTickets.length;

            if (ticketsLength < parseInt(VUE_APP_MAXIUM_BALL)) {
                if (
                    (window.screen.width > 767 && ticketsLength > 2) ||
                    window.screen.width <= 767
                ) {
                    const element = document.getElementById(`quick-pick-${id}`);

                    let offsetTopMore =
                        window.screen.width > 767
                            ? this.isScroll
                                ? 20
                                : 220
                            : this.isScroll
                            ? 0
                            : 60;
                    let offsetTopMiniusMore =
                        window.screen.width > 767 ? 20 : 40;

                    const y = isPlus
                        ? element.offsetTop - offsetTopMore
                        : element.offsetTop - offsetTopMiniusMore;

                    window.scroll({
                        top: y,
                        behavior: "smooth",
                    });
                    this.$store.dispatch("onChangeIdEditCheckoutCart", "");
                    this.isScrollElement = false;
                }
            }
        },

        // on remove ticket
        onClickConfirmModal(index) {
            this.indexTicket = index;
            this.$bvModal.show("modal-confirm-remove-ticket");
        },
        onCancelRemove() {
            this.$bvModal.hide("modal-confirm-remove-ticket");
            this.indexTicket = -1;
        },

        onAddTickets() {
            EventBus.$emit("onAddTicketNumber");
        },

        // finish remove ticket
        finishRemoveTicket(listBlock) {
            this.listBlockTickets = listBlock
            localStorage.setItem("list_block", JSON.stringify(listBlock));
            this.indexTicket = -1;``
            this.$bvModal.hide("modal-confirm-remove-ticket");
            this.isLoadingButtonConfirm = false;
            EventBus.$emit('onRemoveTicket', listBlock);
            this.showToast("Delete Successfully", "success");
        },

        onRemoveTicket() {
            if (this.indexTicket !== -1) {
                this.isLoadingButtonConfirm = true;
                this.numberQuickPick--;
                let listBlockTicketsClone = [...this.listBlockTickets]
                let listBlockLocal = []
                listBlockTicketsClone.splice(this.indexTicket, 1);


                if(!isArrayEmpty(listBlockTicketsClone)) {
                    listBlockLocal = listBlockTicketsClone.map((x, i) => {
                        let item = {
                            ...x,
                            name: `Item ${i + 1}`,
                        };
                        return {
                            ...item,
                        };
                    });

                    this.finishRemoveTicket(listBlockLocal)
                } else {
                    let date = !isArrayEmpty(this.listGames) ? [this.listGames[0].event_id] : [];
                    let valueDate = !isArrayEmpty(this.listGames) ? this.listGames[0].draw_date : "";

                    let item = {
                        id: idRandom(),
                        name: `Item 1`,
                        numbersChecked: [],
                        priceMore: 2.5,
                        date,
                        valueDate,
                        isError: false,
                        automatically: false,
                        doubles: [],
                    };

                    listBlockLocal.push(item);
                    this.finishRemoveTicket(listBlockLocal)

                }
            }
        },

        updateBlock(listBlockLocal) {
            this.listBlockTickets = listBlockLocal;
            localStorage.setItem("list_block", JSON.stringify(listBlockLocal));
            if(this.isLoadingScroll) {
                this.timer && clearTimeout(this.timer);
                    this.timer = setTimeout(() => {
                    this.onScrollTickets(
                        this.listBlockTickets[listBlockLocal.length - 1].id
                    );
                }, 500);
            } else {
                this.isLoadingScroll = true
            }


        },

        async callCheckLimit(id, listBlockCheckLimt, isHiddenScroll) {
            try {
                if (!isHiddenScroll) {
                    this.onScrollTickets(id, true);
                }
                this.isLoadingQuantity = true;
                let userInfo = getUserInfo();
                userInfo = !isStrEmpty(userInfo) ? JSON.parse(userInfo) : null;

                if (userInfo) {
                    const resp = await gameService.checkLimitGame({
                        user_id: userInfo?.id,
                        quantity: this.numberQuickPick,
                    });

                    if (
                        !resp.data.status ||
                        this.numberQuickPick === parseInt(VUE_APP_MAXIUM_BALL)
                    ) {
                        const listRemove = removeDouble(
                            this.listBlockTickets,
                            listBlockCheckLimt,
                            "id"
                        );
                        this.listBlockTickets = listRemove;
                        this.numberQuickPick = listRemove.length;
                        this.listBlockCheckLimt = [];
                        let listBlockTickets = JSON.stringify(listRemove);
                        localStorage.setItem("list_block", listBlockTickets);
                        this.modalShowLimit = true;
                        this.isLoadingQuantity = false;
                        return false;
                    } else {
                        this.listBlockCheckLimt = [];
                    }

                    this.isLoadingQuantity = false;
                } else {
                    if (
                        this.numberQuickPick === parseInt(VUE_APP_MAXIUM_BALL)
                    ) {
                        this.modalShowLimit = true;
                    }
                    this.listBlockCheckLimt = [];
                    this.isLoadingQuantity = false;
                }
            } catch (error) {
                console.log(error);
            }
        },

        onScroll(e) {
            const currentScrollPosition =
                window.pageYOffset || document.documentElement.scrollTop;
            this.isScroll =
                window.screen.width > 769
                    ? currentScrollPosition > 100 &&
                      this.listBlockTickets.length > 2
                    : currentScrollPosition > 100;
        },

        // show modal confirm register
        onShowModal() {
            this.modalShow = true;
        },

        clickOutSideModal() {
            this.startTutorial();
        },
        onHideModal() {
            this.modalShow = false;
            this.startTutorial();
        },

        async startTutorial() {
            await this.checkDevice();
            this.$tours["myTour"].start();
        },

        onHideModalLimit() {
            this.modalShowLimit = false;
        },

        async onClickCheckOut() {
            this.isLoadingCheckout = true;
            let listBlockTickets = this.listBlockTickets;

            let isReturn = [];
            let amount = 0;

            let newDate = new Date();
            let items = []
            let drawNumbers = []
            listBlockTickets.map((item) => {
                let quantity = item.automatically ? 1 : item.date.length;
                amount += quantity;
                let indexIsReturn = isReturn.findIndex((x) => x === item.id);
                if (
                    item.numbersChecked.length < this.minimum ||
                    (item.date.length === 0 && item.automatically !== true)
                ) {
                    if (indexIsReturn === -1) {
                        item["isError"] = true;
                        isReturn.push(item.id);
                    }
                } else if (
                    newDate > new Date(item.valueDate) &&
                    this.listGameLotto.length > 0
                ) {
                    item["valueDate"] = this.listGameLotto[0].cutoff;
                    item["date"] = [this.listGameLotto[0].event_id];
                    item["isError"] = false;
                } else {
                    item["isError"] = false;
                }
                item.date.map((value, i) => {
                    this.listGameLotto.map((data, idx) => {
                        if (data.event_id === value) {
                            let production = {
                                draw_date: moment(data.draw_date).format('YYYY-MM-DD'),
                                draw_number: item.numbersChecked.join(' ')
                            }
                            drawNumbers.push(...item.numbersChecked)
                            items.push(production)
                        }
                    })
                })
            });

            if (isReturn.length > 0) {
                this.onScrollTickets(isReturn[0], true);
                this.isLoadingCheckout = false;
                return false;
            }

            if (this.$store.state.isLoggedIn) {
                try {
                    let param = {
                        cart_data: listBlockTickets,
                        items: 1,
                        amount,
                    };
                    const resp = await authService.saveListCart(param);
                    if (resp.status === 200) {
                        localStorage.setItem(
                            "list_block_tmp",
                            JSON.stringify(listBlockTickets)
                        );
                        localStorage.setItem(
                            "list_block",
                            JSON.stringify(listBlockTickets)
                        );
                        this.$store.dispatch(
                            "onChangeAnountCart",
                            parseInt(amount)
                        );
                        this.listBlockTickets = listBlockTickets;
                        localStorage.setItem("total_items", amount);
                        let checkUrl = this.isWeeklyDefault ? '/checkout?weekly=true' : '/checkout'
                        if (this.$store.state.is_app) {
                            redirectPathApp(checkUrl);
                        } else {
                            this.$router.push(checkUrl);
                        }

                        this.isLoadingCheckout = false;
                    }
                } catch (error) {
                    console.log(error);
                }
            } else {
                localStorage.setItem(
                    "list_block_tmp",
                    JSON.stringify(listBlockTickets)
                );
                localStorage.setItem("total_items", amount);
                this.$store.dispatch("onChangeAnountCart", parseInt(amount));
                let checkUrl = this.isWeeklyDefault ? '/checkout?weekly=true' : '/checkout'
                if (this.$store.state.is_app) {
                    redirectPathApp(checkUrl);
                } else {
                    this.$router.push(checkUrl);
                }
                this.isLoadingCheckout = false;
            }
            scriptTag = document.createElement('script');
            scriptTag.textContent = `
                window.dataLayer = window.dataLayer || [];
                dataLayer.push({
                    'event': 'Add To Cart',
                    'prqt': ${items.length},
                    'currency': '${this.currencyCode}',
                    'amount': ${parseFloat(2.5 * this.exchangeRate * this.listBlockTickets.length).toFixed(2)},
                    'prod_url': '${this.domain + '/img/green-cer.ec7f9573.webp'}',
                    'date_draw': '${items[0].draw_date}',
                    'draw_number': '${drawNumbers.join(', ')}',
                    'items': ${JSON.stringify(items)}
                });
            `;
            document.head.appendChild(scriptTag);
        },

        renderClass(number) {
            const className =
                number > this.maxiumBlue && number <= this.maxiumGreen
                    ? "ball-blue"
                    : "ball-green";

            return className;
        },

        onGoBack() {
            localStorage.setItem("list_block", JSON.stringify(this.listBlockTickets));
            this.$router.push(this.isGoBackCheckout ? "/checkout" : "/buy/green-certificates");
        },

        renderNumberLocale(number) {
            return renderLocaleNumber(number);
        },

        formatLocalePrice(number) {
            return renderLocalePrice(number);
        },

        renderNumber(number) {
            return renderLocaleNumber(number);
        },
    },
    beforeDestroy() {
      function removeScript(script) {
          if (script && script.parentNode) {
              script.parentNode.removeChild(script);
          }
      }
      removeScript(scriptTag);
      removeScript(scriptTag1);
    }
};
</script>

<style scoped lang="scss" src="@/assets/scss/mobile/tickets/_tickets.scss" />
<style scoped lang="scss" src="@/assets/scss/mobile/tutorial/tutorial.scss" />
