import { RouteConfig } from "vue-router";
import { listUrlBuyNow } from "@/resources/urlBuyNow";

import Home from "@/views/mobile/home/index.vue";
import Tickets from "@/views/mobile/tickets/index.vue";
import Checkout from "@/views/mobile/checkout/index.vue";
import CheckoutIframe from "@/views/mobile/checkout/CheckoutIframe.vue";
import Notify from "@/views/mobile/notify/index.vue";
import Inspiring from "@/views/mobile/inspiring/Inspiring.vue";
import AboutUs from "@/views/mobile/static-pages/AboutUs.vue";
// import Green from "@/views/mobile/static-pages/Green.vue";
import HowTo from "@/views/mobile/static-pages/HowTo.vue";
import TermCondition from "@/views/mobile/static-pages/TermCondition.vue";
import News from "@/views/mobile/static-pages/News.vue";
import Draws from "@/views/mobile/static-pages/Draws.vue";
import ListDraws from "@/views/mobile/static-pages/ListDraws.vue";
import PastDraws from "@/views/mobile/static-pages/PastDraws.vue";
import Winners from "@/views/mobile/static-pages/ListWinners.vue";
import ContactUs from "@/views/mobile/static-pages/ContactUs.vue";
import OasisPark from "@/views/mobile/static-pages/OasisPark.vue";
import LandingPage from "@/views/mobile/landing-page/index.vue";
import BuyNow from "@/views/mobile/buy-now/index.vue";
import Results from "@/views/mobile/static-pages/Results.vue";
import Shop from "@/views/mobile/static-pages/Shop.vue";
import LiveWatch from "@/views/mobile/live-watch/index.vue";
import PrizeBreakdown from "@/views/mobile/prize-breakdown/index.vue";
import BrandAmbassador from "@/views/mobile/static-pages/BrandAmbassador.vue";
import Subscribe from "@/views/mobile/subscribe/index.vue";
import VerifyEmail from "@/views/mobile/verify-email/index.vue";

const publicRoutes: Array<RouteConfig> = [
    {
        path: "/",
        name: "LandingPage",
        meta: { isShowFooter: true, isChangeBgColor: true },
        component: LandingPage,
    },
    {
        path: "/numbers",
        name: "Tickets",
        meta: { isSetStyle: true, isChangeBgColor: true, isHideFloating: true },
        component: Tickets,
    },
    {
        path: "/home",
        redirect: { name: 'Tickets' }
    },
    {
        path: "/buy/green-certificates",
        name: "BuyNow",
        meta: { isShowFooter: true, isChangeBgColor:true },
        component: BuyNow,
    },
    {   
        path: '/buy/green-ce',
        alias: listUrlBuyNow,
        name: "BuyNow",
        meta: { isShowFooter: true, isChangeBgColor:true },
        beforeEnter: () => {
            window.location.href = '/buy/green-certificates';
        },
        component: BuyNow,
    },
    {
        path: "/checkout",
        name: "Checkout",
        meta: { isSetStyle: true, isChangeBgColor:true, isHideFloating: true },
        component: Checkout,
    },
    // {
    //     path: "/payment",
    //     name: "CheckoutIframe",
    //     meta: { isChangeBgColor:true },
    //     component: CheckoutIframe,
    // },
    {
        path: "/notify",
        name: "Notify",
        component: Notify,
    },
    {
        path: "/thank-you",
        name: "Inspiring",
        meta: { isShowFooter: true, isChangeBgColor: true, isHideConsent: true },
        component: Inspiring,
    },
    {
        path: "/about-us",
        name: "AboutUs",
        meta: { isShowFooter: true, isChangeBgColor: true },
        component: AboutUs,
    },
    // {
    //     path: "/green",
    //     name: "Green",
    //     meta: { isShowFooter: true },
    //     component: Green,
    // },
    {
        path: "/faq",
        name: "HowTo",
        meta: { isShowFooter: true, isChangeBgColor: true },
        component: HowTo,
    },
    {
        path: "/how-to",
        name: "HowTo",
        meta: { isShowFooter: true, isChangeBgColor: true },
        beforeEnter: () => {
            window.location.href = '/faq';
        },
        component: HowTo,
    },
    {
        path: "/terms-and-conditions",
        name: "TermConditions",
        meta: { isShowFooter: true, hasPaddingTop:true },
        component: TermCondition,
    },
    {
        path: "/term-conditions",
        alias: ['/term-conditions', '/Turn'],
        name: "TermConditions",
        meta: { isShowFooter: true, hasPaddingTop:true },
        beforeEnter: () => {
            window.location.href = '/terms-and-conditions';
        },
        component: TermCondition,
    },
    {
        path: "/draws",
        name: "Draws",
        meta: { isShowFooter: true, isChangeBgColor: true },
        component: Draws,
    },
    {
        path: "/list-draws",
        name: "ListDraws",
        meta: { hasPaddingTop:true },
        component: ListDraws,
    },
    {
        path: "/past-draws",
        meta: { isShowFooter: true, hasPaddingTop:true },
        name: "PastDraws",
        component: PastDraws,
    },
    // {
    //     path: "/winners",
    //     name: "Winners",
    //     component: Winners,
    // },
    {
        path: "/news",
        name: "News",
        meta: { isShowFooter: true },
        beforeEnter: () => {
            window.location.href = 'https://omillionaire-mediacenter.com/';
        },
        component: News,
    },
    {
        path: "/contact-us",
        name: "ContactUs",
        meta: { isShowFooter: true, isChangeBgColor: true },
        component: ContactUs,
    },
    {
        path: "/oasispark",
        alias: ['/oasis-park'],
        name: "OasisPark",
        meta: { isShowFooter: true },
        beforeEnter: () => {
            window.location.href = 'https://oasispark.org/';
        },
        component: OasisPark,
    },
    {
        path: "/results",
        name: "Results",
        meta: { isShowFooter: true },
        beforeEnter: () => {
            window.location.href = 'https://omillionaireresults.com/';
        },
        component: Results,
    },
    {
        path: "/shop",
        name: "Shop",
        meta: { isShowFooter: true },
        beforeEnter: () => {
            window.location.href = 'https://omillionaire.shop/';
        },
        component: Shop,
    },
    {
        path: "/watch-live",
        name: "LiveWatch",
        meta: { isShowFooter: true, isChangeBgColor: true },
        component: LiveWatch,
    },
    {
        path: "/prize-breakdown",
        name: "PrizeBreakdown",
        meta: { isShowFooter: true, isChangeBgColor: true },
        component: PrizeBreakdown,
    },
    {
        path: "/brand-ambassador",
        name: "BrandAmbassador",
        meta: { isShowFooter: true, isChangeBgColor: true },
        component: BrandAmbassador,
    },
    // {
    //     path: "/subscribe",
    //     name: "Subscribe",
    //     meta: { isShowFooter: true, isChangeBgColor: true },
    //     component: Subscribe,
    // },
    {
        path: "/confirm/verify-email",
        name: "VerifyEmail",
        component: VerifyEmail,
    },
];

export default publicRoutes;
