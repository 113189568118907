<template>
    <div class="thank_you" id="inspiring-id">
        <div class="inspiring">
            <div class="loading-full-page" v-if="isLoadingPage">
                <b-card>
                    <b-skeleton animation="wave" width="85%"></b-skeleton>
                    <b-skeleton animation="wave" width="55%"></b-skeleton>
                    <b-skeleton animation="wave" width="70%"></b-skeleton>
                </b-card>
            </div>
            <template v-else>
                <BannerCustom title="CONGRATULATIONS!"/>
                <div class="title-ins">{{ getTranslatedText('Purchase successful') }}</div>
                <div class="inspiring__content" :class="{'show-one-item' : listTickets.length === 1}">
                    <div class="inspiring__item_wrap" v-for="(item, index) in listTickets" :key="index">
                        <div class="inspiring__item">
                         <div class="inspiring__item--number">
                            <span>{{ getTranslatedText('My Numbers') }}</span>
                            <ul>
                                <li v-for="(number, index) in item.balls" :key="index" :class="`${renderClass(number, index)}`"><span>{{ renderNumberLocale(number) }}</span></li>
                            </ul>
                            <div class="inspiring__item--draw">
                                <span>{{ getTranslatedText('Draw Date') }}:</span><span>{{ formatDate(item.date) }}</span>
                            </div>
                         </div>
                         <div class="inspiring__item--raffle">
                            <span class="inspiring__item--raffle_title">{{ getTranslatedText('Raffle') }}
                                {{ getTranslatedText('ID') }}
                            </span>
                            <div class="inspiring__item--raffle_content">
                                <div class="inspiring__item--raffle_id">{{ item.raffle_id || getTranslatedText("Pending Generation")}}</div>
                                <div class="inspiring__item--double">
                                    <img v-if="Number(item?.double_jackpot) > 0" src="@/assets/images/inspiring/double.svg" alt="">
                                    <img v-if="Number(item?.secure_jackpot) > 0" src="@/assets/images/inspiring/secure.svg" alt="">
                                </div>
                        </div>
                         </div>
                         <div class="inspiring__item--coupon">
                            <div class="inspiring__item--coupon_item">
                                <b-spinner v-if="item.isLoadingDownLoadGen" variant="success" />
                                <img v-else @click="handleDownloadCertificate(item)" src="@/assets/images/inspiring/icon-arrow-down.svg" alt="">
                                <img @click="handleFavorite(true, item.balls)" src="@/assets/images/inspiring/icon-heart.svg" alt="">
                                <img @click="handleQuickPick(item.balls)" src="@/assets/images/inspiring/icon-play.svg" alt="">
                            </div>
                            <div v-if="item.recurring" class="inspiring__item--coupon_subscribe">
                                {{ getTranslatedText('Subscribed')}}: 
                                <span>{{ item.recurring_type === 1 ? getTranslatedText('Next Draw') : getTranslatedText('Weekly') }}</span>
                            </div>
                         </div>
                    </div>
                    </div>
                </div>
            </template>
        </div>
        <div class="thank_you__footer">
            <div class="thank_you__purchase">
                <h3>{{ getTranslatedText('Thank you for your  purchase.') }}</h3>
                <p>{{ getTranslatedText("When you buy a Green Certificate, not only do you contribute to planting trees, but you’re also helping Oasis Park achieve its mission to make the world a greener, healthier place. Trees do so much more than just absorb carbon dioxide! Trees create homes for wildlife, prevent soil erosion, purify the air, and counteract deforestation’s disastrous impact on climate change.") }}</p>
                <ButtonCustom
                    classBtn="buy_more_btn"
                    typeBtn="primary"
                    type="primary"
                    text="buy more green certificates"
                    @onClickButton="onBuyMore"
                />
            </div>
            <div class="thank_you__time_remaining">
                <p class="time_remaining__title">{{ getTranslatedText('TIME REMAINING') }}</p>
                <CountDownDate/>
                <p class="time_remaining__view" @click="handleRouter('LiveWatch')">
                    <img class="show_pc" src="@/assets/images/inspiring/youtube.svg" alt="">
                    <img class="show_sp" src="@/assets/images/inspiring/youtube.svg" alt="">
                    <span>{{ getTranslatedText('View Live Draw') }}</span>
                </p>
            </div>
        </div>
        <vue-bottom-sheet max-height="90%" ref="bottomFavorite">
            <BottomSheet
                :title="getTranslatedText('mark as favorite')"
                :description="
                    getTranslatedText('Give your favorite number a name')
                "
                :isHiddenBtn="true"
                classBody="bottom_favorite"
            >
                <template slot="body-sheet">
                    <div class="bottom-favorite">
                        <div
                            class="box-number-favorite t-transform-cap"

                        >
                            <h2 class="title-number">
                                {{ getTranslatedText("selected numbers") }}
                            </h2>
                            <div class="box-number-favorite__box-balls">
                                <div class="list-number-favorite">
                                    <div
                                        v-for="(
                                            item, index
                                        ) in dataFavorite.number"
                                        :class="`item-number-favorite ball-blue ${renderClass(
                                            item, index
                                        )}`"
                                        :key="index"
                                    >
                                        <span
                                            ><a>{{
                                                renderNumberLocale(item)
                                            }}</a></span
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>
                        <FormCommon
                            :formItem="dataFavorite.formItem"
                            :dataForm="dataFavorite"
                            @update-form-data="updateDataFavorite"
                        />
                        <div class="box-number-favorite__box-button">
                            <ButtonCustom
                                typeBtn="button"
                                type="default"
                                classBtn="button-favorite cancel"
                                text="cancel"
                                @onClickButton="handleFavorite(false)"
                            />
                            <ButtonCustom
                                typeBtn="button"
                                type="primary"
                                classBtn="button-favorite add"
                                :isLoading="isLoadingBtn"
                                text="add"
                                @onClickButton="onFinishFavorite"
                            />
                        </div>
                    </div>
                </template>
            </BottomSheet>
        </vue-bottom-sheet>
        <Toast :title="toastData.message" :type="toastData.type" />
    </div>
</template>
<script>
import Toast from "@/components/common/toast/Toast.vue";
import toolService from '@/services/tool.service';
import gameService from '@/services/game.service';
import netcoreService from '@/services/netcore.service';
import languageMixin from "@/mixins/language/language-mixin";
import ButtonCustom from "@/components/common/button";
import CountDownDate from "@/components/common/count-down-date";
import FormCommon from "@/components/common/form";
import BottomSheet from "@/components/common/bottom-sheet";
// import CountDownTimer from "@/components/common/count-down-timer/CountDownTimer";

import moment from "moment";
import { isArrayEmpty, isStrEmpty, renderClassBall, renderLocaleNumber, formatNumberMoney, formatDate } from '@/components/common/utils';
import convertGSTtime from "@/mixins/convert-gst-time/convert-gst-time-mixin";
import drawDateMixin from "@/mixins/draw-date/draw-date-mixin";
import BannerCustom from "@/components/common/banner";
import FavouriteService from "@/services/favourite.service";

const CURRENCY_CODE = process.env.VUE_APP_CURRENCY_CODE;
let scriptTag1, scriptTag2, scriptTag3, scriptTag4, scriptTag5, scriptTag8, scriptTag9
const EXCHANGE_RATE = process.env.VUE_APP_EXCHANGE_RATE;

export default {
    mixins: [languageMixin, convertGSTtime, drawDateMixin],
    components: {
        // CountDownTimer,
        ButtonCustom,
        CountDownDate,
        FormCommon,
        BottomSheet,
        Toast,
        BannerCustom
    },
    data() {
        return {
            listTickets: [],
            maxiumBlue: 0,
            maxiumGreen: 0,
            minimumBall: 0,
            isLoadingPage: true,
            isLoadingDownload: false,
            nextDraw: "",
            nextDrawFormat: "",
            transactionId: "",
            scriptEle: '',
            exchangeRate: EXCHANGE_RATE,
            dataFavorite: {
                name: "",
                number: [],
                formItem: [
                    {
                        type: "input",
                        typeInput: "text",
                        placeholder: "Name...",
                        name: "name",
                        required: false,
                        state: null,
                        textInvalid: "Please enter name",
                        label: "Enter Name",
                        for: "input-Name",
                        id: "input-Name",
                    },
                ],
            },
            isLoadingBtn:false,
            toastData: {
                message: "",
                type: "success",
            },
        };
    },
    created() {
        const parameters = this.$route.query;
        if(parameters?.reference) {
            this.fetchData(parameters.reference)
        } else {
            this.$router.push("/home");
        }
        localStorage.removeItem("gift_redeemed");
    },
    mounted(){
        // scriptTag1 = document.createElement('script');
        // scriptTag1.src = 'https://www.googletagmanager.com/gtag/js?id=AW-10932118710';
        // scriptTag1.async = true;
        // document.head.appendChild(scriptTag1);

        // scriptTag2 = document.createElement('script');
        // scriptTag2.textContent = `
        //     window.dataLayer = window.dataLayer || [];
        //     function gtag(){dataLayer.push(arguments);}
        //     gtag('js', new Date());
        //     gtag('config', 'AW-10932118710');
        // `;
        // document.head.appendChild(scriptTag2);

        // scriptTag3 = document.createElement('script');
        // scriptTag3.src = 'https://www.googletagmanager.com/gtag/js?id=AW-10936920541';
        // scriptTag3.async = true;
        // document.head.appendChild(scriptTag3);

        // scriptTag4 = document.createElement('script');
        // scriptTag4.textContent = `
        //     window.dataLayer = window.dataLayer || [];
        //     function gtag(){dataLayer.push(arguments);}
        //     gtag('js', new Date());
        //     gtag('config', 'AW-10936920541');
        // `;
        // document.head.appendChild(scriptTag4);
        let userInfo = JSON.parse(localStorage.getItem("user_info"));
        scriptTag8 = document.createElement('script');
        scriptTag8.textContent = `
            window.dataLayer = window.dataLayer || [];
            dataLayer.push({
                'mobile': ${userInfo.phone || ''},
                'email': '${userInfo.email || ''}'
            });
        `;
        document.head.appendChild(scriptTag8);
        this.renderUserInfor()
    },
    methods: {
        async fetchData(reference) {
            try {
                const resp = await gameService.getReference(reference)
                const respValidate = await toolService.getValidateTicket();
                const respNextLive = await gameService.getTimeNextLive()
                if(resp.status === 200) {
                    let snippetScript = document.createElement('script');
                    snippetScript.type = 'text/javascript';
                    snippetScript.textContent = `
                    (function(a,b,c,d,e){var f=d.getDate().toString()+d.getMonth().toString()+d.getFullYear().toString(),g=b.createElement(c);g.type="text/javascript",g.async=1,g.setAttribute("data-script-id","jb-leads-code"),g.src="https://static.jubnaadserve.com/api/conversion/js/"+e+"?jcv="+f,document.getElementsByTagName("head")[0].appendChild(g)})(window,document,"script",new Date,19875);
                `
                    this.scriptEle = document.head.appendChild(snippetScript)

                    if(respNextLive.status === 200) {
                        this.nextDraw = moment(respNextLive.data.cutoff)
                        this.nextDrawFormat =  moment(respNextLive.data.cutoff).format('dddd, DD MMMM yyyy')
                    }
                    let isDouble = 'N/A'
                    let isSecure = 'N/A'
                    let items = []
                    let drawNumbers = []
                    let double = []
                    let secure = []
                    let listTransactions = resp.data.transactions.map((item, index) => {
                        let balls = [];
                        if (!isArrayEmpty(item.bets.bets)) {
                            item.bets.bets.map((x) => {
                                balls = balls.concat(x);
                            });
                        }
                        isDouble = !isStrEmpty(item.bets.double_jackpot) ? 'yes' : 'no'
                        isSecure = !isStrEmpty(item.bets.secure_jackpot) ? 'yes' : 'no'
                        let date = this.convertGSTtime(item.date);

                        let production = {
                            product_price: item.wager * this.exchangeRate,
                            draw_date: moment(item.date).format('YYYY-MM-DD'),
                            double_the_grand_prize: isDouble,
                            secure_the_grand_prize: isSecure,
                        }
                        drawNumbers.push(...item.bets.bets)
                        double.push(production.double_the_grand_prize)
                        secure.push(production.secure_the_grand_prize)
                        items.push(production)
                        return {
                            id: item.id,
                            name: `entry ${index + 1}`,
                            double_jackpot:item.bets?.double_jackpot,
                            secure_jackpot:item.bets?.secure_jackpot,
                            date:date,
                            balls,
                            raffle_id: item.raffle_id,
                            link_certificate: item?.link_certificate,
                            recurring: item.bets?.additional_information?.recurring,
                            recurring_type: item.bets?.additional_information?.recurring_type,
                            isLoadingDownLoadGen: false
                        };
                    });
                    this.listTickets = listTransactions
                    this.transactionId = resp.data.transactions[0]?.transaction_id
                    this.maxiumBlue = respValidate.maxiumBlue;
                    this.maxiumGreen = respValidate.maxiumGreen;
                    this.minimumBall = respValidate.minMaxTickets[0];
                    this.isLoadingPage = false;
                    let userInfo = JSON.parse(localStorage.getItem("user_info"));
                    scriptTag5 = document.createElement("script")
                    scriptTag5.textContent =`
                    gtag('event', 'conversion', {
                        'send_to': 'AW-10932118710/ySFDCLnn4ZgYELbJ69wo',
                        'value':  ${Number(resp.data.transactions[0]?.total_wager) * Number(this.exchangeRate)},
                        'currency': '${CURRENCY_CODE}',
                        'transaction_id': ${resp.data.transactions[0]?.transaction_id},
                        'mobile': '${userInfo.phone || ""}',
                        'email': '${userInfo.email || ""}'
                    });
                    `
                    document.head.appendChild(scriptTag5);

                    let payment = localStorage.getItem("payment_vendor");
                    scriptTag9 = document.createElement('script');
                    scriptTag9.textContent = `
                        window.dataLayer = window.dataLayer || [];
                        dataLayer.push({
                            'event': 'green_ticket_purchase',
                            'order_id': '${resp.data.transactions[0]?.transaction_id}',
                            'user_id': ${userInfo.id},
                            'voucher_code': 'N/A',
                            'currency': '${CURRENCY_CODE}',
                            'amount': ${Number(resp.data.transactions[0]?.total_wager) * Number(this.exchangeRate)},
                            'payment_vendor': '${payment || "NA"}',
                            'date_draw': '${items[0].draw_date}',
                            'grand_prize': '${this.price(1)}',
                            'eligible_prize': '${this.price(2)}',
                            'draw_number': '${drawNumbers.join(',')}',
                            'double_the_grand_prize': '${double.join(', ')}',
                            'secure_the_grand_prize': '${secure.join(', ')}',
                            'items': ${JSON.stringify(items)}
                        });
                    `;
                    document.head.appendChild(scriptTag9);
                    const dataNetCore = {
                        "activity_name": "green_ticket_purchase",
                        "identity": userInfo.id,
                        "activity_source": "web",
                        "activity_params": {
                            "order_id": resp.data.transactions[0]?.transaction_id,
                            "voucher_code": "N/A",
                            "currency": CURRENCY_CODE,
                            "amount": Number(resp.data.transactions[0]?.total_wager) * Number(this.exchangeRate),
                            "payment_vendor": payment || "NA",
                            "user_id": userInfo.id,
                            "items": items
                        }
                    }
                    if(window.performance.navigation.TYPE_RELOADED) {
                        this.purchaseNetcore(dataNetCore)
                    }
                }
            } catch (err) {
                console.log(err, "err");
            }

        },
        showToast(message, type) {
            this.toastData.message = message;
            this.toastData.type = type;
            this.$bvToast.show("my-toast");
        },

        formatLocalePrice(price) {
            return formatNumberMoney(parseFloat(price));
        },

        price(number) {
            let list_games = !isStrEmpty(localStorage.getItem("list_games"))
                ? JSON.parse(localStorage.getItem("list_games"))
                : [];

            let price = !isArrayEmpty(list_games)
                ? parseFloat(list_games[0]?.estimated_jackpot) * number
                : 0;

            return price;
        },

        renderUserInfor() {
            if (this.$store.state.isLoggedIn) {
                let userInfo = JSON.parse(localStorage.getItem("user_info"));
                let createInfo = document.createElement('div');
                createInfo.innerHTML = `${userInfo.phone && `Mobile number: ${userInfo.phone}`} <br/> ${userInfo.email && `Email id: ${userInfo.email}`}`
                createInfo.style.position = "absolute"
                createInfo.style.visibility = "hidden"
                document.getElementById("inspiring-id").appendChild(createInfo);
            }
        },

        renderClass(number, index) {
            return renderClassBall(
                number,
                index,
                this.minimumBall,
                this.maxiumBlue,
                this.maxiumGreen
            );
        },

        renderNumberLocale(number) {
            return renderLocaleNumber(number);
        },

        onBuyMore() {
            this.$router.push('/buy/green-certificates')
        },
        handleRouter(name){
            this.$router.push({name:name});
        },
        updateDataFavorite(item) {
            let { value, name } = item;
            if (
                name === "name" &&
                this.dataFavorite.formItem[0].state === false
            ) {
                this.dataFavorite.formItem[0].state = true;
            }
            this.dataFavorite[name] = value;
        },
        handleFavorite(state, number) {
            if(state){
                this.$refs.bottomFavorite?.open();
                this.dataFavorite.number = number;
            }else{
                this.$refs.bottomFavorite?.close();
            }

        },
        async onFinishFavorite(){
            this.isLoadingBtn = true;
            if (this.dataFavorite.name === "") {
                this.dataFavorite.formItem[0].state = false;
                this.isLoadingBtn = false;
                return false;
            }
            if (!this.$store.state.isLoggedIn) {
                this.showToast(
                    this.getTranslatedText("Please Log In"),
                    "error"
                );

                this.$router.push("/login");
                this.isLoadingBtn = false;
                return false;
            }
            try {
                let user_info = JSON.parse(localStorage.getItem("user_info"));

                let param = {
                    user_id: user_info.id,
                    favourite_name: this.dataFavorite.name,
                    favourite_number: this.dataFavorite.number,
                    bonus_ball: [],
                };

                const resp = await gameService.addFavourite(param);

                if (resp.status === 200) {
                    this.dataFavorite.name = "";
                    this.dataFavorite.formItem[0].state = true;
                    this.$refs.bottomFavorite?.close();
                    this.isLoadingBtn = false;
                    this.showToast(
                        this.getTranslatedText("Number market as Favorite"),
                        "success"
                    );
                } else {
                    this.isLoadingBtn = false;
                    if (!isArrayEmpty(resp.errors)) {
                        resp.errors.map((x) => {
                            this.showToast(
                                this.getTranslatedText(x.message),
                                "error"
                            );
                        });
                    } else {
                        this.showToast(
                            this.getTranslatedText(resp.message),
                            "error"
                        );
                    }
                }
            } catch (error) {
                console.log(error, "error");
            }
        },
        handleQuickPick(number){
            this.$router.push({name:'Tickets'});
            localStorage.setItem('number-quick-pick', JSON.stringify(number));
        },
        formatDate(date){
            const parts = date.split("/");
            const dateFormat = formatDate(new Date(`${parts[2]}-${parts[1]}-${parts[0]}`));
            const result = `${dateFormat.day} ${dateFormat.monthName} ${dateFormat.year}`;
            return result;
        },
        async handleDownloadCertificate(data) {
            data.isLoadingDownLoadGen = true
            try {
                const res = await FavouriteService.reGenCerticate(data.raffle_id)
                if(res.status === 200) {
                    window.open(res.data, "_blank")
                    setTimeout(() => {
                        data.isLoadingDownLoadGen = false
                    }, 2000);
                    data.link_certificate = res.data
                    data.isLoadingDownLoadGen = false
                } else {
                    data.isLoadingDownLoadGen = false
                    let message = ''
                    !isArrayEmpty(res.errors)
                        ? res.errors.forEach((element) => {
                            message += element.message;
                    })
                    : (message = res.message);
                    this.showToast(this.getTranslatedText(message), "errorGenC")
                }
            } catch (error) {
                data.isLoadingDownLoadGen = false
                console.log(error)
            }
        },

        async purchaseNetcore(data) {
            try {
                await netcoreService.purchase(data)
            } catch (error) {
                console.log(error)
            }
        }
    },
    beforeDestroy() {
    function removeScript(script) {
        if (script && script.parentNode) {
            script.parentNode.removeChild(script);
        }
    }

    document.head.removeChild(this.scriptEle);
    removeScript(scriptTag1);
    removeScript(scriptTag2);
    removeScript(scriptTag3);
    removeScript(scriptTag4);
    removeScript(scriptTag5);
    removeScript(scriptTag8);
    removeScript(scriptTag9);
    }
};
// Taboola Pixel Code
// _tfa.push({notify: 'event', name: 'lead', id: 1479600});
// End of Taboola Pixel Code
</script>



<style scoped lang="scss" src="@/assets/scss/mobile/inspiring/inspiring.scss" />
