<template>
    <div
        id="section-header_content"
        class="section-header"
        :class="{ 'no-login': !$store.state.isLoggedIn }"
    >
        <div class="section-header__content--wrap">
            <div class="section-header__wrap">
                <nav id="section-header__nav-menu" class="section-header__nav">
                    <div class="section-header__nav__left">
                        <div class="logo-container">
                            <div class="logo">
                                <img
                                    loading="lazy"
                                    @click="handleMyHome"
                                    class="user-cart icon-header"
                                    width="149" height="45"
                                    src="@/assets/images/header/logo.svg"
                                />
                            </div>
                        </div>
                        <div
                            class="section-header__menus"
                            :class="{ 'no-login': !$store.state.isLoggedIn }"
                        >
                            <div
                                v-for="(item, index) in menus[0].data"
                                :key="index"
                                class="section-header__menus__menu t-transform-cap"
                                :class="{ hideDesktop: item.isDesktop === false }"
                                @click="handleClickRedirect(item)"
                            >
                                {{ renderMutiLang(item.title) }}
                            </div>
                            <button
                                @click="onShowVoucherCode"
                                class="section-header__voucher section-voucher t-transform-cap"
                            >
                                {{ getTranslatedText("Redeem Gift Card") }}
                            </button>
                        </div>
                    </div>

                    <!-- <img loading="lazy"
                        class="line-desktop"
                        src="@/assets/images/header/line-desktop.png"
                    /> -->

                    <div class="user-section" v-if="$store.state.isLoggedIn">
                        <!-- <a href="tel:+800 645 54667" class="icon-phone">
                              <img loading="lazy"
                                  class="icon-header"
                                  src="@/assets/images/header/call.png"
                              />
                          </a> -->
                        <div
                            v-if="!$store.state.is_app"
                            @click="handleMyCart"
                            class="user-section-cart t-transform-cap"
                            :class="{
                'no-padding': $store.state.isLoggedIn,
                'no-login': !$store.state.isLoggedIn,
              }"
                        >
                            <!-- <label class="user-section-cart__title-notify">
                                    <span class="user-section-cart__text">3</span>
                                </label> -->
                            <div class="user-section-cart_quatity">
                <span
                ><a>{{ $store.state.amoutCart || 0 }}</a></span
                >
                                <img
                                    loading="lazy"
                                    class="user-cart icon-header"
                                    src="@/assets/images/header/shopping-cart.svg"
                                />
                            </div>
                            <!-- <span class="text-cart" v-if="!$store.state.isLoggedIn">{{
                                    getTranslatedText("my cart")
                                }}</span> -->
                        </div>
                        <div @click="handleNotify" class="user-section__bell">
              <span
              ><a>{{ $store.state.unreadNotify }}</a></span
              >
                            <img
                                loading="lazy"
                                class="user-notify icon-header icon-bell"
                                src="@/assets/images/header/bell.svg"
                            />
                        </div>
                        <div class="burger" ref="burger">
                            <div class="line-1"></div>
                            <div class="line-2"></div>
                            <div class="line-3"></div>
                        </div>
                        <div
                            @click="handleClickDropdown"
                            name="submit"
                            class="user-section__info"
                        >
                            <b-avatar size="38px" v-if="$store.state.isLoggedIn">
                                <img
                                    loading="lazy"
                                    v-if="$store.state.srcAvatar !== ''"
                                    class="user-cart icon-header"
                                    :src="$store.state.srcAvatar"/>
                                <img
                                    loading="lazy"
                                    v-else
                                    class="user-cart icon-header"
                                    src="@/assets/images/header/account/avatar.png"
                                /></b-avatar>
                            <!-- <div class="user-section__info__name">
                                    <span v-if="$store.state.isLoggedIn"
                                        >{{ userName }}
                                    </span>
                                    <img loading="lazy"
                                        v-if="
                                            !$store.state.isLoggedIn &&
                                            isActiveLanguage === 'in'
                                        "
                                        class="flag-ar"
                                        src="@/assets/images/flags/Flag_of_India.svg"
                                    />
                                    <img loading="lazy"
                                        v-if="
                                            !$store.state.isLoggedIn &&
                                            isActiveLanguage === 'en'
                                        "
                                        class="flag-ar"
                                        src="@/assets/images/flags/Flag_of_the_United_Kingdom.svg"
                                    />
                                    <img loading="lazy"
                                        v-if="
                                            !$store.state.isLoggedIn &&
                                            isActiveLanguage === 'ar'
                                        "
                                        class="flag-ar"
                                        src="@/assets/images/flags/Flag_of_Oman.svg"
                                    />
                                </div> -->
                            <div class="user-section__info__dropdown">
                                <b-dropdown
                                    id="dropdown-right"
                                    ref="dropdown"
                                    right
                                    variant="primary"
                                    class="dropdown-custom"
                                    v-if="$store.state.isLoggedIn"
                                >
                                    <b-dropdown-form>
                                        <template>
                                            <div
                                                class="user-section__info__dropdown__item balance t-transform-cap"
                                            >
                        <span
                            class="user-section__info__dropdown__item__balance"
                        >
                          {{ getTranslatedText("balance") }}:
                          {{ formatPrice($store.state.balance) }}
                          {{ getTranslatedText($store.state.currency_code) }}
                        </span>
                                                <button
                                                    type="button"
                                                    class="btn-deposit"
                                                    @click="onClickRedirectMyWallet"
                                                >
                                                    {{ getTranslatedText("deposit") }}
                                                </button>
                                            </div>
                                            <div
                                                @click="handleOpenPath"
                                                class="user-section__info__dropdown__item t-transform-cap"
                                            >
                                                {{
                                                    getTranslatedText("my account") +
                                                    " (" +
                                                    getTranslatedText("User ID") +
                                                    ": " +
                                                    userId +
                                                    ")"
                                                }}
                                            </div>
                                            <div
                                                class="user-section__info__dropdown__item t-transform-cap"
                                                @click="handleLogout"
                                            >
                                                {{ getTranslatedText("logout") }}
                                            </div>
                                        </template>
                                    </b-dropdown-form>
                                </b-dropdown>
                            </div>
                        </div>
                        <div class="qr-group" @click="handleClickQR">
                            <b-dropdown ref="qrDropdown">
                                <b-dropdown-item>
                                    <img
                                        loading="lazy"
                                        class="qr-code-icon"
                                        src="@/assets/images/header/qr-code.webp"
                                    />
                                    <div class="des">
                                        <div class="scan">{{ getTranslatedText('Scan to download the app from') }}</div>
                                        <div class="gg-play">
                                            <img
                                                loading="lazy"
                                                class="gg-icon"
                                                src="@/assets/images/header/gg-play.svg"
                                            />
                                            <span>{{ getTranslatedText('Google Play') }}</span>
                                        </div>
                                    </div>
                                </b-dropdown-item>
                            </b-dropdown>
                            <img
                                loading="lazy"
                                class="qr-icon"
                                src="@/assets/images/header/qr.webp"
                            />
                        </div>
                    </div>

                    <div
                        v-if="!$store.state.isLoggedIn"
                        class="section-header__buttons"
                        :class="{ 'no-login': !$store.state.isLoggedIn }"
                    >
                        <div @click="handleLogin" class="section-header__buttons__login">
                            {{ getTranslatedText("SIGN IN") }}
                        </div>
                        <div
                            @click="handleRegister"
                            class="section-header__buttons__register"
                        >
                            {{ getTranslatedText("REGISTER") }}
                        </div>
                        <div class="qr-group" @click="handleClickQR">
                            <b-dropdown ref="qrDropdown">
                                <b-dropdown-item>
                                    <img
                                        loading="lazy"
                                        class="qr-code-icon"
                                        src="@/assets/images/header/qr-code.webp"
                                    />
                                    <div class="des">
                                        <div class="scan">{{ getTranslatedText('Scan to download the app from') }}</div>
                                        <div class="gg-play">
                                            <img
                                                loading="lazy"
                                                class="gg-icon"
                                                src="@/assets/images/header/gg-play.svg"
                                            />
                                            <span>{{ getTranslatedText('Google Play') }}</span>
                                        </div>
                                    </div>
                                </b-dropdown-item>
                            </b-dropdown>
                            <img
                                loading="lazy"
                                class="qr-icon"
                                src="@/assets/images/header/qr.webp"
                            />
                        </div>
                        <div class="burger" ref="burger">
                            <div class="line-1"></div>
                            <div class="line-2"></div>
                            <div class="line-3"></div>
                        </div>
                    </div>
                    <VoucherCode
                        id="modalVoucherCode"
                        @showToast="showToast"
                        @onCancelModal="onHideVoucherCode"
                    />
                </nav>
            </div>
            <div class="nav-links">
                <div class="nav-links__overlay"></div>
                <div class="nav-links__wrap">
                    <div class="menu-header">
                        <div class="menu-header__wrap">
                            <img
                                loading="lazy"
                                @click="handleMyHome"
                                class="logo"
                                src="@/assets/images/header/logo.svg"
                            />
                            <img
                                loading="lazy"
                                class="icon-header-menu close-icon"
                                src="@/assets/images/header/closeicon.svg"
                            />
                        </div>
                        <div class="menu-menus">
                        <div class="menu-menus__buttons">
                            <div class="menu-menus__buttons-box" v-if="!$store.state.isLoggedIn">
                                <div @click="handleLogin" class="menu-menus__buttons__login">
                                    {{ getTranslatedText("SIGN IN") }}
                                </div>
                                <div
                                    @click="handleRegister"
                                    class="menu-menus__buttons__register"
                                >
                                    {{ getTranslatedText("REGISTER") }}
                                </div>
                            </div>
                            <div v-else class="menu-menus__buttons__top" >
                                <div class="balance">
                                    <div class="text">{{ getTranslatedText("balance") }}</div>
                                    {{ getTranslatedText($store.state.currency_code) }}
                                    {{ formatPrice($store.state.balance) }}
                                </div>
                                <div class="avatar">
                                    <b-avatar size="60px">
                                        <img
                                            loading="lazy"
                                            v-if="$store.state.srcAvatar !== ''"
                                            class="user-cart icon-header"
                                            :src="$store.state.srcAvatar"
                                        />
                                        <img
                                            loading="lazy"
                                            v-else
                                            class="user-cart icon-header"
                                            src="@/assets/images/header/account/avatar.png"
                                        />
                                    </b-avatar>
                                </div>
                            </div>
                            <button @click="handleClickAccountItem('/buy/green-certificates', false, true)"
                                class="section-header__voucher section-voucher t-transform-cap buy-mobile">
                                {{ getTranslatedText("Buy now") }}
                            </button>
                            <div class="menu-menus__items__menu balance t-transform-cap">
                                <button @click="onShowVoucherCodeMobile"
                                 class="section-header__voucher section-voucher t-transform-cap voucher-mobile">
                                    {{ getTranslatedText("Redeem Gift Card") }}
                                </button>
                            </div>
                        </div>
                    </div>
                    </div>
                    <div class="menu-menus__items">
                        <div
                            class="menu-menus__items__menu t-transform-cap"
                            v-for="(item, key) in menus"
                            :key="key"
                            :class="{
                                        menuLogout: item.slot === 'info-user',
                                        menuAccount: item.slot === 'account',
                                        menuMain: item.slot === 'menu-item',
                                        hideMenuAccount:
                                        item.slot === 'account' && !$store.state.isLoggedIn,
                                    }"
                                                >
                            <div
                                v-if="item.slot === 'info-user'"
                                class="menu-menus__items__menu__user-info"
                            >
                                <div class="menu-menus__items__menu__user-name" v-if="$store.state.isLoggedIn">
                                    <b-avatar size="30px">
                                        <img
                                            loading="lazy"
                                            v-if="$store.state.srcAvatar !== ''"
                                            class="user-cart icon-header"
                                            :src="$store.state.srcAvatar"
                                        />
                                        <img
                                            loading="lazy"
                                            v-else
                                            class="user-cart icon-header"
                                            src="@/assets/images/header/account/avatar.png"
                                        />
                                    </b-avatar>

                                    <div class="menu-menus__items__menu__user-info__name">
                                        {{ userName }}
                                    </div>
                                </div>
                            </div>
                            <div
                                class="menu-menus__menu_wrap"
                                v-if="item.slot === 'menu-item'"
                            >
                                <template v-for="(i, index) in item.data">
                                    <div
                                        class="menu-menus__menu_item"
                                        v-if="i.isShowSp !== false"
                                        :key="index"
                                        :class="{ menuBuynow: i.slot === 'buy now' }"
                                        @click="
                                            handleClickAccountItem(
                                                i.path,
                                                i.isPathDesk,
                                                i.isRedirect,
                                                i.isNewTab
                                            )
                                            ">
                                        {{ renderMutiLang(i.title) }}
                                    </div>
                                </template>
                            </div>
                            <div
                                v-if="item.slot === 'account' && $store.state.isLoggedIn"
                                class="menu-menus__items__menu__account"
                            >
                                <!-- <div
                                    v-for="(account, i) in menusAccount"
                                    :key="i+100"
                                    v-show="account.isShowCollapse"
                                    @click="
                                            handleClickAccountItem(
                                            account.url,
                                            null,
                                            account.isRedirect
                                            )
                                        "
                                        class="menu-menus__items__menu__account__item t-transform-cap"
                                    >
                                    <img
                                        loading="lazy"
                                        :src="require(`@/assets/images/navigation/${account.icon}`)"
                                    />
                                    <div>
                                        {{ getTranslatedText(account.title) }}
                                        <div class="text-id" v-if="account.isShowUserId">{{getTranslatedText("User ID") + ": " + userId }}</div>
                                    </div>
                                    <span v-if="account.hasNumber" class="number"><a>{{ $store.state.unreadNotify }}</a></span>
                                </div> -->
                                <div
                                    v-for="(account, i) in menusAccount"
                                    :key="i"
                                    v-show="account.isShow"
                                    @click="
                                            handleClickAccountItem(
                                            account.url,
                                            null,
                                            account.isRedirect,
                                            false,
                                            account.subMenu,
                                            i
                                            )
                                        "
                                    :class="{'has-sub-menu' : menusAccount[i].isShowCollapse}"
                                    >
                                    <div class="menu-menus__items__menu__account__item t-transform-cap">
                                        <img
                                            loading="lazy"
                                            :src="require(`@/assets/images/navigation/${menusAccount[i].isShowCollapse ? account.iconFocus : account.icon}`)"
                                        />
                                        <div>
                                            {{ getTranslatedText(account.title) }}
                                            <div class="text-id" v-if="account.isShowUserId && !menusAccount[i].isShowCollapse">{{getTranslatedText("User ID") + ": " + userId }}</div>
                                        </div>
                                        <span v-if="account.hasNumber" class="number"><a>{{ $store.state.unreadNotify }}</a></span>
                                    </div>
                                    <div v-if="menusAccount[i].isShowCollapse" class="sub-menu">
                                        <div  v-for="(item, index) in account.subMenu" :key="index">
                                            <div @click="handleClickSubMenu(item)">{{ item.title }}</div>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="collapse-icon" @click="isActiveContent = !isActiveContent">
                                    <img
                                        loading="lazy"
                                        :src="require(`@/assets/images/header/account/chevron-down.svg`)"
                                    />
                                </div> -->
                            </div>
                        </div>
                        <ButtonCustom
                            v-if="$store.state.isLoggedIn"
                            classBtn="logout-btn"
                            typeBtn="primary"
                            type="primary"
                            text="Sign Out"
                            @onClickButton="handleLogout"
                        />
                    </div>
                </div>
            </div>
        </div>
        <Toast
            id="header-toast"
            :title="toastData.message"
            :type="toastData.type"
            :hasVariableTrans="toastData.hasVariableTrans"
        />
        <AddCredit
            v-if="$store.state.isLoggedIn"
            id="show-amount-popup-header"
            :offsite="offsite"
            @showToast="showToast"
        />
    </div>
</template>
<script>
import {listMenuNavigation} from "@/resources/listNavigation";
import {
    isArrayEmpty,
    isStrEmpty,
    redirectPathApp,
    redirectNewTab,
    renderLocaleNumber,
    renderLocalePrice,
} from "@/components/common/utils";
import {setUserInfo} from "@/services/axios";

import toolService from "@/services/tool.service";
import UserService from "@/services/user.service";
import authService from "@/services/auth.service";

import languageMixin from "@/mixins/language/language-mixin";
import RouterMixin from "@/mixins/router/router-mixin";

import Toast from "@/components/common/toast/Toast.vue";
// import SocialSignUp from "@/components/common/socialLogin";
import VoucherCode from "@/components/common/voucherCode";
import EventBus from "@/components/common/EventBus";
import ButtonCustom from "@/components/common/button";
import AddCredit from "@/views/mobile/wallet/add-credit";
import PaymentService from "@/services/payment.servive";

export default {
    mixins: [languageMixin, RouterMixin],
    components: {Toast, VoucherCode, ButtonCustom, AddCredit},
    data() {
        return {
            toastData: {
                message: "",
                type: "success",
                hasVariableTrans: false
            },
            isActiveLanguage: "en",
            amountCart: "amount",
            userName: "",
            userId: "",
            isActiveContent: false,
            offsite: false,
            menus: [
                {
                    title: "",
                    slot: "menu-item",
                    data: [
                        // {
                        //     title: "language",
                        //     slot: "language",
                        //     path: "",
                        //     isDesktop: false,
                        //     isRedirect: false,
                        // },
                        // {
                        //     title: "home",
                        //     path: "/home",
                        //     isRedirect: true,
                        // },
                        {
                            title: "Buy now",
                            path: "/numbers",
                            isRedirect: true,
                            slot: "buy now",
                        },
                        {title: "Green Certificate", path: "/buy/green-certificates", isRedirect: true},
                        // {title: "Subscribe", path: "/subscribe", isRedirect: false},
                        {title: "draws results", path: "/draws", isRedirect: false},
                        // {
                        //     title: "oasis park ",
                        //     path: "https://oasispark.org/",
                        //     isRedirect: false,
                        //     isNewTab: true,
                        // },
                        {title: `FAQ`, path: "/faq", isRedirect: false},
                        // {
                        //     title: "Shop",
                        //     path: "https://omillionaire.shop/",
                        //     isRedirect: false,
                        //     isNewTab: true,
                        // },
                        // {
                        //     title: "green Initiative",
                        //     path: "/green",
                        //     isRedirect: false,
                        // },
                        {
                            title: "news",
                            path: "https://omillionaire-mediacenter.com/",
                            isRedirect: false,
                            isDesktop: false,
                            isNewTab: true,
                            isShowSp: false,
                        },
                        {
                            title: "about us",
                            path: "/about-us",
                            isRedirect: false,
                            isDesktop: false,
                            isShowSp: false,
                        },

                        {
                            title: "Watch Live Draw",
                            path: "/watch-live",
                            isRedirect: false,
                        },
                        {
                            title: "Shop Now",
                            path: "https://omillionaire.shop/",
                            isRedirect: false,
                            isNewTab: true,
                        },
                        {
                            title: "Download App",
                            path: "https://play.google.com/store/apps/details?id=app.omilionaire&pli=1",
                            isNewTab: true,
                            isRedirect: false,
                            isDesktop: false,
                            isShowSp: !([   'iPad Simulator',
                                            'iPhone Simulator',
                                            'iPod Simulator',
                                            'iPad',
                                            'iPhone',
                                            'iPod'
                                        ].includes(navigator.platform) || (navigator.userAgent.includes("Mac") && "ontouchend" in document))
                        },
                    ],
                },
                {
                    title: "",
                    slot: "info-user",
                    path: "",
                    isDesktop: false,
                    isRedirect: false,
                },
                {
                    title: "my account",
                    slot: "account",
                    isPathDesk: "/personal-detail",
                    isDesktop: false,
                    path: "my-account",
                    isRedirect: false,
                },
            ],
            menusAccount: listMenuNavigation,
            // listSocial: [
            //     {
            //         name: "youtube",
            //         srcIcon: "youtube.png",
            //         path: "https://www.youtube.com/channel/UCCZxGo-Fgh-Vd47jxUBjnww/featured",
            //     },
            //     {
            //         name: "facebook",
            //         srcIcon: "Facebook.png",
            //         path: "https://www.facebook.com/OMillionaireom-110101778237267/",
            //     },
            //     {
            //         name: "twitter",
            //         srcIcon: "twitter.png",
            //         path: "https://twitter.com/Omillionaire_me?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor",
            //     },
            //     {
            //         name: "instagram",
            //         srcIcon: "instagram.png",
            //         path: "https://instagram.com/omillionaire.om?igshid=YmMyMTA2M2Y=",
            //     },
            //     // { name: "snapchat", srcIcon: "snapchat.png", path: "" },
            //     {
            //         name: "tiktok",
            //         srcIcon: "tiktok.png",
            //         path: "https://www.tiktok.com/@omillionaire_me",
            //     },
            // ],
        };
    },
    mounted() {
        this.navSlide();
        this.getUserData();
        // this.fetchMutilanguage();
        this.$store.dispatch("setUnreadNotify");
        this.$store.dispatch("setBalance");
    },
    created() {
        this.getTotalNotification();
        const appRedirect = new URLSearchParams(window.location.search).get(
            "isApp"
        );
        if (appRedirect) {
            this.$store.dispatch("handleLogout");
        }
        if(this.$store.state.isLoggedIn){
            this.onCheckCart();
        }
        window.addEventListener('scroll', function() {
            let header = document.querySelector('nav');
            if (window.scrollY==0) {
                header.classList.remove('header-new');
            } else {
                header.classList.add('header-new');
            }
        });
    },
    methods: {
        showToast(message, type, hasVariableTrans) {
            this.toastData.message = message;
            this.toastData.type = type;
            this.toastData.hasVariableTrans = hasVariableTrans;
            this.$bvToast.show("header-toast");
        },

        async onClickRedirectMyWallet() {

            const resp = await PaymentService.getGateway();

            if (resp.status === 200) {
                const data = resp.data;
                const config = data.config ? JSON.parse(data.config): null;

                if (config) {
                    const offsite = config.offsite ? config.offsite : 'no';
                    if (offsite === 'yes') {
                        this.offsite = true;
                    }
                }
                this.$bvModal.show("show-amount-popup-header");
            } else {
                this.$bvModal.show("show-amount-popup-header");
            }
        },

        async getTotalNotification() {
            this.$store.dispatch("setUnreadNotify");
        },

        handleOpenPath() {
            if (this.$store.state.is_app) {
                redirectPathApp("/personal-detail");
            } else {
                this.routerPush("/personal-detail");
            }
            setTimeout(() => {
                this.$refs.dropdown?.hide(true);
            }, 300);
        },

        getUserData() {
            if (this.$store.state.isLoggedIn) {
                UserService.getDataUser().then((res) => {
                    if (res) {
                        if (
                            isStrEmpty(res.dob) &&
                            isStrEmpty(res.gender) &&
                            isStrEmpty(res.nationality) &&
                            isStrEmpty(res.country_code)
                        ) {
                            this.$store.state.isUpdateProfile = true;
                        }
                        let locale = res?.locale_code || "en";
                        let userInfo = {
                            first_name: res.first_name,
                            last_name: res.last_name,
                            calling_code: res.calling_code,
                            email: res.email,
                            id: res.id,
                            name: `${res.first_name} ${res.last_name}`,
                            phone: res.phone_number,
                            address: res.address,
                            state: res.state,
                            city: res.city,
                            zipcode: res.zipcode,
                            country_code: res.country_code,
                        };
                        setUserInfo(userInfo);
                        this.userName = res.first_name;
                        this.userId = res.id;
                        localStorage.setItem("locale", locale);
                        // this.fetchMutilanguage();
                        if (res.avatar) {
                            this.$store.dispatch("onChangeSrcAvatar", res.avatar);
                        }
                    } else {
                        this.userName = this.getTranslatedText("language");
                    }
                });
            } else {
                // this.fetchMutilanguage();
            }
        },

        handleClickQR() {
            this.$refs.qrDropdown?.show(true);
        },

        handleClickDropdown() {
            this.$refs.dropdown?.show(true);
        },

        async fetchMutilanguage() {
            try {
                // need better way for config these language
                // let supportLocale = ["en", "ar", "in"];
                let supportLocale = ["en"];
                await supportLocale.forEach(async (lang) => {
                    const data = await toolService.getMutiLanguage(lang);
                    localStorage.setItem("muti_language_" + lang, JSON.stringify(data));
                });

                let locale = localStorage.getItem("locale")
                    ? localStorage.getItem("locale")
                    : "en";
                // const data = await toolService.getMutiLanguage(locale);
                // localStorage.setItem("muti_language", JSON.stringify(data));
                localStorage.setItem("locale", locale);
                this.isActiveLanguage = locale;
                const nav = document.querySelector(".nav-links");
                if (locale === "ar") {
                    document.body.setAttribute("dir", "rtl");
                    nav.classList.add("rtl");
                } else {
                    document.body.removeAttribute("dir");
                    nav.classList.remove("rtl");
                }
            } catch (error) {
                console.log(error);
            }
        },

        handleClickAccountItem(path, isPathDesk, isRedirect, isNewTab = false, subMenu, index) {
            if(!isArrayEmpty(subMenu)) {
                if (index === 0) {
                    this.menusAccount[1].isShowCollapse = false
                } 
                if (index === 1) {
                    this.menusAccount[0].isShowCollapse = false
                }
                this.menusAccount[index].isShowCollapse = !this.menusAccount[index].isShowCollapse
            } else {
                if (
                    !isStrEmpty(path) ||
                    (!isStrEmpty(isPathDesk) && window.screen.width > 767)
                ) {
                    if (isNewTab) {
                        redirectNewTab(path);
                    } else if (this.$store.state.is_app && isRedirect) {
                        redirectPathApp(path);
                    } else {
                        this.handleCloseMenu();
                        this.routerPush(path);
                    }
                }
                this.menusAccount[1].isShowCollapse = false
                this.menusAccount[0].isShowCollapse = false
            }
        },
        handleLogout() {
            let scriptTag1
            scriptTag1 = document.createElement('script');
            scriptTag1.textContent = `
                window.dataLayer = window.dataLayer || [];
                dataLayer.push({
                    'event': 'log_out',
                    'user_id': ${this.userId},
                });
            `;
            document.head.appendChild(scriptTag1);
            this.$store.dispatch("handleLogout");
            this.handleCloseMenu();
            this.routerPush("/login");
            setTimeout(() => {
                this.$refs.dropdown?.hide(true);
            }, 300);
        },
        handleCloseMenu() {
            const nav = document.querySelector(".nav-links");
            const close = document.querySelector(".close-icon");
            const burger = this.$refs.burger;
            const header = document.querySelector("#section-header_content");

            close.classList.remove("close-icon-active");
            nav.classList.remove("nav-active");
            burger.classList.remove("active");
            header.classList.remove("open-menu");
            document.body.classList.remove("no-scroll-menu");
        },
        handleRegister() {
            this.handleCloseMenu();
            this.routerPush("/register");
        },
        handleLogin() {
            this.handleCloseMenu();
            window.location.href = "/login";
        },
        handleMyCart() {
            if (this.$store.state.amoutCart > 0) {
                if (this.$router.history.current.path !== "/checkout") {
                    this.routerPush("/checkout");
                }
            } else {
                this.routerPush("/numbers");
            }
            this.handleCloseMenu();
        },
        handleNotify() {
            this.routerPush("/notifications");
            this.handleCloseMenu();
        },
        navSlide() {
            const burger = this.$refs.burger;
            const nav = document.querySelector(".nav-links");
            const close = document.querySelector(".close-icon");
            const header = document.querySelector("#section-header_content");

            burger.addEventListener(
                "click",
                () => {
                    if (nav.classList.contains("nav-active")) {
                        this.handleCloseMenu();
                    } else {
                        nav.classList.add("nav-active");
                        close.classList.add("close-icon-active");
                        burger.classList.add("active");
                        header.classList.add("open-menu");
                        document.body.classList.add("no-scroll-menu");
                        // header.addEventListener('scroll', function() {
                        //     let head = document.querySelector('nav');
                        //     if (header.scrollTop==0) {
                        //         head.classList.remove('header-new');
                        //     } else {
                        //         head.classList.add('header-new');
                        //     }
                        // });
                    }
                },
                true
            );

            close.addEventListener(
                "click",
                () => {
                    close.classList.remove("close-icon-active");
                    nav.classList.remove("nav-active");
                    burger.classList.remove("active");
                    header.classList.remove("open-menu");
                    document.body.classList.remove("no-scroll-menu");
                },
                true
            );
        },

        onShowVoucherCode() {
            if(this.$store.state.isLoggedIn){
                this.$bvModal.show("modalVoucherCode");
            }else{
                this.$router.push('/login')
            }
        },

        onHideVoucherCode() {
            EventBus.$emit("onHideVoucherCode");
            this.$bvModal.hide("modalVoucherCode");
        },
        handleClickSubMenu(item) {
            window.location.href = item.url;
        },
        onShowVoucherCodeMobile() {
            window.location.href = "/wallet?step=5";
        },

        async onChangeLanguage(locale) {
            if (this.$store.state.isLoggedIn) {
                try {
                    const resp = await authService.updateLocale(locale);
                    if (resp.status === 200) {
                        localStorage.setItem("locale", locale);
                        //this.isActiveLanguage = locale === "en" ? false : true;
                        this.isActiveLanguage = locale;
                        this.$router.replace({query: null});
                        window.location.reload(true);
                    }
                } catch (error) {
                    console.log(error);
                }
            } else {
                localStorage.setItem("locale", locale);
                //this.isActiveLanguage = locale === "en" ? false : true;
                this.isActiveLanguage = locale;
                this.$router.replace({query: null});
                window.location.reload(true);
            }
        },

        renderMutiLang(title) {
            return this.getTranslatedText(title);
        },

        renderNumberLocale(number) {
            return renderLocaleNumber(parseInt(number));
        },

        formatPrice(price) {
            price = price.replaceAll(",", "");
            return renderLocalePrice(parseFloat(price));
        },

        handleClickRedirect(item) {
            if (item.isNewTab) {
                redirectNewTab(item.path);
            } else if (this.$store.state.is_app && item.isRedirect) {
                redirectPathApp(item.path);
            } else {
                this.routerPush(item.path);
            }
        },

        async onCheckCart() {
            let totalCart = !isStrEmpty(localStorage.getItem("total_items"))
                ? parseInt(localStorage.getItem("total_items"))
                : 0;
            try {
                const resp = await authService.getListCart();
                if (resp.status === 200) {
                    let listGames = localStorage.getItem("list_games");
                    listGames = !isStrEmpty(listGames)
                    ? [...JSON.parse(listGames)]
                    : [];
                    let list_block_tmp =
                        totalCart > 0
                            ? JSON.parse(localStorage.getItem("list_block_tmp"))
                            : [];
                    let list_block_api =
                        parseInt(resp.data?.amount) > 0
                            ? JSON.parse(resp.data.cart_data)
                            : [];
                        let amount = 0;

                        let dataCart = list_block_api.filter((x) => {
                            return x.date.some((v) => {
                                return listGames.some(
                                    (val) => val.event_id.toString() === v
                                );
                            })
                        });
                        dataCart.map((val) => {
                            let cart = val.date.filter((v) => {
                                return listGames.some(
                                    (x) => x.event_id.toString() === v
                                );
                            })
                            amount += cart.length
                        })
                        let param = {
                            cart_data: dataCart,
                            items: dataCart.length,
                            amount,
                        };

                        const respSaveCart = await authService.saveListCart(param);
                        if (respSaveCart.status === 200) {
                            this.$store.dispatch(
                                "onChangeAnountCart",
                                parseInt(param.amount)
                            );
                            localStorage.setItem(
                                "list_block_tmp",
                                JSON.stringify(list_block_api)
                            );
                            localStorage.setItem(
                                "list_block",
                                JSON.stringify(list_block_api)
                            );
                            localStorage.setItem("total_items", param.amount);
                            if(list_block_tmp.length !== list_block_api.length && this.$route.name === 'Checkout'){
                                window.location.href = "/checkout";
                            }
                            // EventBus.$emit("onFetchDataListCart");
                        }
                } else {
                    await this.$router.push("/");
                }
            } catch (error) {
                console.log(error);
            }
        },

        // onLoginSocial(paramLoginSocial) {
        //     authService
        //         .loginByGoogle(paramLoginSocial.idToken, paramLoginSocial.type)
        //         .then((res) => {
        //             if (res.status === 200) {
        //                 this.onFinishLogin();
        //             } else {
        //                 this.showToast(
        //                     this.getTranslatedText(res.message),
        //                     "error"
        //                 );
        //             }
        //         });
        // },
        handleMyHome(){
            this.routerPush("/");
            this.handleCloseMenu();
        },
    },
};
</script>
<style scoped lang="scss" src="@/assets/scss/mobile/_header.scss"/>
