<template>
    <div class="main">
        <BannerCustom title="Prize Breakdown"/>
        <div class="prize-breakdown">
            <div class="first-section">
                <div class="img-win"><img loading="lazy" src="@/assets/images/prize-breakdown/pb.webp" alt=""></div>
                    <div class="title">{{ getTranslatedText('O! Millionaire is your most rewarding eco-friendly draw, where you can win the Grand Draw in five ways.') }}</div>
                    <div class="line-text"></div>
                    <div class="sub-title" v-html="getTranslatedText('the grand prize is now worth over')"></div>
            </div>
            <div class="second-section">
                <div class="win">{{ getTranslatedText('WIN THE GRAND PRIZE') }}</div>
                <div class="match">{{ getTranslatedText('MATCH 7 NUMBERS IN ANY ORDER') }}</div>
                <div class="header">
                    <div class="head">
                        <p class="text">{{ getTranslatedText('PRIZE') }}</p>
                        <p class="text">{{ getTranslatedText('MATCH') }}</p>
                        <p class="text">{{ getTranslatedText('WIN') }}</p>
                    </div>
                    <div></div>
                </div>
                <div class="content-head">
                    <div>
                        <div class="item-main"  v-for="(item, i) in listWinnings" :key="i">
                            <div class="item text">{{ getTranslatedText(item.title)}}
                                <div class="prize">{{ getTranslatedText('prize')}}</div>
                            </div>
                            <div class="item text-sub">{{ getTranslatedText(item.name)}}
                                <img loading="lazy" class="prize-img" :src="require(`@/assets/images/landing-page/${item.icon}.webp`)"/>
                            </div>
                            <div class="item blue first" v-if="i===0"><span class="t-transform-cap">{{ getTranslatedText("Over") }}</span>{{ getTranslatedText(currencyCode) + ' ' + formatNumberPrice($store.state.listGameLoto[0]?.estimated_jackpot || "0") }}</div>
                            <div class="item blue" v-else>{{ getTranslatedText(currencyCode) + ' ' + item.prize }}</div>
                        </div>
                        <div class="note">{{ getTranslatedText('*The Grand Prize and Second Prize are equally divided among numbers') }}</div>
                    </div>
                    <div class="right">
                        <div class="prize-breakdown__seaction-video">
                            <div class="prize-breakdown__video">
                                <iframe :src="linkGrandPrize" title="O! Millionaire 5 Winning Categories - Prize Breakdown" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                            </div>
                            <p>{{ getTranslatedText('o! millionaire 5 wining categories - prize breakdown') }}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="line"></div>
            <div class="second-section" id="raffle">
                <div class="win">{{ getTranslatedText('WIN AED 100,000') }}</div>
                <div class="match">{{ getTranslatedText('MATCH GREEN CERTIFICATE ID WITH RAFFLE ID') }}</div>
                <div class="header"></div>
                <div class="content-head">
                    <div>
                        <div class="title-head">{{ getTranslatedText('You have an infinite chance to win the Raffle Draw Prize worth 100,000 dirhams weekly.') }}</div>
                        <div class="sub-title-head">{{ getTranslatedText('At O! Millionaire, you always have a chance to win your best life...') }}</div>
                    </div>
                    <div class="right">
                        <div class="prize-breakdown__seaction-video">
                            <div class="prize-breakdown__video">
                                <iframe :src="linkRaffle" title="O! Millionaire Raffle winning prize" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                            </div>
                            <p class="t-transform-cap">{{ getTranslatedText('One Winner Brings Home 100,000 Dirhams Weekly') }}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="line"></div>
             <div class="second-section" id="double-secure">
                <div class="win">{{ getTranslatedText('BOOST THE GRAND PRIZE') }}</div>
                <div class="match">{{ getTranslatedText('DOUBLE & SECURE') }}</div>
                <div class="header"></div>
                <div class="content-head">
                    <div class="video">
                        <div class="prize-breakdown__boost_content">
                            <div class="prize-breakdown__boost_item">
                                <p class="boost_item__title">
                                    <img loading="lazy" src="@/assets/images/prize-breakdown/double.webp" alt=""/>
                                    <span class="text"><span class="sub">{{ getTranslatedText('DOUBLE') }}</span> <br> {{ getTranslatedText('THE GRAND PRIZE') }}</span>
                                </p>
                                <p class="boost_item__text">
                                    {{ getTranslatedText('Go big and') }} <span>{{ getTranslatedText('DOUBLE the Grand Prize.') }}</span> {{ getTranslatedText('This option will give you') }} <span>{{ getTranslatedText('TWICE AS MUCH') }}</span> {{ getTranslatedText('as your total winning prize.') }}
                                </p>
                                <div class="btn-boost">{{ getTranslatedText('+ AED 12.50') }}</div>
                            </div>
                            <div class="prize-breakdown__boost_item">
                                <p class="boost_item__title">
                                    <img loading="lazy" src="@/assets/images/prize-breakdown/secure.webp" alt=""/>
                                    <span class="text">
                                        <span class="sub">{{ getTranslatedText('SECURE') }}</span><br>{{ getTranslatedText('THE GRAND PRIZE') }}
                                    </span>
                                </p>
                                <p class="boost_item__text">
                                    {{ getTranslatedText('Go bigger and keep the') }} <span>{{ getTranslatedText('Grand Prize') }}</span> {{ getTranslatedText('for yourself. Earn your whole') }} <span>{{ getTranslatedText('TOTAL WINNINGS WITHOUT HAVING TO SHARE') }}</span> {{ getTranslatedText('with other lucky winners.') }}
                                </p>
                                <div class="btn-boost">{{ getTranslatedText('+ AED 7.50') }}</div>
                            </div>
                            <div class="prize-breakdown__boost_item">
                                <p class="boost_item__title">
                                    <img loading="lazy" src="@/assets/images/prize-breakdown/both.webp" alt=""/>
                                    <span class="text">
                                        <span class="sub secure">{{ getTranslatedText('DOUBLE & SECURE') }}</span><br>{{ getTranslatedText('THE GRAND PRIZE') }}
                                    </span>
                                </p>
                                <p class="boost_item__text">
                                    {{ getTranslatedText('Maximize your luck! Have') }} <span>{{ getTranslatedText('DOUBLE the Grand Prize') }}</span> {{ getTranslatedText('for') }} <span>{{ getTranslatedText('YOURSELF') }}</span> {{ getTranslatedText('when you') }}<span>{{ getTranslatedText('match all 7 numbers.') }}</span>
                                </p>
                                <div class="btn-boost">{{ getTranslatedText('+ AED 20') }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="right">
                        <div class="prize-breakdown__seaction-video">
                            <div class="prize-breakdown__video">
                                <iframe width="930" height="523" :src="linkDouble" title="Double &amp; Secure the Grand Prize" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                            </div>
                            <p>{{ getTranslatedText('Double & Secure the Grand Prize') }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import languageMixin from "@/mixins/language/language-mixin";
import RouterMixin from "@/mixins/router/router-mixin";
import ButtonCustom from "@/components/common/button";
import BannerCustom from "@/components/common/banner";

const CURRENCY_CODE = process.env.VUE_APP_CURRENCY_CODE;
const LINK_VIDEO_GRAND_PRIZE = process.env.VUE_APP_LINK_VIDEO_GRAND_PRIZE;
const LINK_VIDEO_RAFFLE = process.env.VUE_APP_LINK_VIDEO_RAFFLE;
const LINK_VIDEO_DOUBLE = process.env.VUE_APP_LINK_VIDEO_DOUBLE;

let scriptTag

export default {
  mixins: [languageMixin, RouterMixin],
  components: {
    ButtonCustom, BannerCustom
  },
  data() {
    return {
        action: '',
        currencyCode: CURRENCY_CODE,
        linkGrandPrize: LINK_VIDEO_GRAND_PRIZE,
        linkRaffle: LINK_VIDEO_RAFFLE,
        linkDouble: LINK_VIDEO_DOUBLE,
        listWinnings: [
            {
                title: 'grand',
                name: '7 out of 7',
                icon: 'match-7',
                prize: '90,000,000'
            },
            {
                title: 'second',
                name: '6 out of 7',
                icon: 'match-6',
                prize: '500,000'
            },
            {
                title: 'third',
                name: '5 out of 7',
                icon: 'match-5',
                prize: '5,000'
            },
            {
                title: 'fourth',
                name: '4 out of 7',
                icon: 'match-4',
                prize: '500'
            },
            {
                title: 'fifth',
                name: '3 out of 7',
                icon: 'match-3',
                prize: '10'
            }
        ]
    };
  },
  created() {
    this.$store.dispatch("getListGame");
    this.addScriptCanonical()
  },
  mounted() {
    const parameters = this.$route.query;
    if(parameters?.action === '1') {
        this.$router.replace({'query': null});
        const element = document.getElementById('double-secure');
        element.scrollIntoViewIfNeeded({ behavior: 'smooth', block: 'center', inline: 'center' });
    }
    if(parameters?.action === '2') {
        this.$router.replace({'query': null});
        const element = document.getElementById('raffle');
        element.scrollIntoViewIfNeeded({ behavior: 'smooth', block: 'center', inline: 'center' });
    }
  },
    beforeDestroy(){
        function removeScript(script) {
            if (script && script.parentNode) {
                script.parentNode.removeChild(script);
            }
        }
        removeScript(scriptTag);
    },
  methods: {
    addScriptCanonical() {
        scriptTag = document.createElement("link");
        scriptTag.rel = "canonical";
        scriptTag.href =`https://omillionaire.com/prize-breakdown`
        document.head.appendChild(scriptTag);
    },
    redirectYoutube(link){
        window.open(link,'_blank');
    },
    formatNumberPrice(price) {
        price = parseInt(price)
        return price.toLocaleString("en-US", {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        });
    },
    // playVideo(event) {
    //   event.target.className = "prize-breakdown__video-play hidden";
    //   event.target.nextElementSibling.play();
    //   event.target.nextElementSibling.controls = true;
    // },
  },
};
</script>

<style scoped lang="scss" src="@/assets/scss/mobile/prize-breakdown/_prize-breakdown.scss"/>
